import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Modal, ModalBody, Spinner} from 'reactstrap'
import {componentModalToggleAction} from 'app.cms.react/store/components/modal/actions'
import {componentFormInitAction, componentFormOnSubmitAction} from 'app.cms.react/store/components/form/actions'

class ModalLoader extends Component {
    constructor(props) {
        super(props)

        this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'loader') ? this.props.isOpen : false} toggle={this.toggleModal} backdrop="static">
                    <ModalBody className={'py-5 px-4'}>
                        <div className="text-center">
                            <h5>
                                {this.props.t('Please wait while your invoice is being generated.')}
                                <br />
                                <small>{this.props.t('This may take a few minutes.')}</small>
                            </h5>
                            <Spinner className="m-2 mt-3" color="primary" />
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalLoader.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalLoader))