import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom"
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {ErrorMessage, Field} from 'formik'
import {Col, Row} from 'reactstrap'
import {micrositesFormAction} from 'app.cms.react/store/actions'

class FormMicrosites extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Field
                            name="microsite_name"
                            placeholder={this.props.t('Name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'microsite_name')}
                        />
                        <ErrorMessage
                            name="microsite_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="microsite_desc"
                            placeholder={this.props.t('Description')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'microsite_desc')}
                        />
                        <ErrorMessage
                            name="microsite_desc"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="token"
                            placeholder={this.props.t('Token')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'token')}
                        />
                        <ErrorMessage
                            name="token"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

FormMicrosites.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const {
        defaults
    } = state.Defaults

    return {
        defaults
    }
}

export default connect(mapStateToProps, {
    micrositesFormAction
})(withRouter(withTranslation()(FormMicrosites)))