import axios from 'axios'
import fileDownload from 'js-file-download'

// import accessToken from './jwt-token-access/accessToken'

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = ''

const axiosApi = axios.create({
    baseURL: API_URL,
})

if (localStorage.getItem('jwt') != null || typeof localStorage.getItem('jwt') != 'undefined') {
    axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt')
}

axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
)

export async function apiGet(url, config = {}) {
    return await axiosApi.get(url, {...config}).then(response => response.data)
}

export async function apiGetNoCors(url, config = {}) {
    return await axios.get(url, {...config}).then(response => response.data)
}

export async function apiPost(url, data, config = {}) {
    return axiosApi.post(url, {...data}, {...config}).then(response => response.data)
}

export async function apiPut(url, data, config = {}) {
    return axiosApi.put(url, {...data}, {...config}).then(response => response.data)
}

export async function apiDel(url, config = {}) {
    return await axiosApi.delete(url, {...config}).then(response => response.data)
}

export async function apiPostDownload(url, data, fileName = 'file.pdf', config = {}) {
    const defaultConfig = {responseType: 'blob'}

    return axiosApi.post(url, {...data}, {...defaultConfig, ...config}).then(response => {
        if (response.status == 200) {
            fileDownload(response.data, fileName)
        }
    })
}