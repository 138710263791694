import {takeEvery, put, call} from 'redux-saga/effects'
import {
    getPropTraverse,
    getErrorResponse,
    formArgsExcludeEmpty
} from 'app.cms.react/helpers/utils'
import {
    BUSINESS_INFO,
    BUSINESS_INFO_UPDATE,
    // BUSINESS_INFO_ADD,
    // BUSINESS_INFO_DELETE
} from './actionTypes'
import {AJAX_URL_BUSINESS_INFO} from '../actionTypes'
import {
    businessInfoDataAction,
    componentFormOnSubmitDoneAction,
    componentDataTableLoadedAction,
    componentDataTableLoadingAction,
    businessInfoFailAction,
    businessInfoSuccessAction
} from '../actions'
import {apiGet, apiPost, apiPut, apiDel} from 'app.cms.react/helpers/apiHelpers'
import * as API_URL from '../actionTypes'

function* businessInfo({payload: {params}}) {
    let paramObj = {}

    try {
        params = formArgsExcludeEmpty(params)

        if (Object.entries(params).length > 0) {
            paramObj = {params: params}
        }

        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_BUSINESS_INFO, paramObj)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(businessInfoDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* businessInfoUpdate({payload: {params, meta}}) {
    let options = {}
    let componentResponse = {}

    options = {
        id: params.id,
        business_address_details: params.business_address_details,
        business_contact_details: params.business_contact_details,
        business_name: params.business_name,
    }

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_BUSINESS_INFO, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(businessInfoSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_BUSINESS_INFO)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(businessInfoDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(businessInfoFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

// function* businessInfoAdd({payload: {params, meta}}) {
    // const options = {
    //     product_service_name: params.product_service_name,
    //     product_service_desc: params.product_service_desc,
    //     price: params.price,
    // }
    // let componentResponse = {}

    // try {
    //     const response = yield call(apiPost, API_URL.AJAX_URL_BUSINESS_INFO, options)
    //     componentResponse = {
    //         alertType: 'success',
    //         response: getPropTraverse(response, ['response', 'data']),
    //     }
    //     yield put(businessInfoSuccessAction(componentResponse))

    //     const responseData = yield call(apiGet, API_URL.AJAX_URL_BUSINESS_INFO)
    //     const data = getPropTraverse(responseData, ['response', 'data'])
    //     yield put(businessInfoDataAction(data))
    //     yield put(componentDataTableLoadedAction())
    // } catch (error) {
    //     componentResponse = {
    //         alertType: 'danger',
    //         response: getErrorResponse(error),
    //     }
    //     yield put(businessInfoFailAction(componentResponse))
    // }

    // yield put(componentFormOnSubmitDoneAction())
// }

// function* businessInfoDelete({payload: {params, meta}}) {
//     const options = {
//         data: {
//             id: params.primaryKey.id,
//         }
//     }
//     let componentResponse = {}

//     try {
//         const response = yield call(apiDel, API_URL.AJAX_URL_BUSINESS_INFO, options)
//         componentResponse = {
//             alertType: 'success',
//             response: getPropTraverse(response, ['response', 'data']),
//         }
//         yield put(businessInfoSuccessAction(componentResponse))

//         const responseData = yield call(apiGet, API_URL.AJAX_URL_BUSINESS_INFO)
//         const data = getPropTraverse(responseData, ['response', 'data'])
//         yield put(businessInfoDataAction(data))
//         yield put(componentDataTableLoadedAction())
//     } catch (error) {
//         componentResponse = {
//             alertType: 'danger',
//             response: getErrorResponse(error),
//         }
//         yield put(businessInfoFailAction(componentResponse))
//     }

//     yield put(componentFormOnSubmitDoneAction())
// }

function* BusinessInfoSaga() {
    yield takeEvery(BUSINESS_INFO, businessInfo)
    yield takeEvery(BUSINESS_INFO_UPDATE, businessInfoUpdate)
    // yield takeEvery(BUSINESS_INFO_ADD, businessInfoAdd)
    // yield takeEvery(BUSINESS_INFO_DELETE, businessInfoDelete)
}

export default BusinessInfoSaga