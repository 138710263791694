import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Card, CardBody, Container} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import TimelineInvoicePayments from 'app.cms.react/components/Invoices/TimelineInvoicePayments'

class InvoicePayments extends Component {
    constructor(props) {
        super(props)

        const {
            match: { params },
        } = this.props

        this.state = {
            invoice_id: params.id
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Payments')}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Payments')} breadcrumbItem={this.props.t('Payments')}/>
                        <TimelineInvoicePayments invoiceId={this.state.invoice_id} />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

InvoicePayments.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(InvoicePayments))