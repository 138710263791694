import {
    CONTACTS_INIT,
    CONTACTS,
    CONTACTS_DATA,
    CONTACTS_UPDATE,
    CONTACTS_ADD,
    CONTACTS_DELETE,
    CONTACTS_SUCCESS,
    CONTACTS_FAIL,
    CONTACTS_FORM,
} from './actionTypes'

export const contactsInitAction = (params) => ({
    type: CONTACTS_INIT,
    payload: params,
})

export const contactsAction = (params) => ({
    type: CONTACTS,
    payload: {params},
})

export const contactsDataAction = (data) => ({
    type: CONTACTS_DATA,
    payload: {data},
})

export const contactsUpdateAction = (params, meta) => ({
    type: CONTACTS_UPDATE,
    payload: {params, meta},
})

export const contactsAddAction = (params, meta) => ({
    type: CONTACTS_ADD,
    payload: {params, meta},
})

export const contactsDeleteAction = (params, meta) => ({
    type: CONTACTS_DELETE,
    payload: {params, meta},
})

export const contactsSuccessAction = (params) => ({
    type: CONTACTS_SUCCESS,
    payload: params,
})

export const contactsFailAction = (params) => ({
    type: CONTACTS_FAIL,
    payload: params,
})

export const contactsFormAction = (params) => ({
    type: CONTACTS_FORM,
    payload: {params},
})