import {takeEvery, put, call} from 'redux-saga/effects'
import {FORGOT_PASSWORD} from './actionTypes'
import * as API_URL from 'app.cms.react/store/actionTypes'
import {
    getSuccessResponse,
    getErrorResponse,
} from 'app.cms.react/helpers/utils'
import {apiPost} from 'app.cms.react/helpers/apiHelpers'
import {
    forgotPasswordSuccessAction,
    forgotPasswordFailAction,
    componentFormOnSubmitAction,
    componentFormOnSubmitDoneAction
} from '../actions'

function* forgotPassword({payload: {params, history}}) {
    let componentResponse = {}

    try {
        yield put(componentFormOnSubmitAction())

        const response = yield call(apiPost, API_URL.AJAX_URL_FORGOT_PASSWORD, params)
        componentResponse = {
            alertType: 'success',
            response: getSuccessResponse(response),
        }
        yield put(forgotPasswordSuccessAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(forgotPasswordFailAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
    }
}

function* ForgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

export default ForgotPasswordSaga