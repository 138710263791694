import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, Col, Label, Row} from 'reactstrap'

class FormWedding extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Row className="mt-2">
                            Wedding Only
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

FormWedding.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
})(FormWedding))