import {
    // BUSINESS_INFO_INIT,
    BUSINESS_INFO,
    BUSINESS_INFO_DATA,
    BUSINESS_INFO_UPDATE,
    // BUSINESS_INFO_ADD,
    // BUSINESS_INFO_DELETE,
    BUSINESS_INFO_SUCCESS,
    BUSINESS_INFO_FAIL,
    BUSINESS_INFO_FORM,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    dataView: [],
    response: '',
    alertType: '',
}

const businessInfo = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        // case BUSINESS_INFO_INIT:
        //     return {
        //         ...state,
        //         response: '',
        //         alertType: '',
        //     }
        case BUSINESS_INFO:
        case BUSINESS_INFO_UPDATE:
        // case BUSINESS_INFO_ADD:
        // case BUSINESS_INFO_DELETE:
            return {...state}
        case BUSINESS_INFO_SUCCESS:
        case BUSINESS_INFO_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case BUSINESS_INFO_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
                dataView: getPropTraverse(payload, ['data']),
            }
        case BUSINESS_INFO_FORM:
            const data = state.data.business

            Object.entries(payload).map(([prop, value]) => {
                switch (prop) {
                    case 'country':
                        data.business_address_details[0].country_code = value.value
                        data.business_address_details[0].country_name = value.label
                        break
                    case 'address_line_1':
                    case 'address_line_2':
                        data.business_address_details[0][prop] = value
                        break
                    case 'contact_number':
                        data.business_contact_details[0][prop] = value
                        break
                    default:
                        data[prop] = value
                }
            })

            return {
                ...state,
                data: {
                    business: {
                        ...{...data},
                    }
                }
            }
    }

    return {...state}
}

export default businessInfo