import {
    CODE_TABLE_OPTIONS,
    CODE_TABLE_SET_OPTIONS
} from './actionTypes'

const INIT_STATE = {
    isDataLoading: false,
    data: {}
}

const codeTable = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case CODE_TABLE_OPTIONS:
            return {
                ...state,
                isDataLoading: true
            }
        case CODE_TABLE_SET_OPTIONS:
            INIT_STATE.data[payload.type] = payload.options

            return {
                ...state,
                data: INIT_STATE.data,
                isDataLoading: false,
            }
        default:
            return { ...state }
    }
}

export default codeTable