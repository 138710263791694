import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import PlaceholderEmptyState from '../Placeholder/PlaceholderEmptyState'
import {Button, Col, ListInlineItem, Row, Spinner} from 'reactstrap'
import {componentModalToggleAction} from 'app.cms.react/store/components/modal/actions'
import {Link, withRouter} from 'react-router-dom'
import {pluckRouteBasePathFromName} from '../../../helpers/utils'

class DataTableLoader extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                {(this.props.data.length == 0 && this.props.isDataTableLoading == false) ? (
                    <React.Fragment>
                        <PlaceholderEmptyState />
                        {(typeof this.props.toggleAddModal != 'undefined') ? (
                            <div className="mt-2 text-center">
                                <Button
                                    type="button"
                                    color="primary"
                                    className="mb-2 me-2"
                                    onClick={(e) => this.props.componentModalToggleAction(this.props.toggleAddModal)}
                                >
                                    <i className="mdi mdi-plus me-1" />{(typeof this.props.toggleAddModal.buttonText == 'undefined') ? 'Add New' : this.props.toggleAddModal.buttonText}
                                </Button>
                            </div>
                        ) : null}
                        {(typeof this.props.toggleAddLink != 'undefined') ? (
                            <div className="mt-2 text-center">
                                <Link
                                    to={this.props.toggleAddLink.linkTo}
                                    className="btn btn-primary"
                                >
                                    <i className="mdi mdi-plus me-1"/>{(typeof this.props.toggleAddLink.linkText == 'undefined') ? 'Add New' : this.props.toggleAddLink.linkText}
                                </Link>
                            </div>
                        ) : null}
                    </React.Fragment>
                ) : (
                    (this.props.isDataTableLoading) ? (
                        <div className="text-center"><Spinner className="m-2" color="primary" /></div>
                    ) : (
                        (typeof this.props.dataTable != 'undefined') ? (this.props.dataTable) : (
                            (this.props.dataView != 'undefined') ? (this.props.dataView) : ''
                        )
                    )
                )}
            </React.Fragment>
        )
    }
}

DataTableLoader.propTypes = {
    dataTable: PropTypes.any,
    dataView: PropTypes.any,
    data: PropTypes.any.isRequired,
    isDataTableLoading: PropTypes.bool.isRequired,
    toggleAddModal: PropTypes.any,
    toggleAddLink: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {
    componentModalToggleAction
})(withRouter(withTranslation()(DataTableLoader)))