import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Countdown from "react-countdown"
import lomograpikaLogo from 'assets/images/logo/lomograpika-logo.png'


class Section extends Component {
    constructor() {
        super()
        this.renderer.bind(this)
    }

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>You are good to go!</span>
        } else {
            // Render a countdown
            return (
                <>
                    <div className="coming-box">
                        {days}
                        <span>Days</span>
                    </div>
                    <div className="coming-box">
                        {hours}
                        <span>Hours</span>
                    </div>
                    <div className="coming-box">
                        {minutes}
                        <span>Minutes</span>
                    </div>
                    <div className="coming-box">
                        {seconds}
                        <span>Seconds</span>
                    </div>
                </>
            )
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section hero-section bg-hero-section" id="home">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg="5">
                                <div className="text-white-50">
                                    <div className="mb-3">
                                        <img src={lomograpikaLogo} width="330" />
                                    </div>
                                    <p className="font-size-14">
                                        A group of collaborative creatives that specializes in different genres of photography, web development and technical recruitment.
                                    </p>
                                    <div className="button-items mt-4">
                                        <a href="http://lomograpika.com" className="btn btn-primary">
                                            Visit our website
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="5" md="8" sm="10" className="ms-lg-auto">
                                <Card className="overflow-hidden mb-0 mt-5 mt-lg-0">
                                    <CardHeader className="text-center">
                                        <h5 className="mb-0">Booking Form</h5>
                                    </CardHeader>
                                    <CardBody>
                                        <p className="text-center mb-0">
                                            Effortless Booking Made Simple: Discover a seamless and user-friendly booking experience on our website.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

export default Section
