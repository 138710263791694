import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL
} from './actionTypes'

export const forgotPasswordAction = (params, history) => ({
    type: FORGOT_PASSWORD,
    payload: {params, history},
})

export const forgotPasswordSuccessAction = (params) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: params,
})

export const forgotPasswordFailAction = (params) => ({
    type: FORGOT_PASSWORD_FAIL,
    payload: params,
})
