import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {Container} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TableContacts from '../../app.cms.react/components/Contacts/TableContacts'

class Customers extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Customers')}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={this.props.t('Customers')}/>
                        <TableContacts contactType="customer" />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Customers.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {})(withRouter(withTranslation()(Customers)))
