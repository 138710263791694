import {
    PAYMENTS_ADD,
    PAYMENTS_DATA,
    PAYMENTS_DELETE,
    PAYMENTS_FAIL,
    PAYMENTS_FORM_DATA,
    PAYMENTS_FORM,
    PAYMENTS_INIT,
    PAYMENTS_SUCCESS,
    PAYMENTS_UPDATE,
    PAYMENTS,
    PAYMENTS_SET_SELECTED_INVOICE,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    dataView: [],
    response: '',
    alertType: '',
    invoiceId: '',
}

const payments = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case PAYMENTS_INIT:
            return {
                ...state,
                alertType: '',
                response: '',
            }
        case PAYMENTS:
        case PAYMENTS_ADD:
        case PAYMENTS_UPDATE:
        case PAYMENTS_DELETE:
            return {...state}
        case PAYMENTS_SUCCESS:
        case PAYMENTS_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case PAYMENTS_SET_SELECTED_INVOICE:
            return {
                ...state,
                invoiceId: action.payload
            }
    }

    return {...state}
}

export default payments