import {takeEvery, put, call} from 'redux-saga/effects'
import {formArgsExcludeEmpty, getErrorResponse, getPropTraverse} from 'app.cms.react/helpers/utils'
import {
    PACKAGE_BUILDER,
    PACKAGE_BUILDER_DETAILS,
    PACKAGE_BUILDER_VIEW,
    PACKAGE_BUILDER_ADD,
    PACKAGE_BUILDER_DELETE,
    PACKAGE_BUILDER_UPDATE
} from './actionTypes'
import {
    AJAX_URL_PACKAGE_BUILDER,
    AJAX_URL_PACKAGE_BUILDER_DETAIL_VIEW
} from '../actionTypes'
import {
    packageBuilderDataAction,
    packageBuilderSuccessAction,
    packageBuilderFailAction,
    packageBuilderViewDataAction,
    componentDataTableLoadedAction,
    componentDataTableLoadingAction,
    componentFormOnSubmitDoneAction
} from '../actions'
import {apiDel, apiGet, apiPost, apiPut} from 'app.cms.react/helpers/apiHelpers'
import * as API_URL from '../actionTypes'

function* packageBuilder({payload: {params, history}}) {
    let paramObj = {}

    try {
        if (Object.entries(params).length > 0) {
            paramObj = {params: params}
        }

        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_PACKAGE_BUILDER, paramObj)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(packageBuilderDataAction(data))
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
    }

    yield put(componentDataTableLoadedAction())
}

function* packageBuilderDetails({payload: {params, history}}) {
    let paramObj = {}

    try {
        params = formArgsExcludeEmpty(params)

        if (Object.entries(params).length > 0) {
            paramObj = {params: params}
        }

        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_PACKAGE_BUILDER_DETAILS, paramObj)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(packageBuilderDataAction(data))
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
    }

    yield put(componentDataTableLoadedAction())
}

function* packageBuilderView({payload: {params}}) {
    let paramObj = {}

    try {
        if (getPropTraverse(params, ['package_id']) != null) {
            paramObj = {params: {package_id: params.package_id}}
        }

        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_PACKAGE_BUILDER_DETAIL_VIEW, paramObj)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(packageBuilderViewDataAction(data))
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
    }

    yield put(componentDataTableLoadedAction())
}

function* packageBuilderAdd({payload: {params, meta}}) {
    const options = {
        items: params.items,
        package_name: params.package_name,
        description: params.description,
        category: params.category,
        category_type: params.category_type,
        price: params.price,
    }

    let componentResponse = {}

    try {
        const response = yield call(apiPost, API_URL.AJAX_URL_PACKAGE_BUILDER, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(packageBuilderSuccessAction(componentResponse))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(packageBuilderFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* packageBuilderUpdate({payload: {params, meta}}) {
    const options = {
        id: params.id,
        items: params.items,
        package_name: params.package_name,
        description: params.description,
        package_price: params.package_price,
        category: params.category,
        category_type: params.category_type,
    }
    let componentResponse = {}

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_PACKAGE_BUILDER, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(packageBuilderSuccessAction(componentResponse))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(packageBuilderFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* packageBuilderDelete({payload: {params, meta}}) {
    const options = {
        data: {
            id: params.primaryKey.id,
        }
    }
    let componentResponse = {}
    let paramObj = {}

    try {
        const response = yield call(apiDel, API_URL.AJAX_URL_PACKAGE_BUILDER, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(packageBuilderSuccessAction(componentResponse))

        if (getPropTraverse(params.params, ['categoryable_type_code']) != null) {
            paramObj = {params: params.params}
        }

        const responseData = yield call(apiGet, API_URL.AJAX_URL_PACKAGE_BUILDER, paramObj)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(packageBuilderDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(packageBuilderFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* PackageBuilderSaga() {
    yield takeEvery(PACKAGE_BUILDER, packageBuilder)
    yield takeEvery(PACKAGE_BUILDER_DETAILS, packageBuilderDetails)
    yield takeEvery(PACKAGE_BUILDER_VIEW, packageBuilderView)
    yield takeEvery(PACKAGE_BUILDER_ADD, packageBuilderAdd)
    yield takeEvery(PACKAGE_BUILDER_UPDATE, packageBuilderUpdate)
    yield takeEvery(PACKAGE_BUILDER_DELETE, packageBuilderDelete)
}

export default PackageBuilderSaga