import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {componentModalToggleAction} from 'app.cms.react/store/components/modal/actions'
import {componentFormInitAction, componentFormOnSubmitAction} from 'app.cms.react/store/actions'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import FormAlert from 'app.cms.react/components/Common/Alerts/FormAlert'
import {contactsInitAction, contactsUpdateAction} from 'app.cms.react/store/contacts/actions'
import FormContacts from './FormContacts'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalEditContacts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                address_line_1: '',
                address_line_2: '',
                country: {},
                contact_number: '',
            },
        }

        this.toggleModal = this.toggleModal.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
        this.props.contactsInitAction()
    }

    handleFormSubmit(values) {
        const obj = {
            id: this.props.id,
            country: this.props.country
        }

        this.props.contactsUpdateAction({...values, ...obj, ...{type: this.props.contactType}})
        this.props.componentFormOnSubmitAction()
    }

    handleValidationSchema() {
        return Yup.object().shape({
            first_name: Yup.string().required(
                this.props.t('First name is required')
            ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'edit') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <Formik
                        enableReinitialize={false}
                        initialValues={this.props || this.state.form}
                        validationSchema={this.handleValidationSchema()}
                        onSubmit={(values) => this.handleFormSubmit(values)}
                    >
                        {({errors, status, touched}) => (
                            <Form className="form-horizontal">
                                <ModalHeader>Edit</ModalHeader>
                                <ModalBody>
                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                    <FormContacts
                                        errors={errors}
                                        touched={touched}
                                        handleFieldValidation={this.handleFieldValidation}
                                        ddlbCountries={this.props.ddlbCountries}
                                        country={this.props.country}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalEditContacts.propTypes = {
    t: PropTypes.any,
    id: PropTypes.any,
    first_name: PropTypes.any,
    last_name: PropTypes.any,
    price: PropTypes.any,
    address_line_1: PropTypes.any,
    address_line_2: PropTypes.any,
    country: PropTypes.any,
    contact_number: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        response,
        alertType
    } = state.Contacts

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
    contactsUpdateAction,
    contactsInitAction,
})(ModalEditContacts))