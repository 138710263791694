export const BOOKINGS = 'BOOKINGS'
export const BOOKINGS_DATA = 'BOOKINGS_DATA'
export const BOOKINGS_ADD = 'BOOKINGS_ADD'
export const BOOKINGS_UPDATE = 'BOOKINGS_UPDATE'
export const BOOKINGS_DELETE = 'BOOKINGS_DELETE'
export const BOOKINGS_FORM = 'BOOKINGS_FORM'
export const BOOKINGS_SUCCESS = 'BOOKINGS_SUCCESS'
export const BOOKINGS_FAIL = 'BOOKINGS_FAIL'
export const BOOKINGS_INIT = 'BOOKINGS_INIT'
export const BOOKINGS_VIEW = 'BOOKINGS_VIEW'
export const BOOKINGS_VIEW_DATA = 'BOOKINGS_VIEW_DATA'
export const BOOKING_EVENTS = 'BOOKING_EVENTS'
export const BOOKING_EVENTS_DATA = 'BOOKING_EVENTS_DATA'
export const BOOKING_EXPORT = 'BOOKING_EXPORT'
export const BOOKING_EXPORT_LOADING = 'BOOKING_EXPORT_LOADING'
export const BOOKING_EXPORT_LOADED = 'BOOKING_EXPORT_LOADED'