import TagManager from 'react-gtm-module'
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import NothingHere from 'app.cms.react/assets/images/nothing-here.png'
import {withRouter} from 'react-router-dom'
import {gtm} from 'app.cms.react/config'

class GTMComponent extends Component {
    constructor(props) {
        super(props)

        this.handleGtmInit = this.handleGtmInit.bind(this)
    }

    componentDidMount() {
        this.handleGtmInit()
    }

    handleGtmInit() {
        console.log('GTM')
        TagManager.initialize({
            gtmId: gtm.ID,
            dataLayer: {
                userId: '001',
                userProject: 'project'
            }
        })
    }

    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

GTMComponent.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {

})(withRouter(withTranslation()(GTMComponent)))