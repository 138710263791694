import {takeEvery, put, call} from 'redux-saga/effects'
import {SIGN_UP_USER} from './actionTypes'
import * as API_URL from 'app.cms.react/store/actionTypes'
import {
    getSuccessResponse,
    getErrorResponse,
    setJWTToken, getClientIp,
} from 'app.cms.react/helpers/utils'
import {apiGet, apiGetNoCors, apiPost} from 'app.cms.react/helpers/apiHelpers'
import {
    signUpUserSuccessAction,
    signUpUserFailAction,
    componentFormOnSubmitAction,
    componentFormOnSubmitDoneAction
} from '../actions'
import i18n from 'i18n'
// import {geoLocationUrl} from 'app.cms.react/config'

function* signupUser({payload: {params, history}}) {
    let componentResponse = {}

    try {
        yield put(componentFormOnSubmitAction())

        // const geoIp = yield call(apiGetNoCors, geoLocationUrl)
        const response = yield call(apiPost, API_URL.AJAX_URL_SIGNUP, {...params})
        componentResponse = {
            alertType: 'success',
            response: i18n.t('Sign up successful'),
        }
        setJWTToken(getSuccessResponse(response).access_token)
        yield put(signUpUserSuccessAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())

        window.location.assign('/dashboard')
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(signUpUserFailAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
    }
}

function* SignUpSaga() {
    yield takeEvery(SIGN_UP_USER, signupUser)
}

export default SignUpSaga