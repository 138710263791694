import React, {Component} from 'react'
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap'
import bookinghatchLomo from 'app.cms.react/assets/images/logo-bookinghatch.svg'

export default class CarouselPage extends Component {
    render() {
        return (
            <React.Fragment>
                <Col xl={9}>
                    <div className="auth-full-bg p-md-5 p-4">
                        <div className="w-100">
                            <div className="d-flex flex-column h-100">
                                <div className="my-auto text-center">
                                    <p><img src={bookinghatchLomo} width="450" /></p>
                                    <p className="font-size-16 mb-4">Simplify Bookings, Generate Invoices, and Elevate Customer Engagement.<br />Your All-in-One Solution for Seamless Business Management.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        )
    }
}
