import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Button, Card, CardBody, Col, Label, Modal, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FormBirthdayAndChristening from './FormBirthdayAndChristening'
import FormBookingType from './FormBookingType'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import {componentFormOnSubmitAction} from 'app.cms.react/store/components/form/actions'
import {
    bookingsAddAction,
    bookingsFormAction,
    bookingsInitAction,
    bookingsUpdateAction,
    bookingsViewAction,
    codeTableOptionsAction,
} from 'store/actions'
import {findPropertyValueInObject, getPropTraverse} from 'app.cms.react/helpers/utils'
import FormChristeningOnly from './FormChristeningOnly'
import FormWedding from './FormWedding'
import FormBirthdayOnly from './FormBirthdayOnly'
import FormAlert from 'app.cms.react/components/Common/Alerts/FormAlert'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <ButtonLoading
                formLoading={props.formLoading}
                buttonClass={'btn btn-success'}
                onClick={() => props.handleFormSubmitActions('')}
            />
            {/* <ButtonLoading
                buttonLoadingText={<React.Fragment><i className="bx bxs-file-pdf"></i> Generating</React.Fragment>}
                buttonName={<React.Fragment><i className="bx bxs-file-pdf"></i> Submit and Generate Invoice</React.Fragment>}
                formLoading={props.formLoading}
                buttonClass={'btn btn-success'}
                onClick={() => props.handleFormSubmitActions('generate_invoice')}
            /> */}
        </React.Fragment>
    )
}

class Wizard extends Component {
    constructor(props) {
        super(props)

        let isEditMode = false
        let activeTab = 1

        if (typeof this.props.bookingId != 'undefined') {
            this.props.bookingsViewAction({id: this.props.bookingId})
            isEditMode = true
        }

        if (typeof this.props.bookingType != 'undefined') {
            this.props.bookingsFormAction({
                category: {
                    category_code: {value: this.props.bookingType},
                    category_type_code: 'booking_types'
                }
            })
            activeTab = 2
        }

        this.state = {
            form: {
                celebrant_first_name: '',
                celebrant_last_name: '',
                celebrant_nickname: '',
                ceremony_full_address: '',
                reception_full_address: '',
            },
            form_action: '',
            activeTab: activeTab,
            passedSteps: [1],
            isEditMode: isEditMode,
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleFormSubmitActions = this.handleFormSubmitActions.bind(this)
        this.handleFieldValidation = this.handleFieldValidation.bind(this)
        this.handleFormTemplate = this.handleFormTemplate.bind(this)
        this.handleBookingType = this.handleBookingType.bind(this)

        this.handleDdlbDefaultValue = this.handleDdlbDefaultValue.bind(this)
        this.handleSetDdlbOnSelect = this.handleSetDdlbOnSelect.bind(this)
        this.handleDdlbChange = this.handleDdlbChange.bind(this)
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleTextDefaultValue = this.handleTextDefaultValue.bind(this)
        this.renderFormButtons = this.renderFormButtons.bind(this)
    }

    toggleTab(tab, e) {
        if (typeof e != 'undefined') {
            e.preventDefault()
        }

        if (tab == 1) {
            this.props.bookingsInitAction()
        }

        if (this.state.activeTab !== tab) {
            if (tab >= 1 && tab <= 2) { // set min and max number of tabs
                let modifiedSteps = [...this.state.passedSteps, tab]
                this.setState({
                    activeTab: tab,
                    passedSteps: modifiedSteps
                });
            }
        }
    }

    handleFormSubmitActions(value) {
        this.setState({
            form_action: value
        })
    }

    handleFormSubmit(values) {
        this.props.componentFormOnSubmitAction()
        if (typeof this.props.bookingId != 'undefined') {
            this.props.bookingsUpdateAction({...values, ...{
                booking_id: this.props.bookingId,
                form_action: this.state.form_action
            }})
        } else {
            this.props.bookingsAddAction({...values, ...this.props.bookingForm, ...{
                form_action: this.state.form_action,
                token: this.props.token,
            }})
        }
    }

    handleValidationSchema() {
        return Yup.object().shape({
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }


    handleDdlbDefaultValue(type) {
        let contactPerson = ''

        switch (type) {
            case 'customer':
                if (getPropTraverse(this.props.bookingForm, ['contact_person', 'id']) != '') {
                    contactPerson = this.props.bookingForm.contact_person

                    return {
                        label: contactPerson.label,
                        value: contactPerson.value
                    }
                }

                return null
            case 'guest_count':
            case 'celebrant_gender':
                if (getPropTraverse(this.props.bookingForm, [type, 'label']) == '' && getPropTraverse(this.props.bookingForm, [type, 'value']) == '') return null
                return {
                    label: getPropTraverse(this.props.bookingForm, [type, 'label'], true),
                    value: getPropTraverse(this.props.bookingForm, [type, 'value'], true),
                }
        }
    }

    handleSetDdlbOnSelect(value, type) {
        this.setState({
            [type]: value
        })
    }

    handleDdlbChange = (value, idx, type) => {
        switch (type) {
            case 'celebrant_date_of_birth':
            case 'celebrant_gender':
            case 'ceremony_event_datetime_start':
            case 'ceremony_event_datetime_end':
            case 'reception_event_datetime_start':
            case 'reception_event_datetime_end':
            case 'guest_count':
                this.handleSetDdlbOnSelect(value, type)
                this.props.bookingsFormAction({[type]: value})
                break
        }
    }

    handleFieldChange(type, value) {
        this.props.bookingsFormAction({[type]: value})
    }

    handleTextDefaultValue(type) {
        if (getPropTraverse(this.props.bookingForm, [type]) != null) {
            return this.props.bookingForm[type]
        }
    }

    handleBookingType() {
        return (typeof this.props.bookingType != 'undefined')
            ? this.props.bookingType
            : getPropTraverse(this.props.bookingForm, ['category', 'category_code', 'value'])
    }

    handleFormTemplate(bookingForm, errors, touched) {
        const categoryableCode = bookingForm //getPropTraverse(bookingForm, ['category', 'category_code', 'value'])
        const isEditMode = this.state.isEditMode

        switch (categoryableCode) {
            case 'birthday_and_christening':
                return (
                    <React.Fragment>
                        <FormBirthdayAndChristening
                            isEditMode={isEditMode}
                            errors={errors}
                            touched={touched}
                            handleFieldValidation={this.handleFieldValidation}
                            categoryableTypeCode='booking_types'
                            categoryableCode={categoryableCode}
                            handleDdlbChange={this.handleDdlbChange}
                            handleDdlbDefaultValue={this.handleDdlbDefaultValue}
                            handleFieldChange={this.handleFieldChange}
                            handleTextDefaultValue={this.handleTextDefaultValue}
                        />
                    </React.Fragment>
                )
            case 'christening_only':
                return (
                    <React.Fragment>
                        <FormChristeningOnly
                            isEditMode={isEditMode}
                            errors={errors}
                            touched={touched}
                            handleFieldValidation={this.handleFieldValidation}
                            categoryableTypeCode='booking_types'
                            categoryableCode={categoryableCode}
                            handleDdlbChange={this.handleDdlbChange}
                            handleDdlbDefaultValue={this.handleDdlbDefaultValue}
                            handleFieldChange={this.handleFieldChange}
                            handleTextDefaultValue={this.handleTextDefaultValue}
                        />
                    </React.Fragment>
                )
            case 'birthday_only':
                return (
                    <React.Fragment>
                        <FormBirthdayOnly
                            isEditMode={isEditMode}
                            errors={errors}
                            touched={touched}
                            handleFieldValidation={this.handleFieldValidation}
                            categoryableTypeCode='booking_types'
                            categoryableCode={categoryableCode}
                            handleDdlbChange={this.handleDdlbChange}
                            handleDdlbDefaultValue={this.handleDdlbDefaultValue}
                            handleFieldChange={this.handleFieldChange}
                            handleTextDefaultValue={this.handleTextDefaultValue}
                        />
                    </React.Fragment>
                )
            case 'wedding':
                return (
                    <React.Fragment>
                        <FormWedding
                            isEditMode={isEditMode}
                            errors={errors}
                            touched={touched}
                            handleFieldValidation={this.handleFieldValidation}
                        />
                    </React.Fragment>
                )
        }

        return null
    }

    renderFormButtons() {
        return (
            <React.Fragment>
                <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                <div className="actions clearfix mt-3">
                    <ul>
                        <li className={this.state.activeTab === 1 ? 'previous disabled' : 'previous'}>
                            <button className={this.state.activeTab === 1 ? 'btn btn-primary disabled' : 'btn btn-primary'} onClick={(e) => {this.toggleTab(this.state.activeTab - 1, e)}}>
                                {this.props.t('Previous')}
                            </button>
                        </li>
                        <li className={this.state.activeTab === 2 ? 'next' : 'next'}>
                            {(this.state.activeTab === 2) ? (
                                <FormSubmitButtons
                                    formLoading={this.props.formLoading}
                                    handleFormSubmitActions={this.handleFormSubmitActions}
                                />
                            ) : (
                                <button className='btn btn-primary' onClick={(e) => {this.toggleTab(this.state.activeTab + 1, e)}}>
                                    {this.props.t('Next')}
                                </button>
                            )}
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.props.bookingForm || this.state.form}
                    validationSchema={this.handleValidationSchema()}
                    onSubmit={(values) => this.handleFormSubmit(values)}
                >
                    {({errors, status, touched}) => (
                        <Form className="form-horizontal">
                            {(typeof this.props.bookingType == 'undefined') ? (
                                <React.Fragment>
                                    <div className="wizard clearfix">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({
                                                        current: this.state.activeTab === 1,
                                                    })}>
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.activeTab === 1,
                                                        })}
                                                        onClick={() => {
                                                            this.toggleTab(1);
                                                        }}
                                                    >
                                                        <div className="p-2">{this.props.t('Booking Type')}</div>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: this.state.activeTab === 2,
                                                    })}>
                                                    <NavLink
                                                        disabled={!(this.state.passedSteps || []).includes(2)}
                                                        className={classnames({
                                                            active: this.state.activeTab === 2,
                                                        })}
                                                        onClick={() => {
                                                            this.toggleTab(2);
                                                        }}
                                                    >
                                                        <div className="p-2">{this.props.t('Booking Details')}</div>
                                                    </NavLink>
                                                </NavItem>
                                            </ul>
                                        </div>
                                        <div className="content clearfix p-0">
                                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" alertClassName="mt-3 mb-0" />
                                            <TabContent activeTab={this.state.activeTab} className="body">
                                                <TabPane tabId={1}>
                                                    <FormBookingType
                                                        errors={errors}
                                                        touched={touched}
                                                        handleFieldValidation={this.handleFieldValidation}
                                                        initialValue={this.props.bookingType}
                                                    />
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    {this.handleFormTemplate(this.handleBookingType(), errors, touched)}
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                        {this.renderFormButtons()}
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="wizard clearfix">
                                        {this.handleFormTemplate(this.handleBookingType(), errors, touched)}
                                        {this.renderFormButtons()}
                                    </div>
                                </React.Fragment>
                            )}

                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        )
    }
}

Wizard.propTypes = {
    t: PropTypes.any,
    bookingId: PropTypes.any,
    bookingType: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const bookingForm = {
        ...state.Bookings.form
    }

    const {
        alertType,
        response,
    } = state.Bookings

    const {
        token,
    } = state.Globals

    const ddlbBookingTypes = {
        ...state.CodeTable.data
    }

    return {
        disableFields,
        formSubmit,
        formLoading,
        bookingForm,
        alertType,
        response,
        token,
        ddlbBookingTypes,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentFormOnSubmitAction,
    bookingsAddAction,
    bookingsUpdateAction,
    bookingsViewAction,
    bookingsFormAction,
    bookingsInitAction,
    codeTableOptionsAction,
})(Wizard))