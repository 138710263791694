import {
    PACKAGE_BUILDER_INIT,
    PACKAGE_BUILDER,
    PACKAGE_BUILDER_DETAILS,
    PACKAGE_BUILDER_FORM,
    PACKAGE_BUILDER_FORM_ITEMS,
    PACKAGE_BUILDER_FORM_ADD_ROW,
    PACKAGE_BUILDER_FORM_DELETE_ROW,
    PACKAGE_BUILDER_VIEW,
    PACKAGE_BUILDER_VIEW_DATA,
    PACKAGE_BUILDER_DATA,
    PACKAGE_BUILDER_ADD,
    PACKAGE_BUILDER_UPDATE,
    PACKAGE_BUILDER_DELETE,
    PACKAGE_BUILDER_SUCCESS,
    PACKAGE_BUILDER_FAIL
} from './actionTypes'

export const packageBuilderInitAction = (params) => ({
    type: PACKAGE_BUILDER_INIT,
    payload: params,
})

export const packageBuilderAction = (params) => ({
    type: PACKAGE_BUILDER,
    payload: {params},
})

export const packageBuilderDetailsAction = (params) => ({
    type: PACKAGE_BUILDER_DETAILS,
    payload: {params},
})

export const packageBuilderFormAction = (params) => ({
    type: PACKAGE_BUILDER_FORM,
    payload: {params},
})

export const packageBuilderFormItemsAction = (params, idx) => ({
    type: PACKAGE_BUILDER_FORM_ITEMS,
    payload: {params, idx},
})

export const packageBuilderFormAddRowAction = (params) => ({
    type: PACKAGE_BUILDER_FORM_ADD_ROW,
    payload: {params},
})

export const packageBuilderFormDeleteRowAction = (params, idx) => ({
    type: PACKAGE_BUILDER_FORM_DELETE_ROW,
    payload: {params, idx},
})

export const packageBuilderViewAction = (params) => ({
    type: PACKAGE_BUILDER_VIEW,
    payload: {params},
})

export const packageBuilderViewDataAction = (data) => ({
    type: PACKAGE_BUILDER_VIEW_DATA,
    payload: {data},
})

export const packageBuilderDataAction = (data) => ({
    type: PACKAGE_BUILDER_DATA,
    payload: {data},
})

export const packageBuilderAddAction = (params, meta) => ({
    type: PACKAGE_BUILDER_ADD,
    payload: {params, meta},
})

export const packageBuilderUpdateAction = (params, meta) => ({
    type: PACKAGE_BUILDER_UPDATE,
    payload: {params, meta},
})

export const packageBuilderDeleteAction = (params, meta) => ({
    type: PACKAGE_BUILDER_DELETE,
    payload: {params, meta},
})

export const packageBuilderSuccessAction = (params) => ({
    type: PACKAGE_BUILDER_SUCCESS,
    payload: params,
})

export const packageBuilderFailAction = (params) => ({
    type: PACKAGE_BUILDER_FAIL,
    payload: params,
})