import {
    GLOBAL_SETTER,
    GLOBAL_GETTER,
} from './actionTypes'

export const globalSetterAction = (params) => ({
    type: GLOBAL_SETTER,
    payload: params,
})

export const globalGetterAction = (params) => ({
    type: GLOBAL_GETTER,
    payload: params,
})
