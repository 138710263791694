import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import {formArgsExcludeEmpty, getPropTraverse} from 'app.cms.react/helpers/utils'
import {CODE_TABLE_OPTIONS} from './actionTypes'
import {codeTableSetOptionsAction} from './actions'
import {apiGet} from '../../helpers/apiHelpers'
import * as API_URL from '../actionTypes'
// import {exceptionHandlerAction} from 'app.cms.react/store/auth/exceptionHandler/actions'

function* codeTableOptionsSaga({ payload: { options } }) {
    const optionParams = formArgsExcludeEmpty({
        type: options.type,
        token: getPropTraverse(options, ['token'])
    })

    try {
        const response = yield call(apiGet, API_URL.AJAX_URL_CODE_TABLE + '/' + options.table, {params: optionParams})
        const data = getPropTraverse(response, ['response', 'data'])

        yield put(codeTableSetOptionsAction(data, options.table))
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
    }
}

export function* watchCodeTableOptions() {
    yield takeEvery(CODE_TABLE_OPTIONS, codeTableOptionsSaga)
}

function* CodeTableSaga() {
    yield all([
        fork(watchCodeTableOptions),
    ])
}


export default CodeTableSaga