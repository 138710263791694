import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import lomograpikaLogo from 'assets/images/logo/lomograpika-logo.png'

class Features extends Component {
    constructor(props) {
        super(props)
        this.state = {
            footerLinks: [
                {
                    title: "Contact Us",
                    links: [
                        { title: "About Us", link: "#" },
                        { title: "Features", link: "#" },
                        { title: "Team", link: "#" },
                        { title: "News", link: "#" },
                        { title: "FAQs", link: "#" },
                    ],
                },
                {
                    title: "Resources",
                    links: [
                        { title: "Whitepaper", link: "#" },
                        { title: "Token sales", link: "#" },
                        { title: "Privacy Policy", link: "#" },
                        { title: "Terms & Conditions", link: "#" },
                    ],
                },
                {
                    title: "Links",
                    links: [
                        { title: "Tokens", link: "#" },
                        { title: "Roadmap", link: "#" },
                        { title: "FAQs", link: "#" },
                    ],
                },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <footer className="landing-footer">
                    <Container>
                        <Row>
                            <Col>
                                <div className="mb-4 mb-lg-0">
                                    <div className="mb-3">
                                        <img src={lomograpikaLogo} width="200" />
                                    </div>
                                    <p>A group of collaborative creatives that specializes in different genres of photography, web development and technical recruitment.</p>
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-4 mb-lg-0">
                                    <h5 className="mb-3 footer-list-title">Contact Us</h5>
                                    <div className="blog-post">
                                        <div className="post">
                                            <p>Address: Manila, Philippines</p>
                                            <p>Website: <a href="http://lomograpika.com" className="text-info">lomograpika.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        )
    }
}

export default Features
