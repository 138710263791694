import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import StarRatings from 'react-star-ratings'

class StarRatingsComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            starRatedColor: '#F1B44C',
            starEmptyColor: '#2D363F',
            numberOfStars: 5,
            name: 'rating',
            starDimension: '14px',
            starSpacing: '1px',
        }
    }

    render() {
        return (
            <React.Fragment>
                <StarRatings
                    rating={this.props.rating}
                    starRatedColor={typeof this.props.starRatedColor == 'undefined' ? this.state.starRatedColor : this.props.starRatedColor}
                    starEmptyColor={typeof this.props.starEmptyColor == 'undefined' ? this.state.starEmptyColor : this.props.starEmptyColor}
                    numberOfStars={typeof this.props.numberOfStars == 'undefined' ? this.state.numberOfStars : this.props.numberOfStars}
                    name={typeof this.props.name == 'undefined' ? this.state.name : this.props.name}
                    starDimension={typeof this.props.starDimension == 'undefined' ? this.state.starDimension : this.props.starDimension}
                    starSpacing={typeof this.props.starSpacing == 'undefined' ? this.state.starSpacing : this.props.starSpacing}
                />
            </React.Fragment>
        )
    }
}

StarRatingsComponent.propTypes = {
    t: PropTypes.any,
    rating: PropTypes.number.isRequired,
    starRatedColor: PropTypes.any,
    starEmptyColor: PropTypes.any,
    numberOfStars: PropTypes.number,
    name: PropTypes.any,
    starDimension: PropTypes.any,
    starSpacing: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(StarRatingsComponent))
