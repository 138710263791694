import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap'
import Select from 'react-select'
import {ErrorMessage} from 'formik'

class EventDetails extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <CardTitle>{this.props.t('Event Details')}</CardTitle>
                <Row>
                    <Col>
                        <Select
                            name="guest_count"
                            options={this.props.ddlbCodeTable.guest_counts}
                            onChange={(e) => (this.props.handleDdlbChange(e, 0, 'guest_count'))}
                            classNamePrefix="select2-selection"
                            className={this.props.handleFieldValidation(this.props.errors, this.props.touched, 'guest_count')}
                            isClearable={false}
                            placeholder={'Number of guests'}
                            required
                            value={this.props.handleDdlbDefaultValue('guest_count')}
                        />
                        <ErrorMessage
                            name="guest_count"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

EventDetails.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    handleDdlbChange: PropTypes.any.isRequired,
    handleDdlbDefaultValue: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const ddlbCodeTable = {
        ...state.CodeTable.data
    }

    return {
        ddlbCodeTable
    }
}

export default withTranslation()(connect(mapStateToProps, {
})(EventDetails))