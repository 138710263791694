import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom"
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {ErrorMessage, Field, Formik} from 'formik'
import {Col, Label, Row, Spinner, Card, CardBody} from 'reactstrap'
import * as Yup from 'yup'
import Select from 'react-select'
import {
    codeTableOptionsAction,
    businessInfoAction,
    businessInfoFormAction,
    businessInfoUpdateAction,
} from 'app.cms.react/store/actions'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import { getPropTraverse } from 'app.cms.react/helpers/utils'
import FormAlert from '../Common/Alerts/FormAlert'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary" onClick={(e) => props.handleFormSubmit()}>Save changes</button>)
            }
        </React.Fragment>
    )
}

class FormBusinessInfo extends Component {
    constructor(props) {
        super(props)

        this.props.codeTableOptionsAction({
            table: 'countries',
            type: 'ddlb'
        })

        this.props.businessInfoAction()
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleDdlbChange = this.handleDdlbChange.bind(this)
        this.handleTextDefaultValue = this.handleTextDefaultValue.bind(this)
        this.handleFieldChange = this.handleFieldChange.bind(this)
    }

    handleDdlbChange = (value, idx, type) => {
        switch (type) {
            case 'country':
                this.handleDdlbValue(value.label, value.value)
                this.props.businessInfoFormAction({[type]: value})
                break
        }
    }

    handleDdlbValue = (code, value) => {
        return {
            label: value,
            value: code,
        }
    }

    handleFieldChange(type, value) {
        this.props.businessInfoFormAction({[type]: value})
    }

    handleTextDefaultValue(type) {
        switch (type) {
            case 'address_line_1':
            case 'address_line_2':
                if (getPropTraverse(this.props.data.business.business_address_details[0], [type]) != null) {
                    return this.props.data.business.business_address_details[0][type]
                }
                break
            case 'contact_number':
                if (getPropTraverse(this.props.data.business.business_contact_details[0], [type]) != null) {
                    return this.props.data.business.business_contact_details[0][type]
                }
                break
            default:
                if (getPropTraverse(this.props.data.business, [type]) != null) {
                    return this.props.data.business[type]
                }
        }
    }

    handleFormSubmit() {
        this.props.businessInfoUpdateAction(this.props.data.business)
    }

    handleValidationSchema() {
        return Yup.object().shape({
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                {(Object.keys(this.props.data).length > 0) ? (
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={this.props.data.business || this.state}
                                        // initialValues={this.props.data.business}
                                        validationSchema={this.handleValidationSchema()}
                                        onSubmit={(values) => this.handleFormSubmit(values)}
                                    >
                                        {({errors, status, touched}) => (
                                            <React.Fragment>
                                                <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Label className={'col-md-1 col-form-label'} htmlFor="business_name">{this.props.t('Business Name')}</Label>
                                                            <Col>
                                                                <Field
                                                                    name="business_name"
                                                                    placeholder={this.props.t('Business Name')}
                                                                    type="text"
                                                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'business_name')}
                                                                    onChange={(e) => {
                                                                        this.handleFieldChange('business_name', e.target.value)
                                                                    }}
                                                                    value={this.handleTextDefaultValue('business_name')}
                                                                    disabled={this.props.disableFields}
                                                                />
                                                                <ErrorMessage
                                                                    name="business_name"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {this.props.data.business.business_address_details.map((item1, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <Row className={"mt-3"}>
                                                            <Label className={'col-md-1 col-form-label'} htmlFor="address_line_1">{this.props.t('Address Line 1')}</Label>
                                                            <Col>
                                                                <Field
                                                                    name="address_line_1"
                                                                    placeholder={this.props.t('Address Line 1')}
                                                                    type="text"
                                                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'address_line_1')}
                                                                    onChange={(e) => {
                                                                        this.handleFieldChange('address_line_1', e.target.value)
                                                                    }}
                                                                    value={this.handleTextDefaultValue('address_line_1')}
                                                                    disabled={this.props.disableFields}
                                                                />
                                                                <ErrorMessage
                                                                    name="address_line_1"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className={"mt-3"}>
                                                            <Label className={'col-md-1 col-form-label'} htmlFor="address_line_2">{this.props.t('Address Line 2')}</Label>
                                                            <Col>
                                                                <Field
                                                                    name="address_line_2"
                                                                    placeholder={this.props.t('Address Line 2')}
                                                                    type="text"
                                                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'address_line_2')}
                                                                    onChange={(e) => {
                                                                        this.handleFieldChange('address_line_2', e.target.value)
                                                                    }}
                                                                    value={this.handleTextDefaultValue('address_line_2')}
                                                                    disabled={this.props.disableFields}
                                                                />
                                                                <ErrorMessage
                                                                    name="address_line_2"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className={"mt-3"}>
                                                            <Label className={'col-md-1 col-form-label'} htmlFor="country_name">{this.props.t('Country')}</Label>
                                                            <Col>
                                                                <Select
                                                                    name="country_name"
                                                                    options={this.props.ddlbCountries.countries}
                                                                    onChange={(e) => this.handleDdlbChange(e, idx, 'country')}
                                                                    classNamePrefix="select2-selection"
                                                                    isClearable={false}
                                                                    value={this.handleDdlbValue(item1.country_code, item1.country_name)}
                                                                    isDisabled={this.props.formLoading}
                                                                    required
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                                {this.props.data.business.business_contact_details.map((item1, idx) => (
                                                    <React.Fragment key={idx}>
                                                        <Row className={"mt-3"}>
                                                            <Label className={'col-md-1 col-form-label'} htmlFor="contact_number">{this.props.t('Contact Number')}</Label>
                                                            <Col>
                                                                <Field
                                                                    name="contact_number"
                                                                    placeholder={this.props.t('Contact Number')}
                                                                    type="text"
                                                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'contact_number')}
                                                                    onChange={(e) => {
                                                                        this.handleFieldChange('contact_number', e.target.value)
                                                                    }}
                                                                    value={this.handleTextDefaultValue('contact_number')}
                                                                    disabled={this.props.disableFields}
                                                                />
                                                                <ErrorMessage
                                                                    name="contact_number"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </Formik>
                                ) : (
                                    <div className="text-center"><Spinner className="m-2" color="primary"/></div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end mb-4">
                        <FormSubmitButtons handleFormSubmit={this.handleFormSubmit} formLoading={this.props.formLoading} />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

FormBusinessInfo.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    const ddlbCountries = {
        ...state.CodeTable.data
    }

    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        data,
        alertType,
        response,
    } = state.BusinessInfo

    console.log(state.BusinessInfo)

    return {
        ddlbCountries,
        disableFields,
        formSubmit,
        formLoading,
        data,
        alertType,
        response,
    }
}

export default connect(mapStateToProps, {
    codeTableOptionsAction,
    businessInfoAction,
    businessInfoFormAction,
    businessInfoUpdateAction,
})(withRouter(withTranslation()(FormBusinessInfo)))