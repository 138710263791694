import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DataTableLoader from 'app.cms.react/components/Common/DataTable/DataTableLoader'
import {Card, CardBody, Col, Row, List, ListInlineItem} from 'reactstrap'
import DataTable from 'app.cms.react/components/Common/DataTable/DataTable'
import {
    componentModalToggleAction,
    componentFormInitAction,
    packageBuilderAction,
    packageBuilderDeleteAction,
    packageBuilderInitAction
} from 'store/actions'
import {Link, withRouter} from 'react-router-dom'
import {numberFormatComma, pluckRouteFromName} from 'app.cms.react/helpers/utils'
import ModalDelete from '../Common/Modals/ModalDelete';

const formatName = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div>{cell}</div>
            <span className="badge badge-soft-primary">{row.category.category_name}</span>
        </React.Fragment>
    )
}

const formatPrice = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <span className="float-end">{row.currency_symbol} {numberFormatComma(cell)}</span>
        </React.Fragment>
    )
}

const formatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div className="float-end">
                <List type="inline" className="p-0 m-0">
                    <ListInlineItem><Link to={pluckRouteFromName('update-package', {id: row.id})}><i className="bx bx-pencil text-primary"/></Link></ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleDelete(row, e)
                    }}><i className="bx bx-trash text-danger"/></ListInlineItem>
                </List>
            </div>
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'package_name',
        text: 'Name',
        sort: true,
        headerClasses: 'col-md-3',
        formatter: formatName,
    },
    {
        dataField: 'package_price',
        text: 'Package Price',
        sort: true,
        headerClasses: 'text-end col-md-3',
        formatter: formatPrice,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        headerClasses: 'text-end col-md-3',
        formatter: formatActions,
        formatExtraData: {
            toggleDelete: props.toggleDelete,
        }
    },
]

class TablePackages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                id: null,
                name: '',
                description: '',
                price: 0.0,
            },
            page: 1,
            limit: 10,
            toggleAddLinkProps: {
                linkTo: pluckRouteFromName('create-package'),
                linkText: 'Build Package',
            },
            toggleDeleteModalProps: {
                target: 'delete',
                isOpen: true
            }
        }

        this.props.packageBuilderAction({categoryable_type_code: this.props.categoryType})
        this.toggleDelete = this.toggleDelete.bind(this)
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
            }
        })
    }

    toggleDelete(row) {
        this.setStateSelected(row)
        this.props.componentModalToggleAction(this.state.toggleDeleteModalProps)
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader
                    data={this.props.data}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddModal={this.state.toggleAddModalProps}
                    toggleAddLink={this.state.toggleAddLinkProps}
                    dataTable={
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                columns={columns({
                                                    toggleDelete: this.toggleDelete,
                                                })}
                                                data={this.props.data}
                                                isDataTableLoading={this.props.isDataTableLoading}
                                                toggleAddLink={this.state.toggleAddLinkProps}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                />
                <ModalDelete
                    primaryKey={
                        {
                            id: this.state.selected.id
                        }
                    }
                    params={
                        {
                            categoryable_type_code: this.props.categoryType
                        }
                    }
                    deleteAction={this.props.packageBuilderDeleteAction}
                    alertType={this.props.alertType}
                    response={this.props.response}
                    resetAlertAction={this.props.packageBuilderInitAction}
                />
            </React.Fragment>
        )
    }
}

TablePackages.propTypes = {
    t: PropTypes.any,
    categoryType: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const {
        data,
        alertType,
        response,
    } = state.PackageBuilder

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        isDataTableLoading,
        data,
        alertType,
        response,
    }
}

export default connect(mapStateToProps, {
    packageBuilderAction,
    packageBuilderDeleteAction,
    packageBuilderInitAction,
    componentModalToggleAction,
    componentFormInitAction,
})(withRouter(withTranslation()(TablePackages)))