import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap'
import {ErrorMessage, Field} from 'formik'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'

class Celebrant extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <CardTitle>{this.props.t('Celebrant')}</CardTitle>
                <Row>
                    <Col>
                        <Field
                            name="celebrant_first_name"
                            placeholder={this.props.t('First name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'celebrant_first_name')}
                            onChange={(e) => {
                                this.props.handleFieldChange('celebrant_first_name', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('celebrant_first_name')}
                        />
                        <ErrorMessage
                            name="celebrant_first_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="celebrant_last_name"
                            placeholder={this.props.t('Last name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'celebrant_last_name')}
                            onChange={(e) => {
                                this.props.handleFieldChange('celebrant_last_name', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('celebrant_last_name')}
                        />
                        <ErrorMessage
                            name="celebrant_last_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="celebrant_nickname"
                            placeholder={this.props.t('Nickname')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'celebrant_nickname')}
                            onChange={(e) => {
                                this.props.handleFieldChange('celebrant_nickname', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('celebrant_nickname')}
                        />
                        <ErrorMessage
                            name="celebrant_nickname"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Flatpickr
                            id="celebrant_date_of_birth"
                            name="celebrant_date_of_birth"
                            placeholder={this.props.t('Date of birth')}
                            className="form-control bg-white"
                            options={{
                                altInput: true,
                                altFormat: "F d Y",
                                dateFormat: "Y-m-d"
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                this.props.handleDdlbChange(dateStr, 0, 'celebrant_date_of_birth')
                            }}
                            value={this.props.handleTextDefaultValue('celebrant_date_of_birth')}
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Select
                            name="celebrant_gender"
                            options={this.props.ddlbCodeTable.gender_types}
                            onChange={(e) => (this.props.handleDdlbChange(e, 0, 'celebrant_gender'))}
                            classNamePrefix="select2-selection"
                            className={this.props.handleFieldValidation(this.props.errors, this.props.touched, 'celebrant_gender')}
                            isClearable={false}
                            placeholder={'Gender'}
                            required
                            value={this.props.handleDdlbDefaultValue('celebrant_gender')}
                        />
                        <ErrorMessage
                            name="celebrant_gender"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

Celebrant.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    handleTextDefaultValue: PropTypes.any.isRequired,
    handleFieldChange: PropTypes.any.isRequired,
    handleDdlbChange: PropTypes.any.isRequired,
    handleDdlbDefaultValue: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const ddlbCodeTable = {
        ...state.CodeTable.data
    }

    return {
        ddlbCodeTable
    }
}

export default withTranslation()(connect(mapStateToProps, {
})(Celebrant))