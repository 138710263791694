import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Row} from 'reactstrap'
import ModalViewPackageDetails from 'components/Bookings/ModalViewPackageDetails'
import {getPropTraverse, numberFormatComma} from 'app.cms.react/helpers/utils'
import {componentModalToggleAction} from 'app.cms.react/store/components/modal/actions'
import {
    bookingsFormAction
} from 'store/actions'
import parse from 'html-react-parser'
import kiddiePriceList from 'assets/images/lomograpika-kiddie-price-list.jpg'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

class Packages extends Component {
    constructor(props) {
        super(props)

        this.state = {
            packageDetailView: {},
            toggleViewPackageDetailsProps: {
                target: 'bookingPackageDetails',
                isOpen: true,
            },
            isFits: false,
        }

        this.handleViewPackageDetailsClick = this.handleViewPackageDetailsClick.bind(this)
        this.handleSelectPackageClick = this.handleSelectPackageClick.bind(this)
    }

    handleViewPackageDetailsClick(e, val) {
        e.preventDefault()
        this.setState({
            packageDetailView: val
        })
        this.props.componentModalToggleAction(this.state.toggleViewPackageDetailsProps)
    }

    handleSelectPackageClick(val) {
        this.props.bookingsFormAction({package: val})
    }

    renderSelectedPackageButton(isSelected, packageItems) {
        let text = (isSelected) ? this.props.t('Selected') + ': ' : this.props.t('Book now for')
        text += ' ' + numberFormatComma(packageItems.package_price)

        return (
            <React.Fragment>{text}</React.Fragment>
        )
    }

    render() {
        const {packageDetailView} = this.state
        const {bookingForm} = this.props
        let isSelected = false


        return (
            <React.Fragment>
                {this.state.isFits ? (
                    <Lightbox
                        mainSrc={kiddiePriceList}
                        enableZoom={true}
                        imageCaption={
                        "Caption. Can be aligned it to any side and contain any HTML."
                        }
                        onCloseRequest={() => this.setState({ isFits: false })}
                    />
                ) : null}
                <Row className="mb-3">
                    <Col className="col-md-6 offset-md-3">
                        <div className="img-container" onClick={() => this.setState({ isFits: true })}>
                            <img
                                src={kiddiePriceList}
                                className="img-thumbnail"
                            />
                            <div className="img-overlay">
                                <div className="img-overlay-text">{this.props.t('Click to view full image')}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="row align-items-center">
                    {(Object.entries(packageDetailView).length > 0) ? (
                        <ModalViewPackageDetails details={packageDetailView} bookingFormPackage={getPropTraverse(bookingForm, ['package'])} />
                    ) : null}
                    <CardTitle>{this.props.t('Select a package')}</CardTitle>
                    {Object.entries(this.props.packages).map(([key, packageItems]) => (
                        <Col key={key} md={4}>
                            {isSelected = (getPropTraverse(bookingForm, ['package', 'id']) != null && (bookingForm.package.id == packageItems.id)) ? true : false}
                            <Card className="plan-box card-packages-mini">
                                <CardHeader className="h6 bg-transparent text-truncate border-bottom">{packageItems.package_name}</CardHeader>
                                <CardBody>
                                    <div className="description-truncate mb-2">{(packageItems.description != null) ? parse(packageItems.description) : ''}</div>
                                    <CardText><Button color={'link'} onClick={(e) => this.handleViewPackageDetailsClick(e, packageItems)}>Click here to view full details</Button ></CardText>
                                    <div className="d-grid gap-2">
                                        <button type="button" className={'btn ' + (isSelected ? 'btn-success' : 'btn-primary')} onClick={(e) => this.handleSelectPackageClick(packageItems)}>
                                            {this.renderSelectedPackageButton(isSelected, packageItems)}
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </React.Fragment>
        )
    }
}

Packages.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const packages = {
        ...state.PackageBuilder.data
    }

    const bookingForm = {
        ...state.Bookings.form
    }

    return {
        packages,
        bookingForm,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    bookingsFormAction,
})(Packages))