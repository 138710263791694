import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom"
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {ErrorMessage, Field} from 'formik'
import {Col, Row} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import { NumericFormat } from 'react-number-format'
import Select from 'react-select'
import { ddlbParser } from 'app.cms.react/helpers/utils'

class FormAddPayment extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Flatpickr
                            id="payment_date"
                            name="payment_date"
                            placeholder={this.props.t('Payment date')}
                            className="form-control bg-white"
                            options={{
                                altInput: true,
                                altFormat: "F d, Y",
                                dateFormat: "Y-m-d"
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                this.props.handleDdlbChange(dateStr, 'payment_date')
                            }}
                            disabled={this.props.disableFields}
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <NumericFormat
                            allowNegative={false}
                            name="total_amount"
                            type="text"
                            className={'form-control ' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'total_amount')}
                            disabled={this.props.disableFields}
                            placeholder={'Amount'}
                            onChange={(e) => (this.props.handleTextDefaultValue(e, 'total_amount'))}
                        />
                        <ErrorMessage
                            name="price"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Select
                            name="payment_term"
                            placeholder="Payment term"
                            options={this.props.ddlbCodeTable.payment_terms}
                            onChange={(e) => (this.props.handleDdlbChange(e, 'payment_term'))}
                            classNamePrefix="select2-selection "
                            isClearable={true}
                            isDisabled={this.props.formLoading}
                            required
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Select
                            name="payment_method"
                            placeholder="Payment method"
                            options={this.props.ddlbCodeTable.payment_methods}
                            onChange={(e) => (this.props.handleDdlbChange(e, 'payment_method'))}
                            classNamePrefix="select2-selection "
                            isClearable={true}
                            isDisabled={this.props.formLoading}
                            required
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Select
                            name="payment_account"
                            placeholder="Payment account"
                            options={this.props.ddlbCodeTable.payment_accounts}
                            onChange={(e) => (this.props.handleDdlbChange(e, 'payment_account'))}
                            classNamePrefix="select2-selection "
                            isClearable={true}
                            isDisabled={this.props.formLoading}
                            required
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="notes"
                            as="textarea"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'notes')}
                            disabled={this.props.disableFields}
                            rows={5}
                            placeholder="Notes"
                            onChange={(e) => (this.props.handleTextDefaultValue(e, 'notes'))}
                        />
                        <ErrorMessage
                            name="notes"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

FormAddPayment.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    disableFields: PropTypes.any.isRequired,
    handleDdlbChange: PropTypes.any.isRequired,
    handleTextDefaultValue: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const ddlbCodeTable = {
        ...state.CodeTable.data
    }

    return {
        ddlbCodeTable
    }
}

export default connect(mapStateToProps, {

})(withRouter(withTranslation()(FormAddPayment)))