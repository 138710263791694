import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap'
import EventDetails from 'components/Bookings/Fields/EventDetails'
import {
    bookingsFormAction,
    bookingsInitAction,
    codeTableOptionsAction,
    packageBuilderDetailsAction,
    productsServicesAction
} from 'store/actions'
import Celebrant from 'components/Bookings/Fields/Celebrant'
import ContactPerson from 'components/Bookings/Fields/ContactPerson'
import ReceptionDetails from 'components/Bookings/Fields/ReceptionDetails'
import Packages from 'components/Bookings/Fields/Packages'
import ProductsServicesAddOns from 'components/Bookings/Fields/ProductsServicesAddOns'

class FormBirthdayOnly extends Component {
    constructor(props) {
        super(props)

        if (!this.props.isEditMode) {
            this.props.bookingsInitAction()
        }

        this.props.packageBuilderDetailsAction({
            category_code: this.props.categoryableCode,
            category_type_code: this.props.categoryableTypeCode,
            token: this.props.token,
        })
        this.props.productsServicesAction({
            is_addon: 1,
            token: this.props.token,
        })
        this.props.bookingsFormAction({
            venue_types: ['reception'],
            persona_types: ['celebrant']
        })
        this.props.codeTableOptionsAction({
            table: 'countries',
            type: 'ddlb',
            token: this.props.token,
        })
        this.props.codeTableOptionsAction({
            table: 'gender_types',
            type: 'ddlb',
            token: this.props.token,
        })
        this.props.codeTableOptionsAction({
            table: 'guest_counts',
            type: 'ddlb',
            token: this.props.token,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Packages
                            errors={this.props.errors}
                            touched={this.props.touched}
                            handleFieldValidation={this.props.handleFieldValidation}
                        />
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <ProductsServicesAddOns />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <ContactPerson
                                    errors={this.props.errors}
                                    touched={this.props.touched}
                                    handleFieldValidation={this.props.handleFieldValidation}
                                    handleTextDefaultValue={this.props.handleTextDefaultValue}
                                    handleFieldChange={this.props.handleFieldChange}
                                    handleDdlbChange={this.props.handleDdlbChange}
                                    handleDdlbDefaultValue={this.props.handleDdlbDefaultValue}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <Celebrant
                                    errors={this.props.errors}
                                    touched={this.props.touched}
                                    handleFieldValidation={this.props.handleFieldValidation}
                                    handleTextDefaultValue={this.props.handleTextDefaultValue}
                                    handleFieldChange={this.props.handleFieldChange}
                                    handleDdlbChange={this.props.handleDdlbChange}
                                    handleDdlbDefaultValue={this.props.handleDdlbDefaultValue}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <EventDetails
                                    errors={this.props.errors}
                                    touched={this.props.touched}
                                    handleFieldValidation={this.props.handleFieldValidation}
                                    handleDdlbChange={this.props.handleDdlbChange}
                                    handleDdlbDefaultValue={this.props.handleDdlbDefaultValue}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <ReceptionDetails
                                    errors={this.props.errors}
                                    touched={this.props.touched}
                                    handleFieldValidation={this.props.handleFieldValidation}
                                    handleTextDefaultValue={this.props.handleTextDefaultValue}
                                    handleFieldChange={this.props.handleFieldChange}
                                    handleDdlbChange={this.props.handleDdlbChange}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

FormBirthdayOnly.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    isEditMode: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    categoryableCode: PropTypes.any.isRequired,
    categoryableTypeCode: PropTypes.any.isRequired,
    handleDdlbChange: PropTypes.any.isRequired,
    handleDdlbDefaultValue: PropTypes.any.isRequired,
    handleFieldChange: PropTypes.any.isRequired,
    handleTextDefaultValue: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const {
        token,
    } = state.Globals

    return {
        token
    }
}

export default withTranslation()(connect(mapStateToProps, {
    bookingsFormAction,
    codeTableOptionsAction,
    bookingsInitAction,
    packageBuilderDetailsAction,
    productsServicesAction,
})(FormBirthdayOnly))