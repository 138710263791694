import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom"
import {withTranslation} from 'react-i18next'
import {Label} from 'reactstrap'
import PropTypes from 'prop-types'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {signUpUserAction} from 'app.cms.react/store/actions'
import FormAlert from '../Common/Alerts/FormAlert'
import ButtonLoading from '../Common/Buttons/ButtonLoading'
import ListSocialite from '../Socialite/ListSocialite'
import {getClientIp} from '../../helpers/utils'

class FormSignUp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
        }
    }

    handleFormSubmit(values) {
        this.props.signUpUserAction(values, this.props.history)
    }

    handleValidationSchema() {
        return Yup.object().shape({
            first_name: Yup.string().required(
                this.props.t('First name is required')
            ),
            last_name: Yup.string().required(
                this.props.t('Last name is required')
            ),
            email: Yup.string().required(
                this.props.t('Email is required')
            ),
            password: Yup.string().required(
                this.props.t('Password is required')
            ),
            password_confirmation: Yup.string().required(
                this.props.t('Confirm password is required')
            ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    render() {
        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.form}
                    validationSchema={this.handleValidationSchema()}
                    onSubmit={(values) => this.handleFormSubmit(values)}
                >
                    {({errors, status, touched}) => (
                        <Form className="form-horizontal">
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />

                            <div className="mb-3">
                                <Field
                                    name="first_name"
                                    placeholder={this.props.t('First Name')}
                                    type="text"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'last_name')}
                                />
                                <ErrorMessage
                                    name="first_name"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <Field
                                    name="last_name"
                                    placeholder={this.props.t('Last Name')}
                                    type="text"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'last_name')}
                                />
                                <ErrorMessage
                                    name="last_name"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <Field
                                    name="email"
                                    placeholder={this.props.t('Email')}
                                    type="email"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'email')}
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <Field
                                    name="password"
                                    placeholder={this.props.t('Password')}
                                    type="password"
                                    autoComplete="true"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'password')}
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <Field
                                    name="password_confirmation"
                                    placeholder={this.props.t('Confirm Password')}
                                    type="password"
                                    autoComplete="true"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'password_confirmation')}
                                />
                                <ErrorMessage
                                    name="password_confirmation"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mt-3 d-grid">
                                <ButtonLoading
                                    formLoading={this.props.formLoading}
                                    buttonClassName="btn btn-primary btn-block"
                                    buttonName={this.props.t('Sign Up')}
                                />
                            </div>

                            {(this.props.hasGoogleSocialite || this.props.hasFacebookSocialite || this.props.hasTwitterSocialite) ? (
                                <div className="mt-4 text-center">
                                    <h5 className="font-size-14 mb-3">{this.props.t('Sign up with')}</h5>
                                    <ListSocialite
                                        hasGoogle={this.props.hasGoogleSocialite}
                                        hasFacebook={this.props.hasFacebookSocialite}
                                        hasTwitter={this.props.hasTwitterSocialite}
                                    />
                                </div>
                            ) : null}
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        )
    }
}

FormSignUp.propTypes = {
    hasGoogleSocialite: PropTypes.bool.isRequired,
    hasFacebookSocialite: PropTypes.bool.isRequired,
    hasTwitterSocialite: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    let {
        response,
        alertType,
    } = state.SignUp

    let {
        disableFields,
        formLoading,
    } = state.ComponentsForm

    return {
        response,
        alertType,
        formLoading,
        disableFields
    }
}


export default connect(mapStateToProps, {
    signUpUserAction
})(withRouter(withTranslation()(FormSignUp)))