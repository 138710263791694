import {allRoutes} from '../../routes'
import jwtDecode from 'jwt-decode'
import { property } from 'lodash'
import moment from 'moment'

export const getSubdomain = () => {
    return window.location.host.split('.')[0]
}

export const setJWTToken = (data) => {
    localStorage.setItem('jwt', data)
}

export const getJWTToken = () => {
    let jwt = localStorage.getItem('jwt')
    return (jwt != null && jwt != '') ? jwt : false
}

export const unsetJWTToken = () => {
    localStorage.removeItem('jwt')
}

export const isUserAuthenticated = () => {
    const jwtToken = getJWTToken(),
        decoded = (jwtToken !== false) ? jwtDecode(jwtToken) : null,
        currentTime = Date.now() / 1000,
        isAuthenticated = (decoded != null) ? decoded.exp > currentTime : false

    if (isAuthenticated == false) {
        unsetJWTToken()
    }

    return isAuthenticated
}

export const getPropTraverse = (data, props, forRender = false) => {
    if (typeof (data) == 'object' && data != null && Array.isArray(props) && props.length > 0) {
        if (props.length > 1) {
            var propKey = props[0]
            if (data.hasOwnProperty(propKey)) {
                props.shift()
                return getPropTraverse(data[propKey], props)
            } else {
                return null
            }
        } else {
            return (data.hasOwnProperty(props[0])) ? data[props[0]] : (forRender) ? '' : null
        }
    }
    return (forRender) ? '' : null
}

export const getErrorResponse = response => {
    return getPropTraverse(response, ['response', 'data', 'response', 'error'])
}

export const getSuccessResponse = response => {
    return getPropTraverse(response, ['response', 'data'])
}

export const pluckRouteFromName = (routeName, params) => {
    const arrRoute = [...allRoutes]
    const routes = arrRoute.find(obj => obj.name === routeName)
    let result = routes.path

    if (typeof routes != 'undefined') {
        if (typeof params != 'undefined') {
            for (const [key, value] of Object.entries(params)) {
                result = result.replace(`:${key}`, value);
            }

            return result
        }

        return routes.path
    }

    return '#'
}

export const pluckRouteBasePathFromName = routeName => {
    const arrRoute = [...allRoutes]
    const routes = arrRoute.find(obj => obj.name === routeName)

    if (typeof routes != 'undefined') {
        return routes.nonParamPath
    }

    return '#'
}

export const numberFormatComma = (num) => {
    return Number(num).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
}

export const objToUrlParams = (obj) => {
    return Object.entries(obj).map(([key, val]) => `${key}=${val}`).join('&')
}

export const buildUrlParams = (options) => {
    return (typeof options != 'undefined') ? '?' + objToUrlParams(options) : ''
}

export const dateFormat = (pDate, pFormatDate) => {
    let formatDate = (typeof pFormatDate == 'undefined') ? 'MMM DD, YYYY' : pFormatDate

    return (typeof pDate.month != 'undefined' && pDate.day != 'undefined' && pDate.year != 'undefined')
        ? moment(pDate.month + '-' + pDate.day +  '-' + pDate.year).format(formatDate)
        : moment(pDate).format(formatDate)
}

export const dateDiff = (from, to) => {
    return moment().diff(from, 'days')
}

// export const fetchJSON = (url, options = {}) => {
//     return fetch(url, options)
//         .then(response => {
//             return response.json().then(data => {
//                 if (response.ok) {
//                     return data
//                 } else {
//                     return Promise.reject({status: response.status, data})
//                 }
//             })
//         })
//         .then(result => {
//             return result
//         })
//         .catch(error => {
//             throw error
//         })
// }
//
// export const defaultGetOptions = () => {
//     return {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'X-Requested-With': 'XMLHttpRequest',
//             'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
//         },
//         credentials: 'same-origin',
//     }
// }
//
// export const defaultPostBloblOptions = () => {
//     return {
//         method: 'POST',
//         headers: new Headers({
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'X-Requested-With': 'XMLHttpRequest',
//             'Authorization': 'Bearer ' + localStorage.getItem('jwt')
//         }),
//         credentials: 'same-origin',
//         responseType: 'blob',
//     }
// }
//
// export const defaultPostOptions = () => {
//     return {
//         method: 'POST',
//         headers: new Headers({
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'X-Requested-With': 'XMLHttpRequest',
//             'Authorization': 'Bearer ' + localStorage.getItem('jwt')
//         }),
//         credentials: 'same-origin',
//     }
// }
//
// export const defaultPutOptions = () => {
//     return {
//         method: 'PUT',
//         headers: new Headers({
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'X-Requested-With': 'XMLHttpRequest',
//             'Authorization': 'Bearer ' + localStorage.getItem('jwt')
//         }),
//         credentials: 'same-origin',
//     }
// }
//
// export const defaultDeleteOptions = () => {
//     return {
//         method: 'DELETE',
//         headers: new Headers({
//             'Content-Type': 'application/x-www-form-urlencoded',
//             'X-Requested-With': 'XMLHttpRequest',
//             'Authorization': 'Bearer ' + localStorage.getItem('jwt')
//         }),
//         credentials: 'same-origin',
//     }
// }
//
export const formatFormData = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export const formArgsExcludeEmpty = (args) => {
    if (typeof args == 'undefined') return {}

    Object.keys(args).map((key) => {
        if (args[key] == null || (args[key] != null && args[key].length == 0)) {
            delete args[key];
        }
    });

    return args;
}

export const ddlbParser = (obj, needle, merge) => {
    let ddlbObj = []
    let merger = {}

    Object.keys(obj).map((key) => {
        if (typeof merge != 'undefined') {
            merger = {
                ...obj[key], ...{
                    label: obj[key][needle.label],
                    value: obj[key][needle.value],
                }
            }
        } else {
            merger = {
                label: obj[key][needle.label],
                value: obj[key][needle.value],
            }
        }

        ddlbObj.push(merger)
    })

    return ddlbObj
}

export const decimalPlace = (num, decimal = 2) => {
    return Number(num).toFixed(decimal)
}

export const containsWord = (str, searchString) => {
    return str.includes(searchString);
}

export const findPropertyValueInObject = (haystack, property, needle) => {
    return haystack.find(event => event[property] === needle)
}