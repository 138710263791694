import {takeEvery, put, call} from 'redux-saga/effects'
import {RESET_PASSWORD} from './actionTypes'
import * as API_URL from 'app.cms.react/store/actionTypes'
import {
    getSuccessResponse,
    getErrorResponse,
} from 'app.cms.react/helpers/utils'
import {apiPost} from 'app.cms.react/helpers/apiHelpers'
import {
    resetPasswordSuccessAction,
    resetPasswordFailAction,
    componentFormOnSubmitAction,
    componentFormOnSubmitDoneAction
} from '../actions'

function* resetPassword({payload: {params, history}}) {
    let componentResponse = {}

    try {
        yield put(componentFormOnSubmitAction())

        const response = yield call(apiPost, API_URL.AJAX_URL_RESET_PASSWORD, params)
        componentResponse = {
            alertType: 'success',
            response: getSuccessResponse(response),
        }
        yield put(resetPasswordSuccessAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(resetPasswordFailAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
    }
}

function* ResetPasswordSaga() {
    yield takeEvery(RESET_PASSWORD, resetPassword)
}

export default ResetPasswordSaga