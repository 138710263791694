import DataTableLoader from 'app.cms.react/components/Common/DataTable/DataTableLoader'
import { dateFormat, decimalPlace, getPropTraverse } from 'app.cms.react/helpers/utils'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardTitle, Col, Row, List, ListInlineItem } from 'reactstrap'
import {
    componentFormInitAction,
    componentModalToggleAction,
    invoicesPaymentsAction,
    paymentsSetSelectedInvoiceAction,
    invoicesPaymentsExportAction,
} from 'store/actions'
import ModalAddPayment from './ModalAddPayment'



class TimelineInvoicePayments extends Component {
    constructor(props) {
        super(props)

        this.state = {
            toggleAddModalProps: {
                target: 'addPayment',
                isOpen: true,
            },
            hasRecordedPayment: false
        }

        this.props.paymentsSetSelectedInvoiceAction(this.props.invoiceId)
        this.props.invoicesPaymentsAction({invoice_id: this.props.invoiceId})
        this.togglePaymentReceiptExport = this.togglePaymentReceiptExport.bind(this)
    }

    togglePaymentReceiptExport(invoiceId, paymentId) {
        this.props.invoicesPaymentsExportAction({
            invoice_id: invoiceId,
            payment_id: paymentId,
        })
    }

    renderPaymentTimelineCard() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <div className="clearfix">
                            <div className="float-end">
                                <Button
                                    type="button"
                                    color="primary"
                                    className="mb-2 me-2"
                                    onClick={(e) => this.props.componentModalToggleAction(this.state.toggleAddModalProps)}
                                >
                                    <i className="mdi mdi-plus me-1" />Add New
                                </Button>
                            </div>
                            <CardTitle className="mb-4 h4">
                                Payments received for invoice {this.props.dataInvoiceDetailPayments.invoice.invoice_code}:
                            </CardTitle>
                        </div>
                        <ul className="verti-timeline list-unstyled">
                            {Object.entries(this.props.dataInvoiceDetailPayments.invoice.payments).map(([objKey, objValue]) => (
                                <React.Fragment key={objKey}>
                                    <li className="event-list pb-3">
                                        <div className="event-timeline-dot">
                                            <i className={'bx bx-right-arrow-circle'} />
                                        </div>
                                        <div className="media">
                                            <div className="media-body">
                                                <div>
                                                    <p className="mb-0 fw-bold text-primary">{dateFormat(objValue.payment_date)}</p>
                                                    <p className="mb-0">
                                                        A payment amounting to <span className="fw-bold">
                                                            {objValue.currencies.currency_symbol} <NumericFormat
                                                            value={decimalPlace(objValue.total_amount)}
                                                            displayType="text"
                                                            thousandSeparator={true}
                                                            decimalSeparator="."
                                                        /></span> was made.
                                                        <br />
                                                        <span className="text-muted">{objValue.payment_terms.payment_term_name} ({objValue.payment_methods.payment_method_name} - {objValue.payment_accounts.payment_account_name})</span>
                                                    </p>
                                                    <List type="inline" className="p-0 m-0">
                                                        <ListInlineItem className="cursor-pointer" onClick={(e) => {
                                                                this.togglePaymentReceiptExport(objValue.invoice_id, objValue.id)
                                                            }}><i className="bx bx-receipt" />
                                                        </ListInlineItem>
                                                        <ListInlineItem className="cursor-pointer" onClick={(e) => {
                                                                this.togglePaymentReceiptExport(objValue.invoice_id, objValue.id)
                                                            }}><i className="bx bx-pencil" />
                                                        </ListInlineItem>
                                                        <ListInlineItem className="cursor-pointer" onClick={(e) => {
                                                                this.togglePaymentReceiptExport(objValue.invoice_id, objValue.id)
                                                            }}><i className="bx bx-trash" />
                                                        </ListInlineItem>
                                                    </List>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

    renderPaymentSummary() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title rounded-circle bg-success bg-soft text-success font-size-18">
                                            <i className="bx bx-copy-alt"/>
                                        </span>
                                    </div>
                                    <h5 className="font-size-14 mb-0">Total Payments Received</h5>
                                </div>
                                <div>
                                    <h4>
                                        {this.props.dataInvoiceDetailPayments.invoice.currency_symbol} <NumericFormat
                                            value={decimalPlace(this.props.dataInvoiceDetailPayments.invoice.total_payment_amount)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                        />
                                    </h4>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title rounded-circle bg-warning bg-soft text-warning font-size-18">
                                            <i className="bx bx-copy-alt"/>
                                        </span>
                                    </div>
                                    <h5 className="font-size-14 mb-0">Remaining Balance</h5>
                                </div>
                                <div>
                                    <h4>
                                        {this.props.dataInvoiceDetailPayments.invoice.currency_symbol} <NumericFormat
                                            value={decimalPlace(this.props.dataInvoiceDetailPayments.invoice.remaining_payment_amount)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                        />
                                        {(Math.sign(this.props.remainingBalance) === -1) ? (
                                            <small><span className='bg-warning badge ms-2'>Over payment</span></small>
                                        ) : null}
                                    </h4>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title rounded-circle bg-soft font-size-18">
                                            <i className="bx bx-copy-alt"/>
                                        </span>
                                    </div>
                                    <h5 className="font-size-14 mb-0">Amount Due</h5>
                                </div>
                                <div>
                                    <h4>
                                        {this.props.dataInvoiceDetailPayments.invoice.currency_symbol} <NumericFormat
                                            value={decimalPlace(this.props.dataInvoiceDetailPayments.invoice.total_amount)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                        />
                                    </h4>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex align-items-center mb-3">
                                    <div className="avatar-xs me-3">
                                        <span className="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                                            <i className="bx bx-copy-alt"/>
                                        </span>
                                    </div>
                                    <h5 className="font-size-14 mb-0">Payment Status</h5>
                                </div>
                                <div>
                                    <h4>
                                        {this.props.dataInvoiceDetailPayments.invoice.invoice_status.payment_status_name}
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

    render() {
        const dataView = getPropTraverse(this.props.dataInvoiceDetailPayments, ['invoice', 'payments'])
        const dataViewHasData = (dataView != null && dataView.length > 0)
        const invoiceData = getPropTraverse(this.props.dataInvoiceDetailPayments, ['invoice'])

        return (
            <React.Fragment>
                <ModalAddPayment onSubmitAction={{
                    type: 'invoicesPaymentsAction',
                    action: this.props.invoicesPaymentsAction,
                    params: {
                        reload: false,
                    }
                }} />
                <DataTableLoader
                    data={this.props.dataInvoiceDetailPayments}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddModal={this.state.toggleAddModalProps}
                    dataView={
                        <React.Fragment>
                            {(invoiceData != null) ? (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            {this.renderPaymentSummary()}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : null}
                            {(dataViewHasData) ? (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            {this.renderPaymentTimelineCard()}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <DataTableLoader
                                        data={''}
                                        isDataTableLoading={this.props.isDataTableLoading}
                                        toggleAddModal={this.state.toggleAddModalProps}
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    }
                />
            </React.Fragment>
        )
    }
}

TimelineInvoicePayments.propTypes = {
    t: PropTypes.any,
    invoiceId: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    const {
        dataInvoiceDetailPayments,
    } = state.Invoices

    const {
        alertType,
    } = state.Payments

    return {
        dataInvoiceDetailPayments,
        isDataTableLoading,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    invoicesPaymentsAction,
    componentFormInitAction,
    componentModalToggleAction,
    paymentsSetSelectedInvoiceAction,
    invoicesPaymentsExportAction,
})(TimelineInvoicePayments))