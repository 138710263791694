import {takeEvery, put, call} from 'redux-saga/effects'
import {
    getPropTraverse,
    buildUrlParams, getErrorResponse, formatFormData, formArgsExcludeEmpty,
} from 'app.cms.react/helpers/utils'
import {MICROSITES, MICROSITES_UPDATE, MICROSITES_ADD, MICROSITES_DELETE} from './actionTypes'
import {AJAX_URL_MICROSITES} from '../actionTypes'
import {
    micrositesDataAction,
    componentFormOnSubmitDoneAction,
    componentDataTableLoadedAction,
    // exceptionHandlerAction,
    componentDataTableLoadingAction,
    componentFormOnSubmitAction,
    loginUserSuccessAction,
    loginUserFailAction,
    micrositesFailAction, micrositesSuccessAction
} from '../actions'
import {apiGet, apiPost, apiPut, apiDel} from 'app.cms.react/helpers/apiHelpers'
import * as API_URL from '../actionTypes'

function* microsites({payload: {params, history}}) {
    try {
        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_MICROSITES)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(micrositesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* micrositesUpdate({payload: {params, meta}}) {
    const options = {
        id: params.id,
        microsite_name: params.microsite_name,
        microsite_desc: params.microsite_desc,
        token: params.token,
    }
    let componentResponse = {}

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_MICROSITES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(micrositesSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_MICROSITES)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(micrositesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(micrositesFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* micrositesAdd({payload: {params, meta}}) {
    const options = formArgsExcludeEmpty({
        microsite_name: params.microsite_name,
        microsite_desc: params.microsite_desc,
        token: params.token,
    })
    let componentResponse = {}

    try {
        const response = yield call(apiPost, API_URL.AJAX_URL_MICROSITES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(micrositesSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_MICROSITES)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(micrositesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(micrositesFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* micrositesDelete({payload: {params, meta}}) {
    const options = {
        data: {
            id: params.primaryKey.id,
        }
    }
    let componentResponse = {}

    try {
        const response = yield call(apiDel, API_URL.AJAX_URL_MICROSITES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(micrositesSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_MICROSITES)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(micrositesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(micrositesFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* ProductsServicesSaga() {
    yield takeEvery(MICROSITES, microsites)
    yield takeEvery(MICROSITES_UPDATE, micrositesUpdate)
    yield takeEvery(MICROSITES_ADD, micrositesAdd)
    yield takeEvery(MICROSITES_DELETE, micrositesDelete)
}

export default ProductsServicesSaga