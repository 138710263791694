import {takeEvery, put, call} from 'redux-saga/effects'
import * as API_URL from '../actionTypes'
import {apiGet} from '../../helpers/apiHelpers'
import {DEFAULTS} from './actionTypes'
import {getPropTraverse} from '../../helpers/utils'
import {defaultsDataAction} from './actions'

function* defaults({payload: {params, history}}) {
    try {
        const responseData = yield call(apiGet, API_URL.AJAX_URL_DEFAULTS)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(defaultsDataAction(data))
    } catch (error) {

    }
}

function* DefaultsSaga() {
    yield takeEvery(DEFAULTS, defaults)
}

export default DefaultsSaga