import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Col, Row} from 'reactstrap'
import {NumericFormat} from 'react-number-format'
import {decimalPlace, getPropTraverse} from 'app.cms.react/helpers/utils'
import parse from 'html-react-parser'


class InvoiceItemsByPackage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                {this.props.dataView.package_items.map((val, idx) => (
                    <Row key={idx}>
                        <Col>
                            <div className="p-3 bg-dark bg-opacity-10">
                                <p><strong>{val.package_details.package_name}</strong></p>
                                {(val.package_details.description != '' && val.package_details.description != null) ? (
                                    <React.Fragment>{parse(val.package_details.description)}</React.Fragment>
                                ) : null}
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-items">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="bg-dark bg-opacity-10">Item #</th>
                                            <th className="bg-dark bg-opacity-10">Product / Service</th>
                                            <th className="bg-dark bg-opacity-10 text-end">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {val.invoice_items.map((item, idx) => (
                                            <tr id={"invoice-item-" + idx} key={idx}>
                                                <td>{idx + 1}</td>
                                                <td>
                                                    <div>{item.product_service_name}</div>
                                                    {(item.product_service_desc) ? (
                                                        <React.Fragment><small>{item.product_service_desc}</small></React.Fragment>
                                                    ) : ''}
                                                </td>
                                                <td className="text-end">{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr className="text-end">
                                            <td colSpan="5"><strong>Total ({this.props.dataView.invoice.currency_symbol}): <NumericFormat
                                                value={decimalPlace(this.props.dataView.invoice.package_amount)}
                                                displayType="text"
                                                thousandSeparator={true}
                                                decimalSeparator="."
                                            /></strong></td>
                                        </tr>
                                    </tfoot>
                                </table>

                                {(Object.entries(val.add_ons).length > 0) ? (
                                    <React.Fragment>
                                        <div className="p-3 bg-dark bg-opacity-10"><strong>Add Ons</strong></div>
                                        <table className="table table-centered table-items">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="bg-dark bg-opacity-10">Item #</th>
                                                    <th className="bg-dark bg-opacity-10">Product / Service</th>
                                                    <th className="bg-dark bg-opacity-10 text-end">Quantity</th>
                                                    <th className="bg-dark bg-opacity-10 text-end">Price</th>
                                                    <th className="bg-dark bg-opacity-10 text-end">Sub Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {val.add_ons.map((item, idx) => (
                                                    <tr id={"addon-item-" + idx} key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>
                                                            <span>{item.product_service_name}</span>
                                                            {(item.product_service_desc) ? (
                                                                <React.Fragment><br /><small>{item.product_service_desc}</small></React.Fragment>
                                                            ) : ''}
                                                        </td>
                                                        <td className="text-end">{item.quantity}</td>
                                                        <td className="text-end">
                                                            <NumericFormat
                                                                value={decimalPlace(item.price)}
                                                                displayType="text"
                                                                thousandSeparator={true}
                                                                decimalSeparator="."
                                                            />
                                                        </td>
                                                        <td className="text-end">
                                                            <NumericFormat
                                                                value={decimalPlace(item.sub_total_price)}
                                                                displayType="text"
                                                                thousandSeparator={true}
                                                                decimalSeparator="."
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot>
                                                <tr className="text-end">
                                                    <td colSpan="5"><strong>Total ({this.props.dataView.invoice.currency_symbol}): <NumericFormat
                                                        value={decimalPlace(this.props.dataView.invoice.add_ons_amount)}
                                                        displayType="text"
                                                        thousandSeparator={true}
                                                        decimalSeparator="."
                                                    /></strong></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </React.Fragment>
                                ) : null}
                            </div>
                            <div className="p-3 mb-3 bg-dark bg-opacity-10 text-end font-size-14">
                                <strong>
                                    Amount Due ({this.props.dataView.invoice.currency_symbol}): <NumericFormat
                                        value={decimalPlace(this.props.dataView.invoice.total_amount)}
                                        displayType="text"
                                        thousandSeparator={true}
                                        decimalSeparator="."
                                    />
                                </strong>
                            </div>
                        </Col>
                    </Row>
                ))}
            </React.Fragment>
        )
    }
}

InvoiceItemsByPackage.propTypes = {
    t: PropTypes.any,
    dataView: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
})(InvoiceItemsByPackage))