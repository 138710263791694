import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DataTableLoader from 'app.cms.react/components/Common/DataTable/DataTableLoader'
import {Card, CardBody, Col, Row, List, ListInlineItem} from 'reactstrap'
import DataTable from 'app.cms.react/components/Common/DataTable/DataTable'
import {
    componentModalToggleAction,
    componentFormInitAction,
    productsServicesAction,
    productsServicesDeleteAction,
    productsServicesInitAction
} from 'store/actions'
import ModalAddProductsServices from './ModalAddProductsServices'
import ModalEditProductsServices from './ModalEditProductsServices'
import ModalDelete from 'app.cms.react/components/Common/Modals/ModalDelete'
import {numberFormatComma} from 'app.cms.react/helpers/utils'
import {withRouter} from 'react-router-dom'

const formatName = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            {cell}
            <div className="text-muted fst-italic">{row.description}</div>
        </React.Fragment>
    )
}

const formatPrice = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <span className="float-end">{row.currency_symbol} {numberFormatComma(cell)}</span>
        </React.Fragment>
    )
}

const formatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div className="float-end">
                <List type="inline" className="p-0 m-0">
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleUpdate(row, e)
                    }}><i className="bx bx-pencil text-primary"/></ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleDelete(row, e)
                    }}><i className="bx bx-trash text-danger"/></ListInlineItem>
                </List>
            </div>
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerClasses: 'col-md-3',
        formatter: formatName,
    },
    {
        dataField: 'price',
        text: 'Price',
        sort: true,
        headerClasses: 'text-end col-md-3',
        formatter: formatPrice,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        headerClasses: 'text-end col-md-3',
        formatter: formatActions,
        formatExtraData: {
            toggleUpdate: props.toggleUpdate,
            toggleDelete: props.toggleDelete,
        }
    },
]

class TableProductsServices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                id: null,
                product_service_name: '',
                product_service_desc: '',
                price: 0.0,
            },
            page: 1,
            limit: 10,
            toggleAddModalProps: {
                target: 'addProductsServices',
                isOpen: true,
            },
            toggleEditModalProps: {
                target: 'edit',
                isOpen: true
            },
            toggleDeleteModalProps: {
                target: 'delete',
                isOpen: true
            }
        }

        this.props.productsServicesAction()
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
                product_service_name: (typeof row != 'undefined') ? row.name : '',
                product_service_desc: (typeof row != 'undefined' && row.description != null) ? row.description : '',
                price: (typeof row != 'undefined') ? row.price : '',
            }
        })
    }

    toggleUpdate(row) {
        this.setStateSelected(row)
        this.props.componentFormInitAction()
        this.props.componentModalToggleAction(this.state.toggleEditModalProps)
    }

    toggleDelete(row) {
        this.setStateSelected(row)
        this.props.componentModalToggleAction(this.state.toggleDeleteModalProps)
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader
                    data={this.props.data}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddModal={this.state.toggleAddModalProps}
                    dataTable={
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                columns={columns({
                                                    toggleUpdate: this.toggleUpdate,
                                                    toggleDelete: this.toggleDelete,
                                                })}
                                                data={this.props.data}
                                                isDataTableLoading={this.props.isDataTableLoading}
                                                toggleAddModal={this.state.toggleAddModalProps}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <ModalEditProductsServices
                                id={this.state.selected.id}
                                product_service_name={this.state.selected.product_service_name}
                                product_service_desc={this.state.selected.product_service_desc}
                                price={this.state.selected.price}
                            />
                        </React.Fragment>
                    }
                />
                <ModalDelete
                    primaryKey={
                        {
                            id: this.state.selected.id
                        }
                    }
                    deleteAction={this.props.productsServicesDeleteAction}
                    alertType={this.props.alertType}
                    response={this.props.response}
                    resetAlertAction={this.props.productsServicesInitAction}
                />
                <ModalAddProductsServices/>
            </React.Fragment>
        )
    }
}

TableProductsServices.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        data,
        alertType,
        response,
    } = state.ProductsServices

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        isDataTableLoading,
        data,
        alertType,
        response,
    }
}

export default connect(mapStateToProps, {
    productsServicesAction,
    productsServicesDeleteAction,
    productsServicesInitAction,
    componentModalToggleAction,
    componentFormInitAction,
})(withRouter(withTranslation()(TableProductsServices)))