import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DataTableLoader from 'app.cms.react/components/Common/DataTable/DataTableLoader'
import {Card, CardBody, Col, Label, List, ListInlineItem, Row} from 'reactstrap'
import DataTable from 'app.cms.react/components/Common/DataTable/DataTable'
import {
    bookingEventsAction,
    bookingsDeleteAction,
    bookingsExportAction,
    bookingsInitAction,
    componentModalToggleAction
} from 'store/actions'
import {
    dateDiff,
    dateFormat,
    decimalPlace,
    getPropTraverse,
    pluckRouteBasePathFromName,
    pluckRouteFromName
} from 'app.cms.react/helpers/utils'
import {Link, withRouter} from 'react-router-dom'
import {NumericFormat} from 'react-number-format'
import ModalDelete from 'app.cms.react/components/Common/Modals/ModalDelete'

const formatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div className="float-end">
                <List type="inline" className="p-0 m-0">
                    <ListInlineItem><Link to={pluckRouteFromName('view-invoice', {id: row.booking.booking_invoices.invoice_id})}><i className="bx bx-receipt"/></Link></ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleExport(row, e)
                    }}><i className="bx bx bxs-file-pdf" /></ListInlineItem>
                    <ListInlineItem>
                        <Link
                            to={pluckRouteFromName('view-invoice-payments', {id: row.id})}
                            className="text-primary"
                        >
                            <i className="bx bxs-bank" />
                        </Link>
                    </ListInlineItem>
                    <ListInlineItem><Link to={pluckRouteFromName('update-booking', {id: row.booking_id})}><i className="bx bx-pencil text-primary"/></Link></ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleDelete(row, e)
                    }}><i className="bx bx-trash text-danger"/></ListInlineItem>
                </List>
            </div>
        </React.Fragment>
    )
}

const formatContact = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div>{cell.full_name}</div>
            <div><small>{row.booking.booking_primary_contact.email}</small></div>
        </React.Fragment>
    )
}

const formatTotalAmount = (cell, row, rowIndex, formatExtraData) => {
    if (getPropTraverse(row, ['booking', 'booking_invoices']) != null) {
        return (
            <React.Fragment>
                {row.booking.booking_invoices.invoice_summary.currency_symbol} <NumericFormat
                    value={decimalPlace(row.booking.booking_invoices.invoice_summary.total_amount)}
                    displayType='text'
                    thousandSeparator={true}
                    decimalSeparator="."
                />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment></React.Fragment>
    )
}

const formatInvoice = (cell, row, rowIndex, formatExtraData) => {
    const paymentDueDate = dateFormat(row.booking.booking_invoices.invoice_summary.payment_due_date)
    const daysOverdue = row.booking.booking_invoices.invoice_summary.payment_diff_remaining
    const isOverdue = (daysOverdue < 0) ? true : false
    const badgeColor = (isOverdue) ? 'danger' : 'primary'

    return (
        <React.Fragment>
            <div>{paymentDueDate} <span className={'badge badge-soft-' + badgeColor}>{row.booking.booking_invoices.invoice_summary.payment_diff_remaining_human}</span></div>
            <div>{row.booking.booking_invoices.invoice_summary.invoice_code}</div>
        </React.Fragment>
    )
}

const formatEventDateTime = (cell, row, rowIndex, formatExtraData) => {
    const eventDateStart = dateFormat(row.event_datetime_start)
    const eventDateStartHuman = row.event_datetime_start_human
    const isFutureEvent = (row.event_datetime_start_human.includes('from now')) ? true : false
    const wordSeriesArrayWarning = ['weeks', 'week', 'days']
    const wordSeriesArrayDanger = ['day', 'hours', 'hour', 'minutes', 'minute', 'seconds', 'second']
    let badge = 'primary'
    let badgeChange = false

    if (isFutureEvent) {
        for (let i = 0; i < wordSeriesArrayWarning.length; i++) {
            if (eventDateStartHuman.includes(wordSeriesArrayWarning[i])) {
                badge = 'warning'
                badgeChange = true
                break;
            }
        }

        if (badgeChange == false) {
            for (let i = 0; i < wordSeriesArrayDanger.length; i++) {
                if (eventDateStartHuman.includes(wordSeriesArrayDanger[i])) {
                    badge = 'danger'
                    break;
                }
            }
        }
    } else {
        badge = 'success'
    }

    return (
        <React.Fragment>
            <div>{eventDateStart}</div>
            <div className={'badge badge-soft-' + badge}>{row.event_datetime_start_human}</div>
        </React.Fragment>
    )
}

const formatCreatedAt = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            {cell}
        </React.Fragment>
    )
}

const formatHasMultipleDates = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            {(cell > 1) ? (<i className="bx bx-check font-size-18"></i>) : null}
        </React.Fragment>
    )
}


const columns = (props) => [
    {
        dataField: 'id',
        text: 'Booking #',
        sort: true,
        hidden: true,
    },
    {
        dataField: 'event_datetime_start',
        text: 'Earliest Event Date',
        sort: true,
        formatter: formatEventDateTime
    },
    {
        dataField: 'booking.created_date',
        text: 'Created At',
        sort: true,
        formatter: formatCreatedAt
    },
    {
        dataField: 'event_date_count',
        text: 'Has Multiple Dates',
        sort: true,
        formatter: formatHasMultipleDates,
    },
    {
        dataField: 'booking.booking_type.booking_type_name',
        text: 'Booking Type',
        sort: true,
    },
    {
        dataField: 'booking.booking_primary_contact.booking_contact_details',
        text: 'Contact Person',
        sort: true,
        formatter: formatContact
    },
    {
        dataField: 'booking.booking_primary_contact.primary_contact_details.contact_number',
        text: 'Contact #',
        sort: true,
    },
    {
        dataField: 'booking.booking_invoices.invoice_summary.total_amount',
        text: 'Total Amount',
        sort: true,
        formatter: formatTotalAmount,
    },
    {
        dataField: 'booking.booking_invoices.invoice_summary.payment_due_date',
        text: 'Invoice',
        sort: true,
        formatter: formatInvoice,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        headerClasses: 'text-end',
        formatter: formatActions,
        formatExtraData: {
            toggleDelete: props.toggleDelete,
            toggleExport: props.toggleExport,
        }
    },
]

class TableBookings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                id: null,
            },
            page: 1,
            limit: 10,
            toggleAddLinkProps: {
                linkTo: pluckRouteFromName('create-booking'),
                linkText: 'Add New',
            },
            toggleEditModalProps: {
                target: 'edit',
                isOpen: true
            },
            toggleDeleteModalProps: {
                target: 'delete',
                isOpen: true
            }
        }

        this.props.bookingEventsAction()
        this.toggleDelete = this.toggleDelete.bind(this)
        this.toggleExport = this.toggleExport.bind(this)
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.booking_id : '',
            }
        })
    }

    toggleExport(row) {
        this.props.bookingsExportAction({
            booking_id: row.booking_id
        })
    }

    toggleDelete(row) {
        this.setStateSelected(row)
        this.props.componentModalToggleAction(this.state.toggleDeleteModalProps)
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader
                    data={this.props.data}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddLink={this.state.toggleAddLinkProps}
                    dataTable={
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                columns={columns({
                                                    toggleDelete: this.toggleDelete,
                                                    toggleExport: this.toggleExport,
                                                })}
                                                data={this.props.data}
                                                isDataTableLoading={this.props.isDataTableLoading}
                                                toggleAddLink={this.state.toggleAddLinkProps}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                />
                <ModalDelete
                    primaryKey={
                        {
                            id: this.state.selected.id
                        }
                    }
                    deleteAction={this.props.bookingsDeleteAction}
                    alertType={this.props.alertType}
                    response={this.props.response}
                    resetAlertAction={this.props.bookingsInitAction}
                />
            </React.Fragment>
        )
    }
}

TableBookings.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    const {
        data,
        alertType,
        response,
    } = state.Bookings

    return {
        isDataTableLoading,
        data,
        alertType,
        response,
    }
}

export default connect(mapStateToProps, {
    bookingsInitAction,
    bookingEventsAction,
    bookingsDeleteAction,
    bookingsExportAction,
    componentModalToggleAction,
})(withRouter(withTranslation()(TableBookings)))