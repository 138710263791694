export const API_URL = process.env.REACT_APP_API_URL

export const AJAX_URL_BUSINESS_INFO = API_URL + '/business-info'
export const AJAX_URL_CODE_TABLE = API_URL + '/code-table'
export const AJAX_URL_CONTACTS = API_URL + '/contacts'
export const AJAX_URL_DEFAULTS = API_URL + '/defaults'
export const AJAX_URL_FORGOT_PASSWORD = API_URL + '/forgot-password'
export const AJAX_URL_INVOICES = API_URL + '/invoices'
export const AJAX_URL_INVOICES_EXPORT = API_URL + '/invoices/export'
export const AJAX_URL_INVOICES_GENERATE = API_URL + '/invoices/generate'
export const AJAX_URL_INVOICES_VIEW = API_URL + '/invoices/view'
export const AJAX_URL_LOGIN = API_URL + '/login'
export const AJAX_URL_LOGOUT = API_URL + '/logout'
export const AJAX_URL_MICROSITES = API_URL + '/microsites'
export const AJAX_URL_PAYMENTS = API_URL + '/payments'
export const AJAX_URL_PACKAGE_BUILDER = API_URL + '/products-services-packages'
export const AJAX_URL_PACKAGE_BUILDER_DETAIL_VIEW = API_URL + '/products-services-packages/detail-view'
export const AJAX_URL_PACKAGE_BUILDER_DETAILS = API_URL + '/products-services-packages/details'
export const AJAX_URL_PRODUCTS_SERVICES = API_URL + '/products-services'
export const AJAX_URL_RESET_PASSWORD = API_URL + '/reset-password'
export const AJAX_URL_SIGNUP = API_URL + '/register'
export const AJAX_URL_INVOICES_PAYMENTS = API_URL + '/invoices/payments'
export const AJAX_URL_INVOICES_PAYMENTS_EXPORT = API_URL + '/invoices/payments/export'