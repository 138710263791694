import {
    GLOBAL_SETTER,
    GLOBAL_GETTER
} from './actionTypes'

const INIT_STATE = {
    token: '',
}

const Globals = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case GLOBAL_SETTER:
            return {
                ...state,
                token: action.payload.token,
            }
        case GLOBAL_GETTER:
            return {...state}
    }

    return {...state}
}

export default Globals