import {
    MICROSITES_INIT,
    MICROSITES,
    MICROSITES_DATA,
    MICROSITES_UPDATE,
    MICROSITES_ADD,
    MICROSITES_DELETE,
    MICROSITES_SUCCESS,
    MICROSITES_FAIL,
    MICROSITES_FORM,
} from './actionTypes'

export const micrositesInitAction = (params) => ({
    type: MICROSITES_INIT,
    payload: params,
})

export const micrositesAction = (params) => ({
    type: MICROSITES,
    payload: {params},
})

export const micrositesDataAction = (data) => ({
    type: MICROSITES_DATA,
    payload: {data},
})

export const micrositesUpdateAction = (params, meta) => ({
    type: MICROSITES_UPDATE,
    payload: {params, meta},
})

export const micrositesAddAction = (params, meta) => ({
    type: MICROSITES_ADD,
    payload: {params, meta},
})

export const micrositesDeleteAction = (params, meta) => ({
    type: MICROSITES_DELETE,
    payload: {params, meta},
})

export const micrositesSuccessAction = (params) => ({
    type: MICROSITES_SUCCESS,
    payload: params,
})

export const micrositesFailAction = (params) => ({
    type: MICROSITES_FAIL,
    payload: params,
})

export const micrositesFormAction = (params) => ({
    type: MICROSITES_FORM,
    payload: {params},
})