import {takeEvery, put, call} from 'redux-saga/effects'
import {
    getPropTraverse,
    buildUrlParams, getErrorResponse, formatFormData, formArgsExcludeEmpty,
} from 'app.cms.react/helpers/utils'
import {CONTACTS, CONTACTS_UPDATE, CONTACTS_ADD, CONTACTS_DELETE} from './actionTypes'
import {AJAX_URL_CONTACTS} from '../actionTypes'
import {
    contactsDataAction,
    componentFormOnSubmitDoneAction,
    componentDataTableLoadedAction,
    // exceptionHandlerAction,
    componentDataTableLoadingAction,
    componentFormOnSubmitAction,
    loginUserSuccessAction,
    loginUserFailAction,
    contactsFailAction, contactsSuccessAction
} from '../actions'
import {apiGet, apiPost, apiPut, apiDel} from 'app.cms.react/helpers/apiHelpers'
import * as API_URL from '../actionTypes'

function* contacts({payload: {params, history}}) {
    const options = formArgsExcludeEmpty({
        token: params.token
    })

    try {
        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_CONTACTS + '/' + params.type, {params: options})
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(contactsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* contactsUpdate({payload: {params, meta}}) {
    const options = {
        id: params.id,
        first_name: params.first_name,
        last_name: params.last_name,
        email: params.email,
        contact_address_id: params.contact_address_id,
        address_line_1: params.address_line_1,
        address_line_2: params.address_line_2,
        country: params.country.value,
        contact_contact_id: params.contact_contact_id,
        contact_number: params.contact_number,
    }
    let componentResponse = {}

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_CONTACTS + '/' + params.type, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(contactsSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_CONTACTS + '/' + params.type)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(contactsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(contactsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* contactsAdd({payload: {params, meta}}) {
    const options = formArgsExcludeEmpty({
        email: params.email,
        first_name: params.first_name,
        last_name: params.last_name,
        address_line_1: params.address_line_1,
        address_line_2: params.address_line_2,
        country: getPropTraverse(params, ['country', 'value']),
        contact_number: params.contact_number,
    })
    let componentResponse = {}

    try {
        const response = yield call(apiPost, API_URL.AJAX_URL_CONTACTS + '/' + params.type, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(contactsSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_CONTACTS + '/' + params.type)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(contactsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(contactsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* contactsDelete({payload: {params, meta}}) {
    const options = {
        data: {
            id: params.primaryKey.id,
        }
    }
    let componentResponse = {}

    try {
        const response = yield call(apiDel, API_URL.AJAX_URL_CONTACTS + '/' + params.type, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(contactsSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_CONTACTS + '/' + params.type)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(contactsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(contactsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* ProductsServicesSaga() {
    yield takeEvery(CONTACTS, contacts)
    yield takeEvery(CONTACTS_UPDATE, contactsUpdate)
    yield takeEvery(CONTACTS_ADD, contactsAdd)
    yield takeEvery(CONTACTS_DELETE, contactsDelete)
}

export default ProductsServicesSaga