import {
    DEFAULTS,
    DEFAULTS_DATA
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const DEFAULTS_STATE = {
    data: []
}

const Defaults = (state = DEFAULTS_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case DEFAULTS:
            return {...state}
        case DEFAULTS_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
    }

    return {...state}
}

export default Defaults