import {
    PAYMENTS_ADD,
    PAYMENTS_DATA,
    PAYMENTS_DELETE,
    PAYMENTS_FAIL,
    PAYMENTS_FORM_DATA,
    PAYMENTS_FORM,
    PAYMENTS_INIT,
    PAYMENTS_SUCCESS,
    PAYMENTS_UPDATE,
    PAYMENTS,
    PAYMENTS_SET_SELECTED_INVOICE,
} from './actionTypes'

export const paymentsAddAction = (params) => ({
    type: PAYMENTS_ADD,
    payload: {params},
})

export const paymentsUpdateAction = (params) => ({
    type: PAYMENTS_UPDATE,
    payload: {params},
})

export const paymentsDeleteAction = (params) => ({
    type: PAYMENTS_DELETE,
    payload: {params},
})

export const paymentsSuccessAction = (params) => ({
    type: PAYMENTS_SUCCESS,
    payload: params,
})

export const paymentsFailAction = (params) => ({
    type: PAYMENTS_FAIL,
    payload: params,
})

export const paymentsDataAction = (data) => ({
    type: PAYMENTS_DATA,
    payload: {data},
})

export const paymentsSetSelectedInvoiceAction = (data) => ({
    type: PAYMENTS_SET_SELECTED_INVOICE,
    payload: data
})

export const paymentsInitAction = (params) => ({
    type: PAYMENTS_INIT,
    payload: params,
})