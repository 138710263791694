import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link, withRouter} from 'react-router-dom'
import {Col, Container, Row} from 'reactstrap'
import logodark from '../../assets/images/logo-dark.png'
import logolight from '../../assets/images/logo-light.png'
import CarouselPage from 'components/Common/CarouselPage'
import {Helmet} from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {pluckRouteFromName} from 'app.cms.react/helpers/utils'
import FormResetPassword from 'app.cms.react/components/ResetPassword/FormResetPassword'

class ResetPassword extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Reset Password')}</title>
                </Helmet>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <CarouselPage/>

                            <Col xl={3}>
                                <div className="auth-full-page-content p-md-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="mb-4 mb-md-5">
                                                <Link to="dashboard" className="d-block auth-logo">
                                                    <img
                                                        src={logodark}
                                                        alt=""
                                                        height="18"
                                                        className="auth-logo-dark"
                                                    />
                                                    <img
                                                        src={logolight}
                                                        alt=""
                                                        height="18"
                                                        className="auth-logo-light"
                                                    />
                                                </Link>
                                            </div>

                                            <div className="my-auto">
                                                <div>
                                                    <h5 className="text-primary">{this.props.t('Reset Password')}</h5>
                                                </div>

                                                <FormResetPassword />

                                                <div className="mt-3 text-center">
                                                    <p>
                                                        <Link
                                                            to={pluckRouteFromName('login')}
                                                            className="fw-medium text-primary"
                                                        >
                                                            {this.props.t('Return to Log In')}
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <p className="mb-0">
                                                    © {new Date().getFullYear()}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

ResetPassword.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {})(withRouter(withTranslation()(ResetPassword)))