module.exports = {
    google: {
        API_KEY: '',
        CLIENT_ID: '',
        SECRET: '',
    },
    facebook: {
        APP_ID: '',
    },
    gtm: {
        ID: process.env.REACT_APP_GTM_ID
    },
    geoLocationUrl: 'https://geolocation-db.com/json/'
}
