import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Container} from 'reactstrap'

class Dashboard extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Dashboard')}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <h4>Dashboard</h4>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Dashboard.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(Dashboard))
