import React from 'react'
import PropTypes from 'prop-types'
import {Route, Redirect} from 'react-router-dom'
import {isUserAuthenticated, pluckRouteFromName} from 'app.cms.react/helpers/utils'

const AppRoute = ({
    component: Component,
    layout: Layout,
    isAuthProtected,
    isRedirectToProtected,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => {
            if (isRedirectToProtected && isUserAuthenticated()) {
                return (
                    <Redirect
                        to={{pathname: pluckRouteFromName('dashboard'), state: {from: props.location}}}
                    />
                )
            }

            if (isAuthProtected && !isUserAuthenticated()) {
                return (
                    <Redirect
                        to={{pathname: pluckRouteFromName('login'), state: {from: props.location}}}
                    />
                )
            }

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            )
        }}
    />
)

AppRoute.propTypes = {
    isAuthProtected: PropTypes.bool,
    isRedirectToProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any
}

export default AppRoute
