import {
    PRODUCTS_SERVICES_INIT,
    PRODUCTS_SERVICES,
    PRODUCTS_SERVICES_DATA,
    PRODUCTS_SERVICES_UPDATE,
    PRODUCTS_SERVICES_ADD,
    PRODUCTS_SERVICES_DELETE,
    PRODUCTS_SERVICES_SUCCESS,
    PRODUCTS_SERVICES_FAIL,
    PRODUCTS_SERVICES_FORM_ITEMS,
    PRODUCTS_SERVICES_FORM_ITEMS_SELECTED,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    dataView: [],
    response: '',
    alertType: '',
}

const productsServices = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case PRODUCTS_SERVICES_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
            }
        case PRODUCTS_SERVICES:
        case PRODUCTS_SERVICES_UPDATE:
        case PRODUCTS_SERVICES_ADD:
        case PRODUCTS_SERVICES_DELETE:
            return {...state}
        case PRODUCTS_SERVICES_SUCCESS:
        case PRODUCTS_SERVICES_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case PRODUCTS_SERVICES_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
                dataView: getPropTraverse(payload, ['data']),
            }
        case PRODUCTS_SERVICES_FORM_ITEMS:
            return {
                ...state,
                dataView: payload.params,
            }
        case PRODUCTS_SERVICES_FORM_ITEMS_SELECTED:
            return {
                ...state,
            }
    }

    return {...state}
}

export default productsServices