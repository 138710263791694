import {
    RESET_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS
} from './actionTypes'

const INIT_STATE = {
    response: null,
    alertType: '',
}

const ResetPassword = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case RESET_PASSWORD_SUCCESS:
        case RESET_PASSWORD_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
    }

    return {...state}
}

export default ResetPassword