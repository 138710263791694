import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import FormUpdatePackageBuilder from 'app.cms.react/components/PackageBuilder/FormUpdatePackageBuilder'
import {Helmet} from 'react-helmet'
import ModalLoader from 'app.cms.react/components/Invoices/ModalLoader'
import {codeTableOptionsAction} from 'app.cms.react/store/actions'

class Create extends Component {
    constructor(props) {
        super(props)

        const {
            match: { params },
        } = this.props

        this.state = {
            package_id: params.id,
            category_type: 'booking_types'
        }

        this.props.codeTableOptionsAction({
            table: 'booking_types',
            type: 'ddlb'
        })
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Build Package')}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Package Builder')} breadcrumbItem={this.props.t('Build Package')}/>
                        <FormUpdatePackageBuilder ddlbCategories={this.props.ddlbBookingTypes} categoryType={this.state.category_type} packageId={this.state.package_id} />
                        <ModalLoader />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Create.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const ddlbBookingTypes = {
        ...state.CodeTable.data
    }

    return {
        ddlbBookingTypes
    }
}

export default withTranslation()(connect(mapStateToProps, {
    codeTableOptionsAction
})(Create))