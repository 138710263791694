import {
    PRODUCTS_SERVICES_INIT,
    PRODUCTS_SERVICES,
    PRODUCTS_SERVICES_FORM_ITEMS,
    PRODUCTS_SERVICES_FORM_ITEMS_SELECTED,
    PRODUCTS_SERVICES_DATA,
    PRODUCTS_SERVICES_UPDATE,
    PRODUCTS_SERVICES_ADD,
    PRODUCTS_SERVICES_DELETE,
    PRODUCTS_SERVICES_SUCCESS,
    PRODUCTS_SERVICES_FAIL,
} from './actionTypes'

export const productsServicesInitAction = (params) => ({
    type: PRODUCTS_SERVICES_INIT,
    payload: params,
})

export const productsServicesAction = (params) => ({
    type: PRODUCTS_SERVICES,
    payload: {params},
})

export const productsServicesFormItemsAction = (params) => ({
    type: PRODUCTS_SERVICES_FORM_ITEMS,
    payload: {params},
})

export const productsServicesFormItemsSelectedAction = (params) => ({
    type: PRODUCTS_SERVICES_FORM_ITEMS_SELECTED,
    payload: {params},
})

export const productsServicesDataAction = (data) => ({
    type: PRODUCTS_SERVICES_DATA,
    payload: {data},
})

export const productsServicesUpdateAction = (params, meta) => ({
    type: PRODUCTS_SERVICES_UPDATE,
    payload: {params, meta},
})

export const productsServicesAddAction = (params, meta) => ({
    type: PRODUCTS_SERVICES_ADD,
    payload: {params, meta},
})

export const productsServicesDeleteAction = (params, meta) => ({
    type: PRODUCTS_SERVICES_DELETE,
    payload: {params, meta},
})

export const productsServicesSuccessAction = (params) => ({
    type: PRODUCTS_SERVICES_SUCCESS,
    payload: params,
})

export const productsServicesFailAction = (params) => ({
    type: PRODUCTS_SERVICES_FAIL,
    payload: params,
})