import {
    SIGN_UP_USER,
    SIGN_UP_USER_SUCCESS,
    SIGN_UP_USER_FAIL
} from './actionTypes'

export const signUpUserAction = (params, history) => ({
    type: SIGN_UP_USER,
    payload: {params, history},
})

export const signUpUserSuccessAction = (params) => ({
    type: SIGN_UP_USER_SUCCESS,
    payload: params,
})

export const signUpUserFailAction = (params) => ({
    type: SIGN_UP_USER_FAIL,
    payload: params,
})
