import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DataTableLoader from 'app.cms.react/components/Common/DataTable/DataTableLoader'
import {Card, CardBody, Col, Row, List, ListInlineItem} from 'reactstrap'
import DataTable from 'app.cms.react/components/Common/DataTable/DataTable'
import {
    componentModalToggleAction,
    componentFormInitAction,
    contactsAction,
    contactsDeleteAction,
    contactsInitAction,
    codeTableOptionsAction,
    componentDdlbOnChangeAction,
    contactsFormAction,
} from 'store/actions'
import ModalAddContacts from './ModalAddContacts'
import ModalEditContacts from './ModalEditContacts'
import ModalDelete from 'app.cms.react/components/Common/Modals/ModalDelete'
import {withRouter} from 'react-router-dom'
import {ddlbParser, getPropTraverse} from '../../helpers/utils'

const formatPrimaryContact = (cell, row, rowIndex, formatExtraData) => {
    const contactNumber = getIsPrimary(cell)

    return (
        <React.Fragment>
            {(contactNumber.length > 0) ? contactNumber[0].contact_number : ''}
        </React.Fragment>
    )
}

const getIsPrimary = (cell) => {
    return cell.filter((value) => {
        return value.is_primary
    })
}

const formatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div className="float-end">
                <List type="inline" className="p-0 m-0">
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleUpdate(row, e)
                    }}><i className="bx bx-pencil text-primary"/></ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleDelete(row, e)
                    }}><i className="bx bx-trash text-danger"/></ListInlineItem>
                </List>
            </div>
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'full_name',
        text: 'Name',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'Email',
        sort: true,
    },
    {
        dataField: 'contact_details',
        text: 'Contact Number (Primary)',
        sort: true,
        formatter: formatPrimaryContact,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        headerClasses: 'text-end col-md-3',
        formatter: formatActions,
        formatExtraData: {
            toggleUpdate: props.toggleUpdate,
            toggleDelete: props.toggleDelete,
        }
    },
]

class TableContacts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                id: null,
                email: '',
                first_name: '',
                last_name: '',
                contact_address_id: '',
                address_line_1: '',
                address_line_2: '',
                country: {},
                contact_contact_id: '',
                contact_number: '',
            },
            page: 1,
            limit: 10,
            toggleAddModalProps: {
                target: 'addContacts',
                isOpen: true,
            },
            toggleEditModalProps: {
                target: 'edit',
                isOpen: true
            },
            toggleDeleteModalProps: {
                target: 'delete',
                isOpen: true
            }
        }

        this.props.contactsAction({type: 'customer'})
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
        this.props.codeTableOptionsAction({
            table: 'countries',
            type: 'ddlb'
        })
    }

    setStateSelected(row) {
        const contactAddressDetails = getPropTraverse(row, ['address_details'])
        const contactContactDetails = getPropTraverse(row, ['contact_details'])

        const primaryContacts = getIsPrimary(contactContactDetails)
        const contactAddress = (contactAddressDetails.length > 0) ? contactAddressDetails : {}

        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
                first_name: (typeof row != 'undefined') ? row.first_name : '',
                last_name: (typeof row != 'undefined') ? row.last_name : '',
                email: (typeof row != 'undefined') ? row.email : '',
                contact_address_id: (contactAddressDetails.length > 0 && contactAddressDetails[0].id != null) ? contactAddressDetails[0].id : '',
                address_line_1: (contactAddressDetails.length > 0 && contactAddressDetails[0].address_line_1 != null) ? contactAddressDetails[0].address_line_1 : '',
                address_line_2: (contactAddressDetails.length > 0 && contactAddressDetails[0].address_line_2 != null) ? contactAddressDetails[0].address_line_2 : '',
                contact_contact_id: (primaryContacts.length > 0) ? primaryContacts[0].id : '',
                contact_number: (primaryContacts.length > 0) ? primaryContacts[0].contact_number : '',
            }
        })

        this.props.contactsFormAction({country: ddlbParser(contactAddress, {
            label: 'country_name',
            value: 'country_code'
        })[0]})
    }

    toggleUpdate(row) {
        this.setStateSelected(row)
        this.props.componentFormInitAction()
        this.props.componentModalToggleAction(this.state.toggleEditModalProps)
    }

    toggleDelete(row) {
        this.setStateSelected(row)
        this.props.componentModalToggleAction(this.state.toggleDeleteModalProps)
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader
                    data={this.props.data}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddModal={this.state.toggleAddModalProps}
                    dataTable={
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                columns={columns({
                                                    toggleUpdate: this.toggleUpdate,
                                                    toggleDelete: this.toggleDelete,
                                                })}
                                                data={this.props.data}
                                                isDataTableLoading={this.props.isDataTableLoading}
                                                toggleAddModal={this.state.toggleAddModalProps}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <ModalEditContacts
                                id={this.state.selected.id}
                                first_name={this.state.selected.first_name}
                                last_name={this.state.selected.last_name}
                                email={this.state.selected.email}
                                contact_address_id={this.state.selected.contact_address_id}
                                address_line_1={this.state.selected.address_line_1}
                                address_line_2={this.state.selected.address_line_2}
                                country={this.props.country}
                                contact_contact_id={this.state.selected.contact_contact_id}
                                contact_number={this.state.selected.contact_number}
                                ddlbCountries={this.props.ddlbCountries.countries}
                                contactType={this.props.contactType}
                            />
                        </React.Fragment>
                    }
                />
                <ModalDelete
                    primaryKey={
                        {
                            id: this.state.selected.id
                        }
                    }
                    deleteAction={this.props.contactsDeleteAction}
                    alertType={this.props.alertType}
                    response={this.props.response}
                    resetAlertAction={this.props.contactsInitAction}
                    type={this.props.contactType}
                />
                <ModalAddContacts
                    contactType={this.props.contactType}
                    ddlbCountries={this.props.ddlbCountries.countries} />
            </React.Fragment>
        )
    }
}

TableContacts.propTypes = {
    t: PropTypes.any,
    ddlbCountries: PropTypes.any.isRequired,
    contactType: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    const {
        data,
        alertType,
        response,
        country,
    } = state.Contacts

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    const ddlbCountries = {
        ...state.CodeTable.data
    }

    return {
        isDataTableLoading,
        data,
        alertType,
        response,
        ddlbCountries,
        country,
    }
}

export default connect(mapStateToProps, {
    contactsAction,
    contactsDeleteAction,
    contactsInitAction,
    componentModalToggleAction,
    componentFormInitAction,
    codeTableOptionsAction,
    componentDdlbOnChangeAction,
    contactsFormAction
})(withRouter(withTranslation()(TableContacts)))