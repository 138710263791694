import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import {ErrorMessage, Field} from 'formik'

class CeremonyDetails extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <CardTitle>{this.props.t('Ceremony Details')}</CardTitle>
                <Row>
                    <Col>
                        <Flatpickr
                            id="ceremony_event_datetime_start"
                            name="ceremony_event_datetime_start"
                            className="form-control d-block bg-white"
                            placeholder="Start date and time"
                            options={{
                                altInput: true,
                                altFormat: "F d, Y - G:i K",
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i:S'
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                this.props.handleDdlbChange(dateStr, 0, 'ceremony_event_datetime_start')
                            }}
                            value={this.props.handleTextDefaultValue('ceremony_event_datetime_start')}
                        />
                    </Col>
                    <Col>
                        <Flatpickr
                            id="ceremony_event_datetime_end"
                            name="ceremony_event_datetime_end"
                            className="form-control d-block bg-white"
                            placeholder="End date and time"
                            options={{
                                altInput: true,
                                altFormat: "F d, Y - G:i K",
                                enableTime: true,
                                dateFormat: 'Y-m-d H:i:S'
                            }}
                            onChange={(selectedDates, dateStr, instance) => {
                                this.props.handleDdlbChange(dateStr, 0, 'ceremony_event_datetime_end')
                            }}
                            value={this.props.handleTextDefaultValue('ceremony_event_datetime_end')}
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="ceremony_full_address"
                            placeholder={this.props.t('Venue Address')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'ceremony_full_address')}
                            onChange={(e) => {
                                this.props.handleFieldChange('ceremony_full_address', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('ceremony_full_address')}
                        />
                        <ErrorMessage
                            name="ceremony_full_address"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="ceremony_map_url"
                            placeholder={this.props.t('Google Map URL')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'ceremony_map_url')}
                            onChange={(e) => {
                                this.props.handleFieldChange('ceremony_map_url', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('ceremony_map_url')}
                        />
                        <ErrorMessage
                            name="ceremony_map_url"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

CeremonyDetails.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    handleTextDefaultValue: PropTypes.any.isRequired,
    handleFieldChange: PropTypes.any.isRequired,
    handleDdlbChange: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const customers = {
        ...state.Contacts.data
    }

    return {
        customers
    }
}

export default withTranslation()(connect(mapStateToProps, {
})(CeremonyDetails))