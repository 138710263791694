import {combineReducers} from 'redux'

import Bookings from './bookings/reducer'
import Layout from './layout/reducer'

// app.cms.react
import {
    BusinessInfo,
    CodeTable,
    ComponentsDataTable,
    ComponentsDdlb,
    ComponentsForm,
    ComponentsModal,
    Contacts,
    Defaults,
    ForgotPassword,
    Globals,
    Invoices,
    Login,
    Logout,
    Microsites,
    PackageBuilder,
    Payments,
    ProductsServices,
    ResetPassword,
    SignUp,
} from 'app.cms.react/store/reducers'

const rootReducer = combineReducers({
    Bookings,
    Layout,

    // app.cms.react
    BusinessInfo,
    CodeTable,
    ComponentsDataTable,
    ComponentsDdlb,
    ComponentsForm,
    ComponentsModal,
    Contacts,
    Defaults,
    ForgotPassword,
    Globals,
    Invoices,
    Login,
    Logout,
    Microsites,
    PackageBuilder,
    Payments,
    ProductsServices,
    ResetPassword,
    SignUp,
})

export default rootReducer
