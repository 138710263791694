import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {componentModalToggleAction} from 'app.cms.react/store/components/modal/actions'
import {componentFormInitAction, componentFormOnSubmitAction, codeTableOptionsAction, paymentsAddAction, paymentsInitAction} from 'app.cms.react/store/actions'
import ButtonLoading from '../Common/Buttons/ButtonLoading'
import FormAlert from '../Common/Alerts/FormAlert'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import FormAddPayment from './FormAddPayment'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalAddPayment extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {},
        }

        this.props.codeTableOptionsAction({
            table: 'payment_methods',
            type: 'ddlb'
        })
        this.props.codeTableOptionsAction({
            table: 'payment_accounts',
            type: 'ddlb'
        })
        this.props.codeTableOptionsAction({
            table: 'payment_terms',
            type: 'ddlb'
        })

        this.toggleModal = this.toggleModal.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)

        this.handleDdlbChange = this.handleDdlbChange.bind(this)
        this.handleTextDefaultValue = this.handleTextDefaultValue.bind(this)
        this.handleSetState = this.handleSetState.bind(this)
        this.handleOnSubmitActions = this.handleOnSubmitActions.bind(this)
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
        this.props.paymentsInitAction()

        this.setState({form: {}})
    }

    handleFormSubmit(values) {
        const formObj = {
            ...values,
            ...{invoice_id: this.props.invoiceId}
        }

        this.props.paymentsAddAction(formObj)
        setTimeout(this.handleOnSubmitActions, 1500)
        this.props.componentFormOnSubmitAction()
    }

    handleOnSubmitActions() {
        if (typeof this.props.onSubmitAction != 'undefined') {
            switch (this.props.onSubmitAction.type) {
                case 'invoicesPaymentsAction':
                    if (this.props.alertType == 'success') {
                        this.props.onSubmitAction.action({invoice_id: this.props.invoiceId}, this.props.onSubmitAction.params.reload)
                    }
                    break
            }
        }
    }

    handleValidationSchema() {
        return Yup.object().shape({})
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    handleDdlbChange = (value, type) => {
        this.handleSetState(value, type)
    }

    handleTextDefaultValue(e, type) {
        this.handleSetState(e.target.value, type)
    }

    handleSetState(value, type) {
        this.setState({
            form: {...this.state.form, ...{[type]: value}}
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'addPayment') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={this.state.form}
                        validationSchema={this.handleValidationSchema()}
                        onSubmit={(values) => this.handleFormSubmit(values)}
                    >
                        {({errors, status, touched}) => (
                            <Form className="form-horizontal">
                                <ModalHeader>{this.props.t('Record a payment')}</ModalHeader>
                                <ModalBody>
                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                    <FormAddPayment
                                        errors={errors}
                                        touched={touched}
                                        handleFieldValidation={this.handleFieldValidation}
                                        disableFields={this.props.disableFields}
                                        formLoading={this.props.formLoading}
                                        handleDdlbChange={this.handleDdlbChange}
                                        handleTextDefaultValue={this.handleTextDefaultValue}
                                    />
                                </ModalBody>
                                <ModalFooter>
                                    <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalAddPayment.propTypes = {
    t: PropTypes.any,
    onSubmitAction: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        response,
        alertType,
        invoiceId,
    } = state.Payments

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        invoiceId,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    codeTableOptionsAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
    componentModalToggleAction,
    paymentsAddAction,
    paymentsInitAction,
})(ModalAddPayment))