import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Button, Card, CardBody, Col, Label, Modal, Row} from 'reactstrap'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import Select from 'react-select'
import {
    productsServicesAction,
    contactsAction,
    invoicesAddAction,
    invoicesInitAction,
    codeTableOptionsAction,
    componentModalToggleAction,
    invoicesSubmitExportAction,
    componentFormOnSubmitAction,
    componentFormInitAction
} from 'store/actions'
import {ddlbParser, decimalPlace, numberFormatComma} from 'app.cms.react/helpers/utils'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import ModalAddContacts from '../Contacts/ModalAddContacts'
import ModalAddProductsServices from '../ProductsServices/ModalAddProductsServices'
import FormAlert from '../Common/Alerts/FormAlert'
import { NumericFormat } from 'react-number-format'

const formatOptionLabelProductsServices = ({ value, label, price }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>
                <div className="clearfix">
                    <div className="float-start">{label}</div>
                    <div className="float-end">
                        <NumericFormat value={decimalPlace(price)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                    </div>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const formatOptionLabelCustomers = ({ value, label, email }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>{label}<br /><small>{email}</small></React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const RenderCustomerDetail = (props) => {
    const data = props.customer
    return (
        <React.Fragment>
            <p>
                <strong>{data.full_name}</strong><br />
                {data.email}<br />
                {data.contact_number}<br />
                {data.address}
            </p>
        </React.Fragment>
    )
}

class FormInvoices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            payment_due_date: '',
            invoice_date: '',
            items: [{
                product_service: '',
                description: '',
                quantity: 1,
                price: '0.00',
                discount_price: '0.00',
                amount: '0.00',
            }],
            total_amount: '0.00',
            customer: {
                full_name: '',
                email: '',
                contact_number: '',
                address: '',
            },
            toggleAddContactModalProps: {
                target: 'addContacts',
                isOpen: true,
            },
            toggleAddProductsServicesModalProps: {
                target: 'addProductsServices',
                isOpen: true,
            },
        }

        this.props.invoicesInitAction()
        this.props.componentFormInitAction()
        this.props.productsServicesAction()
        this.props.contactsAction({type: 'customer'})
        this.props.codeTableOptionsAction({
            table: 'countries',
            type: 'ddlb'
        })

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleDdlbChange = this.handleDdlbChange.bind(this)
        this.handleSetProductServiceItem = this.handleSetProductServiceItem.bind(this)
        this.handleSetProductServiceItemDescription = this.handleSetProductServiceItemDescription.bind(this)

    }
    handleAddRow = () => {
        const values = this.state.items
        values.push({
            quantity: 1,
            price: '0.00',
            discount_price: '0.00',
            amount: '0.00',
        })

        this.setState({ items: values })
    }

    handleAddContact = () => {
        this.props.componentModalToggleAction(this.state.toggleAddContactModalProps)
    }

    handleAddProductService = () => {
        this.props.componentModalToggleAction(this.state.toggleAddProductsServicesModalProps)
    }

    handleRemoveRow = (e, idx) => {
        const stateList = this.state.items.filter((item, index) => {
            return idx !== index
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})
    }

    handleDdlbChange = (value, idx, type) => {
        switch (type) {
            case 'customer':
                this.handleSetCustomerDetailOnSelect(value)
                break
            case 'product_service':
                this.handleSetProductServiceItemOnSelect(value, idx)
                break
            case 'invoice_date':
            case 'payment_due_date':
                this.handleSetDateOnSelect(value, type)
                break
        }
    }

    handleSetProductServiceItemOnSelect(value, idx) {
        const stateList = Object.entries(this.state.items).map(([key, val]) => {
            if (key == idx) {
                return {
                    id: value.id,
                    name: value.name,
                    description: value.description,
                    quantity: val.quantity,
                    price: value.price,
                    discount_price: val.discount_price,
                    amount: val.quantity * value.price,
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})
    }

    handleSetProductServiceItem(e, idx, type) {
        this.computeSubTotal(e, idx, type)
    }

    handleSetProductServiceItemDescription(e, idx) {
        const stateList = Object.entries(this.state.items).map(([key, val]) => {
            if (key == idx) {
                return {
                    ...val,
                    description: e.target.value
                }
            } else {
                return val
            }
        })

        this.setState({items: stateList})
    }

    computeSubTotal(e, idx, type) {
        let discount = '0.00'

        const stateList = Object.entries(this.state.items).map(([key, val]) => {
            if (key == idx) {
                switch (type) {
                    case 'quantity':
                        discount = (val.discount_price < (e.target.value * val.price)) ? e.target.value * val.price - val.discount_price : discount
                        return {
                            ...val,
                            quantity: e.target.value,
                            amount: discount,
                        }
                    case 'price':
                        discount = (val.discount_price < (e.target.value * val.quantity)) ? e.target.value * val.quantity - val.discount_price : discount
                        return {
                            ...val,
                            price: e.target.value,
                            amount: discount,
                        }
                    case 'discount_price':
                        discount = (e.target.value < (val.price * val.quantity)) ? val.price * val.quantity - e.target.value : discount
                        return {
                            ...val,
                            discount_price: e.target.value,
                            amount: discount,
                        }
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})
    }

    computeTotal(stateList) {
        let total = 0.00

        Object.entries(stateList).map(([key, value]) => {
            total += value.amount
        })

        this.setState({total_amount: total})
    }

    handleSetCustomerDetailOnSelect(value) {
        const addressLine1 = (value.address_details[0].address_line_1 == null) ? '' : value.address_details[0].address_line_1 + ', '
        const addressLine2 = (value.address_details[0].address_line_2 == null) ? '' : value.address_details[0].address_line_2 + ', '

        this.setState({
            customer: {
                id: value.id,
                full_name: value.full_name,
                email: value.email,
                contact_number: value.contact_details[0].contact_number,
                address: addressLine1 + addressLine2 + value.address_details[0].country_name
            }
        })
    }

    handleSetDateOnSelect(value, type) {
        this.setState({
            [type]: value
        })
    }

    handleValidationSchema() {
        return Yup.object().shape({
            // product_service_desc: Yup.string().required(
            //     this.props.t('Product / Service name is required')
            // ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    handleFormSubmit(values) {
        this.props.invoicesAddAction({
            customer: JSON.stringify(this.state.customer),
            items: JSON.stringify(this.state.items),
            invoice_date: this.state.invoice_date,
            payment_due_date: this.state.payment_due_date,
            notes: values.notes,
            submit_export: this.props.submitExport
        })
        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <ModalAddContacts
                    contactType={'customer'}
                    ddlbCountries={this.props.ddlbCountries.countries}
                />
                <ModalAddProductsServices />
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state}
                    validationSchema={this.handleValidationSchema()}
                    onSubmit={(values) => this.handleFormSubmit(values)}
                >
                    {({errors, status, touched}) => (
                        <Form className="form-horizontal mb-4">
                            <Row>
                                <Col md={12}>
                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md={8}>
                                                    <Row className="mb-3">
                                                        <Label className={'col-md-2 col-form-label'} htmlFor="customer">{this.props.t('Customer')}</Label>
                                                        <Col md={8}>
                                                            <div className="input-group">
                                                                <div className="form-control border-0 p-0">
                                                                    <Select
                                                                        name="customer"
                                                                        options={ddlbParser(this.props.customers, {
                                                                            label: 'full_name',
                                                                            value: 'id',
                                                                        }, true)}
                                                                        onChange={(e) => (this.handleDdlbChange(e, 0, 'customer'))}
                                                                        formatOptionLabel={formatOptionLabelCustomers}
                                                                        classNamePrefix="select2-selection "
                                                                        isClearable={true}
                                                                        isDisabled={this.props.formLoading}
                                                                        required
                                                                    />
                                                                </div>
                                                                <Button onClick={this.handleAddContact} color="primary">+</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col md={{
                                                            offset: 2,
                                                            size: 10
                                                        }}>
                                                            <RenderCustomerDetail customer={this.state.customer} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={4}>
                                                    <Row className="mb-3">
                                                        <Label className={'col-md-4 col-form-label'} htmlFor="invoice_date">{this.props.t('Invoice Date')}</Label>
                                                        <Col md={8}>
                                                            <Flatpickr
                                                                id="invoice_date"
                                                                name="invoice_date"
                                                                className="form-control bg-white"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "F j, Y",
                                                                    dateFormat: "Y-m-d"
                                                                }}
                                                                onChange={(selectedDates, dateStr, instance) => {
                                                                    this.handleDdlbChange(dateStr, 0, 'invoice_date')
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Label className={'col-md-4 col-form-label'} htmlFor="invoice_date">{this.props.t('Payment Due Date')}</Label>
                                                        <Col md={8}>
                                                            <Flatpickr
                                                                id="payment_due_date"
                                                                name="payment_due_date"
                                                                className="form-control bg-white"
                                                                options={{
                                                                    altInput: true,
                                                                    altFormat: "F j, Y",
                                                                    dateFormat: "Y-m-d"
                                                                }}
                                                                onChange={(selectedDates, dateStr, instance) => {
                                                                    this.handleDdlbChange(dateStr, 0, 'payment_due_date')
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Label className={'col-md-2 col-form-label pt-0'}>{this.props.t('Invoice Items')}</Label>
                                            <table className="table vertical-align-middle table-striped pt-0">
                                                <thead>
                                                <tr>
                                                    <th>{this.props.t('Product / Service')}</th>
                                                    <th>&nbsp;</th>
                                                    <th className="text-end">{this.props.t('Quantity')}</th>
                                                    <th className="text-end">{this.props.t('Price')}</th>
                                                    <th className="text-end">{this.props.t('Discount')}</th>
                                                    <th className="text-end">{this.props.t('Amount')}</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.items.map((item1, idx) => (
                                                    <tr id={"nested" + idx} key={idx}>
                                                        <td>
                                                            <div className="input-group">
                                                                <div className="form-control border-0 p-0">
                                                                    <Select
                                                                        name="product_service"
                                                                        options={ddlbParser(this.props.productsServices, {
                                                                            label: 'name',
                                                                            value: 'id',
                                                                        }, true)}
                                                                        onChange={(e) => (this.handleDdlbChange(e, idx, 'product_service'))}
                                                                        classNamePrefix="select2-selection "
                                                                        isClearable={true}
                                                                        formatOptionLabel={formatOptionLabelProductsServices}
                                                                        isDisabled={this.props.formLoading}
                                                                        required
                                                                    />
                                                                </div>
                                                                <Button onClick={this.handleAddProductService} color="primary">+</Button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Field
                                                                name="product_service_desc"
                                                                type="text"
                                                                className={'form-control' + this.handleFieldValidation(errors, touched, 'product_service_desc')}
                                                                disabled={this.props.disableFields}
                                                                value={(this.state.items[idx].description != null) ? this.state.items[idx].description : ''}
                                                                onChange={(e) => (this.handleSetProductServiceItemDescription(e, idx))}
                                                            />
                                                            <ErrorMessage
                                                                name="product_service_desc"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </td>
                                                        <td>
                                                            <Col md={3} className="float-end">
                                                                <NumericFormat
                                                                    allowNegative={false}
                                                                    name="quantity"
                                                                    type="text"
                                                                    className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'quantity')}
                                                                    disabled={this.props.disableFields}
                                                                    value={this.state.items[idx].quantity}
                                                                    onChange={(e) => (this.handleSetProductServiceItem(e, idx, 'quantity'))}
                                                                />
                                                                <ErrorMessage
                                                                    name="quantity"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Col md={5} className="float-end">
                                                                <NumericFormat
                                                                    allowNegative={false}
                                                                    name="price"
                                                                    type="text"
                                                                    className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'price')}
                                                                    disabled={this.props.disableFields}
                                                                    value={this.state.items[idx].price}
                                                                    onChange={(e) => (this.handleSetProductServiceItem(e, idx, 'price'))}
                                                                />
                                                                <ErrorMessage
                                                                    name="price"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Col md={5} className="float-end">
                                                                <NumericFormat
                                                                    allowNegative={false}
                                                                    name="discount_price"
                                                                    type="text"
                                                                    className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'discount_price')}
                                                                    disabled={this.props.disableFields}
                                                                    value={this.state.items[idx].discount_price}
                                                                    onChange={(e) => (this.handleSetProductServiceItem(e, idx, 'discount_price'))}
                                                                />
                                                                <ErrorMessage
                                                                    name="discount_price"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Label className="form-control-plaintext text-end">
                                                                <NumericFormat value={decimalPlace(this.state.items[idx].amount)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                                                            </Label>
                                                        </td>
                                                        <td className="text-end">
                                                            {(idx > 0) ? (
                                                                <Button
                                                                    onClick={e =>
                                                                        this.handleRemoveRow(e, idx)
                                                                    }
                                                                    color="danger"
                                                                    className="btn-block inner"
                                                                    value={idx}
                                                                >
                                                                    <i className="bx bx-trash" />
                                                                </Button>
                                                            ) : null}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colSpan={5}>
                                                        <Button onClick={this.handleAddRow} color="primary">{this.props.t('Add Item')}</Button>
                                                    </td>
                                                    <td>
                                                        <Label className="form-control-plaintext text-end font-size-18">
                                                            <NumericFormat value={decimalPlace(this.state.total_amount)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                                                        </Label>
                                                    </td>
                                                    <td>
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Label className={'col-md-2 col-form-label pt-0'} htmlFor="notes">{this.props.t('Notes')}</Label>
                                            <Field
                                                name="notes"
                                                as="textarea"
                                                className={'form-control' + this.handleFieldValidation(errors, touched, 'notes')}
                                                disabled={this.props.disableFields}
                                                rows={5}
                                                placeholder="Enter notes or terms of service that are visible to the customer"
                                            />
                                            <ErrorMessage
                                                name="notes"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-end">
                                            <ButtonLoading
                                                formLoading={this.props.formLoading}
                                                buttonClass={'btn btn-primary w-md me-3'}
                                                onClick={() => {this.props.invoicesSubmitExportAction(false)}}
                                            />
                                            <ButtonLoading
                                                buttonLoadingText={<React.Fragment><i className="bx bxs-file-pdf"></i> {this.props.t('Generating')}</React.Fragment>}
                                                buttonName={<React.Fragment><i className="bx bxs-file-pdf"></i> {this.props.t('Submit and Generate PDF')}</React.Fragment>}
                                                formLoading={this.props.formLoading}
                                                buttonClass={'btn btn-success'}
                                                onClick={() => {this.props.invoicesSubmitExportAction(true)}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        )
    }
}

FormInvoices.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const productsServices = {
        ...state.ProductsServices.data
    }

    const customers = {
        ...state.Contacts.data
    }

    const ddlbCountries = {
        ...state.CodeTable.data
    }

    const {
        submitExport,
        response,
        alertType,
    } = state.Invoices

    return {
        productsServices,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        customers,
        ddlbCountries,
        submitExport,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    productsServicesAction,
    contactsAction,
    invoicesInitAction,
    invoicesAddAction,
    codeTableOptionsAction,
    componentModalToggleAction,
    invoicesSubmitExportAction,
    componentFormOnSubmitAction,
    componentFormInitAction
})(FormInvoices))