import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL
} from './actionTypes'

export const resetPasswordAction = (params, history) => ({
    type: RESET_PASSWORD,
    payload: {params, history},
})

export const resetPasswordSuccessAction = (params) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: params,
})

export const resetPasswordFailAction = (params) => ({
    type: RESET_PASSWORD_FAIL,
    payload: params,
})
