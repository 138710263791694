export const PACKAGE_BUILDER_INIT = 'PACKAGE_BUILDER_INIT'
export const PACKAGE_BUILDER = 'PACKAGE_BUILDER'
export const PACKAGE_BUILDER_DETAILS = 'PACKAGE_BUILDER_DETAILS'
export const PACKAGE_BUILDER_FORM = 'PACKAGE_BUILDER_FORM'
export const PACKAGE_BUILDER_FORM_ITEMS = 'PACKAGE_BUILDER_FORM_ITEMS'
export const PACKAGE_BUILDER_FORM_ADD_ROW = 'PACKAGE_BUILDER_FORM_ADD_ROW'
export const PACKAGE_BUILDER_FORM_DELETE_ROW = 'PACKAGE_BUILDER_FORM_DELETE_ROW'
export const PACKAGE_BUILDER_VIEW = 'PACKAGE_BUILDER_VIEW'
export const PACKAGE_BUILDER_VIEW_DATA = 'PACKAGE_BUILDER_VIEW_DATA'
export const PACKAGE_BUILDER_DATA = 'PACKAGE_BUILDER_DATA'
export const PACKAGE_BUILDER_ADD = 'PACKAGE_BUILDER_ADD'
export const PACKAGE_BUILDER_UPDATE = 'PACKAGE_BUILDER_UPDATE'
export const PACKAGE_BUILDER_DELETE = 'PACKAGE_BUILDER_DELETE'
export const PACKAGE_BUILDER_SUCCESS = 'PACKAGE_BUILDER_SUCCESS'
export const PACKAGE_BUILDER_FAIL = 'PACKAGE_BUILDER_FAIL'