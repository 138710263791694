import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom"
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {ErrorMessage, Field} from 'formik'
import {Col, Row} from 'reactstrap'
import Select from 'react-select'
import {contactsFormAction} from 'app.cms.react/store/actions'

class FormContacts extends Component {
    constructor(props) {
        super(props)
    }

    handleDdlbChange = (value) => {
        this.props.contactsFormAction({country: value})
    }

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Field
                            name="first_name"
                            placeholder={this.props.t('First name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'first_name')}
                        />
                        <ErrorMessage
                            name="first_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="last_name"
                            placeholder={this.props.t('Last name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'last_name')}
                        />
                        <ErrorMessage
                            name="last_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="email"
                            placeholder={this.props.t('Email')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'email')}
                        />
                        <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="address_line_1"
                            placeholder={this.props.t('Address line 1')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'address_line_1')}
                        />
                        <ErrorMessage
                            name="address_line_1"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="address_line_2"
                            placeholder={this.props.t('Address line 2')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'address_line_2')}
                        />
                        <ErrorMessage
                            name="address_line_2"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Select
                            name="country"
                            options={this.props.ddlbCountries}
                            onChange={this.handleDdlbChange}
                            classNamePrefix="select2-selection"
                            className={this.props.handleFieldValidation(this.props.errors, this.props.touched, 'country')}
                            isClearable={true}
                            placeholder={'Country'}
                            required
                            value={this.props.country}
                        />
                        <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="contact_number"
                            placeholder={this.props.t('Contact Number')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'contact_number')}
                        />
                        <ErrorMessage
                            name="contact_number"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

FormContacts.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    ddlbCountries: PropTypes.any.isRequired,
    country: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        defaults
    } = state.Defaults

    return {
        defaults
    }
}

export default connect(mapStateToProps, {
    contactsFormAction
})(withRouter(withTranslation()(FormContacts)))