import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import Navbar from './Navbar/Navbar'
import Section from './HeroSection/Section'
import CardsMini from './HeroSection/cards-mini'
import Footer from './Footer/footer'
import Wizard from 'pages/BookingFormLanding/BookingForm/Wizard'
import {globalSetterAction} from 'app.cms.react/store/actions'

class LandingForm extends Component {
    constructor(props) {
        super(props)

        const {
            match: { params },
        } = this.props

        const query = new URLSearchParams(this.props.location.search)

        this.state = {
            pos: document.documentElement.scrollTop,
            imglight: true,
            navClass: '',
            bookingType: (query.get('booking_type') == null) ? undefined : query.get('booking_type'),
        }

        this.props.globalSetterAction({
            token: params.token,
        })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true)
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "nav-sticky", imglight: false })
        } else {
            this.setState({ navClass: "", imglight: true })
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* import navbar */}
                {/*<Navbar navClass={this.state.navClass} imglight={this.state.imglight} />*/}

                {/* Hero section */}
                <Section />

                <Wizard bookingType={this.state.bookingType} />

                {/* footer */}
                <Footer />
            </React.Fragment>
        )
    }
}

LandingForm.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
    globalSetterAction
})(LandingForm))