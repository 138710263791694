import React, {Component} from 'react'
import {connect} from 'react-redux'
import { withRouter } from "react-router-dom"
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {loginUserAction} from 'app.cms.react/store/actions'
import FormAlert from 'app.cms.react/components/Common/Alerts/FormAlert'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import ListSocialite from '../Socialite/ListSocialite'
import {pluckRouteFromName} from 'app.cms.react/helpers/utils'

class FormLogin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                email: '',
                password: '',
            },
        }
    }

    handleFormSubmit(values) {
        this.props.loginUserAction(values, this.props.history)
    }

    handleValidationSchema() {
        return Yup.object().shape({
            email: Yup.string().required(
                this.props.t('Email is required')
            ),
            password: Yup.string().required(
                this.props.t('Password is required')
            ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    render() {
        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.form}
                    validationSchema={this.handleValidationSchema()}
                    onSubmit={(values) => this.handleFormSubmit(values)}
                >
                    {({errors, status, touched}) => (
                        <Form className="form-horizontal">
                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />

                            <div className="mb-3">
                                <Field
                                    name="email"
                                    placeholder={this.props.t('Email')}
                                    type="email"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'email')}
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <Field
                                    name="password"
                                    placeholder={this.props.t('Password')}
                                    type="password"
                                    autoComplete="true"
                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'password')}
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mt-3 d-grid">
                                <ButtonLoading
                                    formLoading={this.props.formLoading}
                                    buttonClassName="btn btn-primary btn-block"
                                    buttonName={this.props.t('Log In')}
                                />
                            </div>

                            {(this.props.hasGoogleSocialite || this.props.hasFacebookSocialite || this.props.hasTwitterSocialite) ? (
                                <div className="mt-4 text-center">
                                    <h5 className="font-size-14 mb-3">{this.props.t('Sign in with')}</h5>
                                    <ListSocialite
                                        hasGoogle={this.props.hasGoogleSocialite}
                                        hasFacebook={this.props.hasFacebookSocialite}
                                        hasTwitter={this.props.hasTwitterSocialite}
                                    />
                                </div>
                            ) : null}

                            {(this.props.hasForgotPassword) ? (
                                <React.Fragment>
                                    <div className="mt-4 text-center">
                                        <Link
                                            to={pluckRouteFromName('forgot-password')}
                                            className="text-muted"
                                        >
                                            {this.props.t('Forgot password')}?
                                        </Link>
                                    </div>
                                </React.Fragment>
                            ) : null}

                            {(this.props.hasSignupLink) ? (
                                <div className="mt-5 text-center">
                                    <p>
                                        {this.props.t('Don\'t have an account')}?
                                        {' '}
                                        <Link
                                            to={pluckRouteFromName('sign-up')}
                                            className="fw-medium text-primary"
                                        >
                                            {this.props.t('Sign up now')}
                                        </Link>
                                    </p>
                                </div>
                            ) : null}
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        )
    }
}

FormLogin.propTypes = {
    hasGoogleSocialite: PropTypes.bool.isRequired,
    hasFacebookSocialite: PropTypes.bool.isRequired,
    hasTwitterSocialite: PropTypes.bool.isRequired,
    hasForgotPassword: PropTypes.bool.isRequired,
    hasSignupLink: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    let {
        response,
        alertType,
    } = state.Login

    let {
        disableFields,
        formLoading,
    } = state.ComponentsForm

    return {
        response,
        alertType,
        formLoading,
        disableFields
    }
}


export default connect(mapStateToProps, {
    loginUserAction
})(withRouter(withTranslation()(FormLogin)))