import {
    PACKAGE_BUILDER_INIT,
    PACKAGE_BUILDER,
    PACKAGE_BUILDER_DETAILS,
    PACKAGE_BUILDER_FORM,
    PACKAGE_BUILDER_FORM_ITEMS,
    PACKAGE_BUILDER_FORM_ADD_ROW,
    PACKAGE_BUILDER_FORM_DELETE_ROW,
    PACKAGE_BUILDER_VIEW,
    PACKAGE_BUILDER_VIEW_DATA,
    PACKAGE_BUILDER_DATA,
    PACKAGE_BUILDER_ADD,
    PACKAGE_BUILDER_UPDATE,
    PACKAGE_BUILDER_DELETE,
    PACKAGE_BUILDER_SUCCESS,
    PACKAGE_BUILDER_FAIL
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    dataView: {
        package_name: '',
        description: '',
        category: {
            category_code: '',
            category_name: '',
        },
        items: [{
            id: '',
            product_service_id: '',
            product_item: {
                label: '',
                value: '',
            },
            description: '',
            quantity: 1,
            price: '0.00',
            discount_price: '0.00',
            amount: '0.00',
        }],
        package_price: '0.00',
        suggested_package_price: '0.00',
    },
    response: '',
    alertType: '',
}

const packageBuilder = (state = INIT_STATE, action) => {
    const {type, payload} = action
    let items = []

    switch (type) {
        case PACKAGE_BUILDER_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
            }
        case PACKAGE_BUILDER:
        case PACKAGE_BUILDER_UPDATE:
        case PACKAGE_BUILDER_ADD:
        case PACKAGE_BUILDER_DELETE:
        case PACKAGE_BUILDER_VIEW:
        case PACKAGE_BUILDER_FORM_ADD_ROW:
            return {...state}
        case PACKAGE_BUILDER_SUCCESS:
        case PACKAGE_BUILDER_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case PACKAGE_BUILDER_DATA:
        case PACKAGE_BUILDER_DETAILS:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
        case PACKAGE_BUILDER_VIEW_DATA:
            return {
                ...state,
                dataView: getPropTraverse(payload, ['data']),
            }
        case PACKAGE_BUILDER_FORM:
            return {
                ...state,
                dataView: {
                    ...state.dataView,
                    ...action.payload.params,
                }
            }
        case PACKAGE_BUILDER_FORM_ITEMS:
            items = Object.entries(state.dataView.items).map(([key, val]) => {
                if (key == action.payload.idx) {
                    return {
                        ...val,
                        id: action.payload.params[action.payload.idx].id,
                        product_service_id: action.payload.params[action.payload.idx].product_service_id,
                        name: action.payload.params[action.payload.idx].product_item.label,
                        product_item: {
                            label: action.payload.params[action.payload.idx].product_item.label,
                            value: action.payload.params[action.payload.idx].product_item.value
                        },
                        description: action.payload.params[action.payload.idx].description,
                        quantity: action.payload.params[action.payload.idx].quantity,
                        price: action.payload.params[action.payload.idx].price,
                        amount: action.payload.params[action.payload.idx].amount,
                    }
                } else {
                    return val
                }
            })

            return {
                ...state,
                dataView: {...state.dataView, items},
            }
        case PACKAGE_BUILDER_FORM_DELETE_ROW:
            items = state.dataView.items.filter((item, index) => {
                return action.payload.idx !== index
            })

            return {
                ...state,
                dataView: {...state.dataView, items},
            }
    }

    return {...state}
}

export default packageBuilder