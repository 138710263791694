export const PAYMENTS = 'PAYMENTS'
export const PAYMENTS_ADD = 'PAYMENTS_ADD'
export const PAYMENTS_DATA = 'PAYMENTS_DATA'
export const PAYMENTS_DELETE = 'PAYMENTS_DELETE'
export const PAYMENTS_FAIL = 'PAYMENTS_FAIL'
export const PAYMENTS_FORM = 'PAYMENTS_FORM'
export const PAYMENTS_FORM_DATA = 'PAYMENTS_FORM_DATA'
export const PAYMENTS_INIT = 'PAYMENTS_INIT'
export const PAYMENTS_SUCCESS = 'PAYMENTS_SUCCESS'
export const PAYMENTS_UPDATE = 'PAYMENTS_UPDATE'
export const PAYMENTS_SET_SELECTED_INVOICE = 'PAYMENTS_SET_SELECTED_INVOICE'