import React, {Component} from 'react'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {componentModalToggleAction, componentFormInitAction, componentFormOnSubmitAction} from 'app.cms.react/store/actions'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import FormAlert from 'app.cms.react/components/Common/Alerts/FormAlert'
import PropTypes from 'prop-types'
import {Form, Formik} from 'formik'

const RenderDeleteButton = props => {
    if (props.response != '' && props.alertType == 'success') return null

    return (
        <React.Fragment>
            <button type="submit" className="btn btn-danger">{(typeof props.buttonConfirmText == 'undefined') ? 'Confirm Delete' : props.buttonConfirmText}</button>
        </React.Fragment>
    )
}

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading}/>)
                :
                (<RenderDeleteButton {...props} />)
            }
        </React.Fragment>
    )
}

class ModalDelete extends Component {
    constructor(props) {
        super(props)

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.toggleModal = this.toggleModal.bind(this)

        this.props.componentFormInitAction()
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
        this.props.resetAlertAction()
    }

    handleFormSubmit(values) {
        const params = {primaryKey: this.props.primaryKey}
        this.props.deleteAction({...params, ...this.props})

        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'delete') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={''}
                        onSubmit={(values) => this.handleFormSubmit(values)}
                    >
                        {({errors, status, touched}) => (
                            <Form className="form-horizontal">
                                <ModalHeader>{(typeof this.props.title == 'undefined') ? 'Delete' : this.props.title}</ModalHeader>
                                <ModalBody>
                                    <FormAlert response={this.props.response} alertType={this.props.alertType} alertClassName={'mb-0'} validationType="notification"/>
                                    {(this.props.response == '') ? (
                                        <React.Fragment><i className="mdi mdi-alert-outline me-2"></i>{(typeof this.props.context == 'undefined') ? 'Are you sure you want to delete the selected record?' : this.props.context}</React.Fragment>
                                    ) : null}
                                </ModalBody>
                                <ModalFooter>
                                    <FormSubmitButtons
                                        formLoading={this.props.formLoading}
                                        buttonConfirmText={this.props.buttonConfirmText}
                                        toggleModal={this.toggleModal}
                                        response={this.props.response}
                                        alertType={this.props.alertType}
                                    />
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalDelete.propTypes = {
    primaryKey: PropTypes.any.isRequired,
    context: PropTypes.any,
    title: PropTypes.any,
    buttonConfirmText: PropTypes.any,
    response: PropTypes.any,
    alertType: PropTypes.any,
    resetAlertAction: PropTypes.func.isRequired,
    deleteAction: PropTypes.func.isRequired,
    params: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
})(ModalDelete))