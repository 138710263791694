import {
    CONTACTS_INIT,
    CONTACTS,
    CONTACTS_DATA,
    CONTACTS_UPDATE,
    CONTACTS_ADD,
    CONTACTS_DELETE,
    CONTACTS_SUCCESS,
    CONTACTS_FAIL,
    CONTACTS_FORM,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    response: '',
    alertType: '',
    country: {},
}

const contacts = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case CONTACTS_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
            }
        case CONTACTS:
        case CONTACTS_UPDATE:
        case CONTACTS_ADD:
        case CONTACTS_DELETE:
            return {...state}
        case CONTACTS_SUCCESS:
        case CONTACTS_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case CONTACTS_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
        case CONTACTS_FORM:
            return {
                ...state,
                country: action.payload.params.country,
            }
    }

    return {...state}
}

export default contacts