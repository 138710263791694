import {takeEvery, put, call} from 'redux-saga/effects'
import {
    PAYMENTS_ADD,
    PAYMENTS_DELETE,
    PAYMENTS_UPDATE,
    PAYMENTS,
} from './actionTypes'
import {
    paymentsFailAction,
    paymentsDataAction,
    paymentsSuccessAction,
    componentFormOnSubmitDoneAction,
} from '../actions'
import {
    getPropTraverse,
    getErrorResponse,
 } from 'app.cms.react/helpers/utils'
 import * as API_URL from '../actionTypes'
import { apiDel, apiPost, apiPut } from 'app.cms.react/helpers/apiHelpers'

function* paymentsAdd({payload: {params}}) {
    const options = {...params}
    let componentResponse = {}

    try {
        const response = yield call(apiPost, API_URL.AJAX_URL_PAYMENTS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(paymentsSuccessAction(componentResponse))
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(paymentsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* paymentsUpdate({payload: {params}}) {
    const options = {
        data: {
            id: params.primaryKey.id,
        }
    }

    let componentResponse = {}

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_PAYMENTS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(paymentsSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_PAYMENTS)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(paymentsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(paymentsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* paymentsDelete({payload: {params}}) {
    let componentResponse = {}

    try {
        const response = yield call(apiDel, API_URL.AJAX_URL_PAYMENTS, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(paymentsSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_PAYMENTS)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(paymentsDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(paymentsFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* PaymentsSaga() {
    yield takeEvery(PAYMENTS_ADD, paymentsAdd)
    yield takeEvery(PAYMENTS_DELETE, paymentsDelete)
    yield takeEvery(PAYMENTS_UPDATE, paymentsUpdate)
    // yield takeEvery(PAYMENTS, payments)
}

export default PaymentsSaga