import {takeEvery, call} from 'redux-saga/effects'
import {LOGOUT_USER} from './actionTypes'
import * as API_URL from 'app.cms.react/store/actionTypes'
import {
    getErrorResponse,
    unsetJWTToken,
} from 'app.cms.react/helpers/utils'
import {apiPost} from 'app.cms.react/helpers/apiHelpers'

function* logoutUser({payload: {history}}) {
    try {
        yield call(apiPost, API_URL.AJAX_URL_LOGOUT)
        unsetJWTToken()
        window.location.assign('/login')
    } catch (error) {
        console.log(getErrorResponse(error), ' - error')
    }
}

function* LogoutSaga() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export default LogoutSaga