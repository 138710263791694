import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import DataTableLoader from 'app.cms.react/components/Common/DataTable/DataTableLoader'
import {Card, CardBody, Col, Row, List, ListInlineItem} from 'reactstrap'
import DataTable from 'app.cms.react/components/Common/DataTable/DataTable'
import {
    componentModalToggleAction,
    componentFormInitAction,
    micrositesAction,
    micrositesDeleteAction,
    micrositesInitAction,
} from 'store/actions'
import ModalDelete from 'app.cms.react/components/Common/Modals/ModalDelete'
import {withRouter} from 'react-router-dom'
import ModalEditMicrosites from './ModalEditMicrosites'
import ModalAddMicrosites from './ModalAddMicrosites'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const formatActions = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <div className="float-end">
                <List type="inline" className="p-0 m-0">
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleUpdate(row, e)
                    }}><i className="bx bx-pencil text-primary"/></ListInlineItem>
                    <ListInlineItem onClick={(e) => {
                        formatExtraData.toggleDelete(row, e)
                    }}><i className="bx bx-trash text-danger"/></ListInlineItem>
                </List>
            </div>
        </React.Fragment>
    )
}

const formatTokens = (cell, row, rowIndex, formatExtraData) => {
    return (
        <React.Fragment>
            <Link to={`microsite/` + cell} target="_blank">{cell}</Link>
        </React.Fragment>
    )
}

const columns = (props) => [
    {
        dataField: 'microsite_name',
        text: 'Name',
        sort: true,
    },
    {
        dataField: 'microsite_desc',
        text: 'Description',
        sort: true,
    },
    {
        dataField: 'token',
        text: 'Token',
        formatter: formatTokens,
    },
    {
        dataField: 'actions',
        text: 'Actions',
        sort: false,
        headerClasses: 'text-end col-md-3',
        formatter: formatActions,
        formatExtraData: {
            toggleUpdate: props.toggleUpdate,
            toggleDelete: props.toggleDelete,
        }
    },
]

class TableMicrosites extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: {
                id: null,
                microsite_name: '',
                microsite_desc: '',
                token: '',
            },
            toggleAddModalProps: {
                target: 'addMicrosite',
                isOpen: true,
            },
            toggleEditModalProps: {
                target: 'editMicrosite',
                isOpen: true
            },
            toggleDeleteModalProps: {
                target: 'delete',
                isOpen: true
            }
        }

        this.props.micrositesAction()
        this.toggleUpdate = this.toggleUpdate.bind(this)
        this.toggleDelete = this.toggleDelete.bind(this)
    }

    setStateSelected(row) {
        this.setState({
            selected: {
                id: (typeof row != 'undefined') ? row.id : '',
                microsite_name: (typeof row != 'undefined') ? row.microsite_name : '',
                microsite_desc: (typeof row != 'undefined') ? row.microsite_desc : '',
                token: (typeof row != 'undefined') ? row.token : '',
            }
        })
    }

    toggleUpdate(row) {
        this.setStateSelected(row)
        this.props.componentFormInitAction()
        this.props.componentModalToggleAction(this.state.toggleEditModalProps)
    }

    toggleDelete(row) {
        this.setStateSelected(row)
        this.props.componentModalToggleAction(this.state.toggleDeleteModalProps)
    }

    render() {
        return (
            <React.Fragment>
                <DataTableLoader
                    data={this.props.data}
                    isDataTableLoading={this.props.isDataTableLoading}
                    toggleAddModal={this.state.toggleAddModalProps}
                    dataTable={
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                columns={columns({
                                                    toggleUpdate: this.toggleUpdate,
                                                    toggleDelete: this.toggleDelete,
                                                })}
                                                data={this.props.data}
                                                isDataTableLoading={this.props.isDataTableLoading}
                                                toggleAddModal={this.state.toggleAddModalProps}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <ModalEditMicrosites
                                id={this.state.selected.id}
                                microsite_name={this.state.selected.microsite_name}
                                microsite_desc={this.state.selected.microsite_desc}
                                token={this.state.selected.token}
                            />
                        </React.Fragment>
                    }
                />
                <ModalDelete
                    primaryKey={
                        {
                            id: this.state.selected.id
                        }
                    }
                    deleteAction={this.props.micrositesDeleteAction}
                    alertType={this.props.alertType}
                    response={this.props.response}
                    resetAlertAction={this.props.micrositesInitAction}
                    type={this.props.contactType}
                />
                <ModalAddMicrosites />
            </React.Fragment>
        )
    }
}

TableMicrosites.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        data,
        alertType,
        response,
    } = state.Microsites

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    return {
        isDataTableLoading,
        data,
        alertType,
        response,

    }
}

export default connect(mapStateToProps, {
    micrositesAction,
    micrositesDeleteAction,
    micrositesInitAction,
    componentModalToggleAction,
    componentFormInitAction,
})(withRouter(withTranslation()(TableMicrosites)))