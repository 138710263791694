import PropTypes from 'prop-types'
import React, {Component} from 'react'
import 'react-drawer/lib/react-drawer.css'

import {connect} from 'react-redux'

import {Link} from 'react-router-dom'

// Import menuDropdown
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown'
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu'

import logo from '../../assets/images/logo.svg'
import logoLightSvg from '../../assets/images/logo-light.svg'

//i18n
import {withTranslation} from 'react-i18next'

class Header extends Component {
    constructor(props) {
        super(props)

        this.toggleMenu = this.toggleMenu.bind(this)
    }

    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback()
    }

    render() {
        return (
            <React.Fragment>
                <header id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box d-lg-none d-md-block">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="22"/>
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoLightSvg} alt="" height="22"/>
                                    </span>
                                </Link>
                            </div>

                            <button
                                type="button"
                                onClick={this.toggleMenu}
                                className="btn btn-sm px-3 font-size-16 header-item"
                                id="vertical-menu-btn"
                            >
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        </div>
                        <div className="d-flex">
                            <NotificationDropdown/>
                            <ProfileMenu/>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}

Header.propTypes = {
    t: PropTypes.any,
    toggleMenuCallback: PropTypes.any,
}

const mapStatetoProps = state => {
    const {layoutType} = state.Layout
    return {layoutType}
}

export default connect(mapStatetoProps, {})(
    withTranslation()(Header)
)
