import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL
} from './actionTypes'

export const loginUserAction = (params, history) => ({
    type: LOGIN_USER,
    payload: {params, history},
})

export const loginUserSuccessAction = (params) => ({
    type: LOGIN_USER_SUCCESS,
    payload: params,
})

export const loginUserFailAction = (params) => ({
    type: LOGIN_USER_FAIL,
    payload: params,
})
