import {
    SIGN_UP_USER_FAIL,
    SIGN_UP_USER_SUCCESS
} from './actionTypes'

const INIT_STATE = {
    response: null,
    alertType: '',
}

const SignUp = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case SIGN_UP_USER_SUCCESS:
        case SIGN_UP_USER_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
    }

    return {...state}
}

export default SignUp