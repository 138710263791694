import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Button, Card, CardBody, Col, Label, Row} from 'reactstrap'
import PropTypes from 'prop-types'
import 'flatpickr/dist/themes/material_blue.css'
import Select from 'react-select'
import {
    productsServicesAction,
    packageBuilderAddAction,
    componentModalToggleAction,
    componentFormOnSubmitAction,
    componentFormInitAction,
    packageBuilderInitAction,
} from 'store/actions'
import {ddlbParser, decimalPlace} from 'app.cms.react/helpers/utils'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import ModalAddProductsServices from '../ProductsServices/ModalAddProductsServices'
import FormAlert from '../Common/Alerts/FormAlert'
import { NumericFormat } from 'react-number-format'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const formatOptionLabelProductsServices = ({ value, label, price, description }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>
                <div className="clearfix">
                    <div className="text-truncate">{label}{typeof description == 'string' ? <React.Fragment> - <em>{description}</em></React.Fragment> : ''}</div>
                    <small>
                        <NumericFormat value={decimalPlace(price)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                    </small>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

class FormCreatePackageBuilder extends Component {
    constructor(props) {
        super(props)

        this.state = {
            package_name: '',
            description: '',
            category: {
                category_code: '',
                category_name: '',
            },
            items: [{
                product_service: '',
                description: '',
                quantity: 1,
                price: '0.00',
                discount_price: '0.00',
                amount: '0.00',
            }],
            total_amount: '0.00',
            suggested_package_price: '0.00',
            toggleAddProductsServicesModalProps: {
                target: 'addProductsServices',
                isOpen: true,
            },
        }

        this.props.packageBuilderInitAction()
        this.props.componentFormInitAction()
        this.props.productsServicesAction()

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleSetProductServiceItem = this.handleSetProductServiceItem.bind(this)
        this.handleSetProductServiceItemDescription = this.handleSetProductServiceItemDescription.bind(this)

    }
    handleAddRow = () => {
        const values = this.state.items
        values.push({
            quantity: 1,
            price: '0.00',
            discount_price: '0.00',
            amount: '0.00',
        })

        this.setState({ items: values })
    }

    handleAddProductService = () => {
        this.props.componentModalToggleAction(this.state.toggleAddProductsServicesModalProps)
    }

    handleRemoveRow = (e, idx) => {
        const stateList = this.state.items.filter((item, index) => {
            return idx !== index
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})
    }

    handleOnChange = (value, idx, type) => {
        switch (type) {
            case 'category':
                this.setState({category: value})
                break
            case 'product_service':
                this.handleSetProductServiceItemOnSelect(value, idx)
                break
            default:
                this.setState({[type]: value})
        }
    }

    handleSetProductServiceItemOnSelect(value, idx) {
        const stateList = Object.entries(this.state.items).map(([key, val]) => {
            if (key == idx) {
                return {
                    id: value.id,
                    name: value.name,
                    description: value.description,
                    quantity: val.quantity,
                    price: value.price,
                    discount_price: val.discount_price,
                    amount: val.quantity * value.price,
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})
    }

    handleSetProductServiceItem(e, idx, type) {
        this.computeSubTotal(e, idx, type)
    }

    handleSetProductServiceItemDescription(e, idx) {
        const stateList = Object.entries(this.state.items).map(([key, val]) => {
            if (key == idx) {
                return {
                    ...val,
                    description: e.target.value
                }
            } else {
                return val
            }
        })

        this.setState({items: stateList})
    }

    computeSubTotal(e, idx, type) {
        let discount = '0.00'

        const stateList = Object.entries(this.state.items).map(([key, val]) => {
            if (key == idx) {
                switch (type) {
                    case 'quantity':
                        discount = (val.discount_price < (e.target.value * val.price)) ? e.target.value * val.price - val.discount_price : discount
                        return {
                            ...val,
                            quantity: e.target.value,
                            amount: discount,
                        }
                    case 'price':
                        discount = (val.discount_price < (e.target.value * val.quantity)) ? e.target.value * val.quantity - val.discount_price : discount
                        return {
                            ...val,
                            price: e.target.value,
                            amount: discount,
                        }
                    case 'discount_price':
                        discount = (e.target.value < (val.price * val.quantity)) ? val.price * val.quantity - e.target.value : discount
                        return {
                            ...val,
                            discount_price: e.target.value,
                            amount: discount,
                        }
                    case 'suggested_package_price':
                        return {
                            ...val,
                        }
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})
    }

    computeTotal(stateList) {
        let total = 0.00

        Object.entries(stateList).map(([key, value]) => {
            total += value.amount
        })


        this.setState({
            suggested_package_price: total,
            total_amount: total
        })
    }

    handleOverrideTotalAmount(e) {
        this.setState({total_amount: e.target.value})
    }

    handleValidationSchema() {
        return Yup.object().shape({
            // product_service_desc: Yup.string().required(
            //     this.props.t('Product / Service name is required')
            // ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    handleFormSubmit() {
        this.props.packageBuilderAddAction({
            package_name: this.state.package_name,
            description: this.state.description,
            category: this.state.category,
            category_type: this.props.categoryType,
            price: this.state.total_amount,
            items: JSON.stringify(this.state.items),
        })
        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                <ModalAddProductsServices />
                <Formik
                    enableReinitialize={false}
                    initialValues={this.props || this.state}
                    validationSchema={this.handleValidationSchema()}
                    onSubmit={(values) => this.handleFormSubmit(values)}
                >
                    {({errors, status, touched}) => (
                        <Form className="form-horizontal mb-4">
                            <Row>
                                <Col md={12}>
                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Label className={'col-md-1 col-form-label'} htmlFor="package_name">{this.props.t('Package Name')}</Label>
                                                <Col>
                                                    <Field
                                                        name="package_name"
                                                        type="text"
                                                        className={'form-control' + this.handleFieldValidation(errors, touched, 'package_name')}
                                                        disabled={this.props.disableFields}
                                                        onChange={(e) => {
                                                            this.handleOnChange(e.target.value, 0, 'package_name')
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className={"mt-3"}>
                                                <Label className={'col-md-1 col-form-label'} htmlFor="package_desc">{this.props.t('Description')}</Label>
                                                <Col>
                                                    <CKEditor
                                                        name="description"
                                                        editor={ClassicEditor}
                                                        config={
                                                            {
                                                                toolbar: ['bold', 'italic', 'numberedList', 'bulletedList']
                                                            }
                                                        }
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            this.handleOnChange(data, 0, 'description')
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            {(this.props.ddlbCategories) ? (
                                                <Row className={"mt-3"}>
                                                    <Label className={'col-md-1 col-form-label'} htmlFor="category">{this.props.t('Category')}</Label>
                                                    <Col md={11}>
                                                        <Select
                                                            name="category"
                                                            options={this.props.ddlbCategories.booking_types}
                                                            onChange={(e) => (this.handleOnChange(e, 0, 'category'))}
                                                            classNamePrefix="select2-selection"
                                                            className={this.handleFieldValidation(errors, touched, 'category')}
                                                            isClearable={true}
                                                            required
                                                            value={this.props.category}
                                                        />
                                                        <ErrorMessage
                                                            name="category"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : null}
                                        </CardBody>
                                    </Card>
                                    <Card>
                                        <CardBody>
                                            <Label className={'col-md-2 col-form-label pt-0'}>{this.props.t('Inclusions')}</Label>
                                            <table className="table vertical-align-middle table-striped pt-0">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-4">{this.props.t('Product / Service')}</th>
                                                    <th className="col-md-4">&nbsp;</th>
                                                    <th className="col-md-1 text-end">{this.props.t('Quantity')}</th>
                                                    <th className="text-end">{this.props.t('Price')}</th>
                                                    <th className="text-end">{this.props.t('Amount')}</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.items.map((item1, idx) => (
                                                        <tr id={"nested" + idx} key={idx}>
                                                            <td>
                                                                <div className="input-group">
                                                                    <div className="form-control border-0 p-0">
                                                                        <Select
                                                                            name="product_service"
                                                                            options={ddlbParser(this.props.productsServices, {
                                                                                label: 'name',
                                                                                value: 'id',
                                                                            }, true)}
                                                                            onChange={(e) => (this.handleOnChange(e, idx, 'product_service'))}
                                                                            classNamePrefix="select2-selection "
                                                                            isClearable={false}
                                                                            formatOptionLabel={formatOptionLabelProductsServices}
                                                                            isDisabled={this.props.formLoading}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    <Button onClick={this.handleAddProductService} color="primary">+</Button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    name="product_service_desc"
                                                                    type="text"
                                                                    className={'form-control' + this.handleFieldValidation(errors, touched, 'product_service_desc')}
                                                                    disabled={this.props.disableFields}
                                                                    value={(this.state.items[idx].description != null) ? this.state.items[idx].description : ''}
                                                                    onChange={(e) => (this.handleSetProductServiceItemDescription(e, idx))}
                                                                />
                                                                <ErrorMessage
                                                                    name="product_service_desc"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </td>
                                                            <td>
                                                                <NumericFormat
                                                                    allowNegative={false}
                                                                    name="quantity"
                                                                    type="text"
                                                                    className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'quantity')}
                                                                    disabled={this.props.disableFields}
                                                                    value={this.state.items[idx].quantity}
                                                                    onChange={(e) => (this.handleSetProductServiceItem(e, idx, 'quantity'))}
                                                                />
                                                                <ErrorMessage
                                                                    name="quantity"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </td>
                                                            <td>
                                                                <Label className="form-control-plaintext text-end">
                                                                    <NumericFormat
                                                                        allowNegative={false}
                                                                        name="price"
                                                                        displayType="text"
                                                                        disabled={this.props.disableFields}
                                                                        value={this.state.items[idx].price}
                                                                        onChange={(e) => (this.handleSetProductServiceItem(e, idx, 'price'))}
                                                                    />
                                                                </Label>
                                                            </td>
                                                            <td>
                                                                <Label className="form-control-plaintext text-end">
                                                                    <NumericFormat value={decimalPlace(this.state.items[idx].amount)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                                                                </Label>
                                                            </td>
                                                            <td className="text-end">
                                                                {(idx > 0) ? (
                                                                    <Button
                                                                        onClick={e =>
                                                                            this.handleRemoveRow(e, idx)
                                                                        }
                                                                        color="danger"
                                                                        className="btn-block inner"
                                                                        value={idx}
                                                                    >
                                                                        <i className="bx bx-trash" />
                                                                    </Button>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td>
                                                            <Button onClick={this.handleAddRow} color="primary">{this.props.t('Add Item')}</Button>
                                                        </td>
                                                        <td colSpan={4}>
                                                            <Row>
                                                                <Col>
                                                                    <Label className="form-control-plaintext text-end font-size-16">
                                                                        {this.props.t('Suggested Package Price')}
                                                                    </Label>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <Label className="form-control-plaintext text-end font-size-16">
                                                                        <NumericFormat
                                                                            value={decimalPlace(this.state.suggested_package_price)}
                                                                            displayType="text"
                                                                            thousandSeparator={true}
                                                                            decimalSeparator="."
                                                                        />
                                                                    </Label>
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <Row>
                                                                <Col>
                                                                    <Label className="form-control-plaintext text-end font-size-16">
                                                                        {this.props.t('Package Price')}
                                                                    </Label>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <NumericFormat
                                                                        className="form-control text-end font-size-18"
                                                                        value={this.state.total_amount}
                                                                        type="text"
                                                                        decimalSeparator="."
                                                                        onChange={(e) => (this.handleOverrideTotalAmount(e))}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                        <td>
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={12}>
                                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-end">
                                            <ButtonLoading
                                                formLoading={this.props.formLoading}
                                                buttonClass={'btn btn-primary w-md me-3'}
                                                // onClick={() => {this.props.invoicesSubmitExportAction(false)}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        )
    }
}

FormCreatePackageBuilder.propTypes = {
    t: PropTypes.any,
    ddlbCategories: PropTypes.any,
    categoryType: PropTypes.any
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const productsServices = {
        ...state.ProductsServices.data
    }

    const {
        response,
        alertType,
    } = state.PackageBuilder

    return {
        productsServices,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    productsServicesAction,
    packageBuilderAddAction,
    packageBuilderInitAction,
    componentModalToggleAction,
    componentFormOnSubmitAction,
    componentFormInitAction
})(FormCreatePackageBuilder))