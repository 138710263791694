import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {componentModalToggleAction} from 'app.cms.react/store/components/modal/actions'
import {componentFormInitAction, componentFormOnSubmitAction} from 'app.cms.react/store/actions'
import ButtonLoading from '../Common/Buttons/ButtonLoading'
import FormAlert from '../Common/Alerts/FormAlert'
import {productsServicesInitAction, productsServicesAddAction} from 'app.cms.react/store/productsServices/actions'
import FormProductServices from './FormProductServices'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'

const FormSubmitButtons = props => {
    return (
        <React.Fragment>
            <button type="button" onClick={(e) => props.toggleModal({isOpen: false})} className="btn btn-secondary" disabled={props.formLoading}>Close</button>
            {(props.formLoading) ?
                (<ButtonLoading formLoading={props.formLoading} />)
                :
                (<button type="submit" className="btn btn-primary">Save changes</button>)
            }
        </React.Fragment>
    )
}

class ModalAddProductsServices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                product_service_name: '',
                product_service_desc: '',
                price: '',
            },
        }

        this.toggleModal = this.toggleModal.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    toggleModal(value) {
        this.props.componentModalToggleAction({isOpen: value.isOpen})
        this.props.componentFormInitAction()
        this.props.productsServicesInitAction()
    }

    handleFormSubmit(values) {
        this.props.productsServicesAddAction(values)
        this.props.componentFormOnSubmitAction()
    }

    handleValidationSchema() {
        return Yup.object().shape({
            product_service_name: Yup.string().required(
                this.props.t('Product / Service name is required')
            ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'addProductsServices') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={this.state.form}
                        validationSchema={this.handleValidationSchema()}
                        onSubmit={(values) => this.handleFormSubmit(values)}
                    >
                        {({errors, status, touched}) => (
                            <Form className="form-horizontal">
                                <ModalHeader>Add New</ModalHeader>
                                <ModalBody>
                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                    <FormProductServices errors={errors} touched={touched} handleFieldValidation={this.handleFieldValidation} />
                                </ModalBody>
                                <ModalFooter>
                                    <FormSubmitButtons formLoading={this.props.formLoading} toggleModal={this.toggleModal} />
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalAddProductsServices.propTypes = {
    t: PropTypes.any,
    ddlbCountries: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const {
        isOpen,
        target,
    } = state.ComponentsModal

    const {
        response,
        alertType
    } = state.ProductsServices

    return {
        isOpen,
        target,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    componentFormInitAction,
    componentFormOnSubmitAction,
    productsServicesAddAction,
    productsServicesInitAction,
})(ModalAddProductsServices))