import {takeEvery, put, call} from 'redux-saga/effects'
import {
    INVOICES,
    INVOICES_UPDATE,
    INVOICES_ADD,
    INVOICES_DELETE,
    INVOICES_EXPORT,
    INVOICES_VIEW,
    INVOICES_PAYMENTS,
    INVOICES_PAYMENTS_EXPORT,
} from './actionTypes'
import {
    invoicesDataAction,
    componentFormOnSubmitDoneAction,
    componentDataTableLoadedAction,
    // exceptionHandlerAction,
    componentDataTableLoadingAction,
    componentModalToggleAction,
    invoicesViewDataAction,
    invoicesExportLoadingAction,
    invoicesExportLoadedAction,
    invoicesFailAction,
    invoicesSuccessAction,
    invoicesPaymentsDataAction,
} from '../actions'
import * as API_URL from '../actionTypes'
import {apiGet, apiPost, apiPostDownload, file} from 'app.cms.react/helpers/apiHelpers'
import {getErrorResponse, getPropTraverse} from 'app.cms.react/helpers/utils'

function* invoices({payload: {params, history}}) {
    try {
        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_INVOICES)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(invoicesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* invoicesView({payload: {params, history}}) {
    try {
        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_INVOICES_VIEW, {params: {invoice_id: params.invoice_id}})
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(invoicesViewDataAction(data))
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
    }

    yield put(componentDataTableLoadedAction())
}

//
// function* invoicesUpdate({payload: {params, meta}}) {
//     let options = defaultPutOptions();
//     let componentResponse = {}
//
//     options.body = formatFormData({
//         id: params.id,
//         name: params.name,
//     });
//
//     try {
//         const response = yield call(fetchJSON, AJAX_URL_INVOICES, options)
//         componentResponse = {
//             alertType: 'success',
//             response: getPropTraverse(response, ['response', 'data']),
//         }
//
//         const responseData = yield call(fetchJSON, AJAX_URL_INVOICES, defaultGetOptions())
//         const data = getPropTraverse(responseData, ['response', 'data'])
//         yield put(invoicesDataAction(data))
//     } catch (error) {
//         componentResponse = {
//             alertType: 'danger',
//             response: getPropTraverse(error, ['data', 'response', 'error']),
//         }
//     }
//
//     yield put(componentFormOnSubmitDoneAction(componentResponse))
// }
//


function* invoicesPaymentsExport({payload: {params, meta}}) {
    const options = {
        invoice_id: params.invoice_id,
        payment_id: params.payment_id,
    }

    try {
        // yield put(invoicesExportLoadingAction())
        yield call(apiPostDownload, API_URL.AJAX_URL_INVOICES_PAYMENTS_EXPORT, options, 'payment-receipt.pdf')
    } catch (error) {

    }
    // yield put(invoicesExportLoadedAction())
}


function* invoicesExport({payload: {params, meta}}) {
    const options = {
        invoice_id: params.invoice_id,
    }

    try {
        yield put(invoicesExportLoadingAction())
        yield call(apiPostDownload, API_URL.AJAX_URL_INVOICES_EXPORT, options, 'invoice.pdf')
    } catch (error) {

    }
    yield put(invoicesExportLoadedAction())
}

function readAsText(error) {
    return new Promise(resolve => {
        const reader  = new FileReader();
        reader.onload = () => resolve(reader.result)
        // reader.onerror = reject
        console.log(error.response.data)
        reader.readAsText(error.response.data)
    })
}

function* invoicesAdd({payload: {params, meta}}) {
    const options = {
        customer: params.customer,
        items: params.items,
        invoice_date: params.invoice_date,
        payment_due_date: params.payment_due_date,
        notes: params.notes,
        export: params.submit_export
    }
    let componentResponse = {}

    if (params.submit_export) {
        try {
            yield put(componentModalToggleAction({
                target: 'loader',
                isOpen: true
            }))
            yield call(apiPostDownload, API_URL.AJAX_URL_INVOICES_GENERATE, options, 'invoice.pdf')

            componentResponse = {
                alertType: 'success',
                response: 'Record successfully added.', // Hard-coded due to BLOB response of on-demand export
            }

            yield put(invoicesSuccessAction(componentResponse))
        } catch (error) {
            const errorResponse = yield call(readAsText, error)
            componentResponse = {
                alertType: 'danger',
                response: getPropTraverse(JSON.parse(errorResponse), ['response', 'error'])
            }
            console.log(componentResponse)
            yield put(invoicesFailAction(componentResponse))
        }

        yield put(componentModalToggleAction({
            target: 'loader',
            isOpen: false
        }))
    } else {
        try {
            const response = yield call(apiPost, API_URL.AJAX_URL_INVOICES_GENERATE, options)
            componentResponse = {
                alertType: 'success',
                response: getPropTraverse(response, ['response', 'data']),
            }

            yield put(invoicesSuccessAction(componentResponse))
        } catch (error) {
            console.log(error.response)
            componentResponse = {
                alertType: 'danger',
                response: getErrorResponse(error),
            }
            yield put(invoicesFailAction(componentResponse))
        }
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* invoicesPayments({payload: {params, reload}}) {
    try {
        if (reload === true) {
            yield put(componentDataTableLoadingAction())
        }
        const response = yield call(apiGet, API_URL.AJAX_URL_INVOICES_PAYMENTS, {params: {invoice_id: params.invoice_id}})
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(invoicesPaymentsDataAction(data))
    } catch (error) {
        // yield put(exceptionHandlerAction(error))
    }

    if (reload === true) {
        yield put(componentDataTableLoadedAction())
    }
}

function* InvoicesSaga() {
    yield takeEvery(INVOICES, invoices)
    // yield takeEvery(INVOICES_UPDATE, invoicesUpdate)
    yield takeEvery(INVOICES_ADD, invoicesAdd)
    yield takeEvery(INVOICES_EXPORT, invoicesExport)
    yield takeEvery(INVOICES_VIEW, invoicesView)
    // yield takeEvery(INVOICES_DELETE, invoicesDelete)
    yield takeEvery(INVOICES_PAYMENTS, invoicesPayments)
    yield takeEvery(INVOICES_PAYMENTS_EXPORT, invoicesPaymentsExport)
}

export default InvoicesSaga