import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Button, CardTitle, Col, Row} from 'reactstrap'
import Select from 'react-select'
import {ddlbParser, getPropTraverse} from 'app.cms.react/helpers/utils'
import {ErrorMessage, Field} from 'formik'
import {
    contactsAction,
    componentModalToggleAction,
} from 'app.cms.react/store/actions'
import ModalAddContacts from 'app.cms.react/components/Contacts/ModalAddContacts'
import {bookingsFormAction} from 'store/bookings/actions'

const formatOptionLabelCustomers = ({ value, label, email }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>{label}<br /><small>{email}</small></React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const RenderCustomerDetail = (props) => {
    const data = props.customer
    return (
        <React.Fragment>
            <p>
                <strong>{data.full_name}</strong><br />
                {data.email}<br />
                {data.contact_number}
            </p>
        </React.Fragment>
    )
}

class ContactPerson extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customer: {
                full_name: '',
                email: '',
                contact_number: '',
                address: '',
            },
            toggleAddContactModalProps: {
                target: 'addContacts',
                isOpen: true,
            },
        }
        this.props.contactsAction({type: 'customer', token: this.props.token})

    }

    componentDidMount() {
        this.handleSetCustomerDetailOnSelect(getPropTraverse(this.props.bookingForm, ['contact_person']), true)
    }

    handleDdlbChange = (value, idx, type) => {
        switch (type) {
            case 'customer':
                this.handleSetCustomerDetailOnSelect(value)
                this.props.bookingsFormAction({contact_person: value})
                break
        }
    }

    handleSetCustomerDetailOnSelect(value) {
        this.setState({
            customer: {
                id: value.id,
                full_name: value.full_name,
                email: value.email,
                contact_number: (getPropTraverse(value.contact_details) == null) ? [] : value.contact_details[0].contact_number,
            }
        })
    }

    handleAddContact = () => {
        this.props.componentModalToggleAction(this.state.toggleAddContactModalProps)
    }

    renderContactPersonDdlb() {
        return (
            <React.Fragment>
                <Row className="mb-3">
                    <CardTitle>{this.props.t('Contact Person')}</CardTitle>
                    <Col md={12}>
                        <div className="input-group">
                            <div className="form-control border-0 p-0">
                                <Select
                                    name="customer"
                                    options={ddlbParser(this.props.customers, {
                                        label: 'full_name',
                                        value: 'id',
                                    }, true)}
                                    onChange={(e) => (this.handleDdlbChange(e, 0, 'customer'))}
                                    formatOptionLabel={formatOptionLabelCustomers}
                                    classNamePrefix="select2-selection "
                                    isClearable={false}
                                    isDisabled={this.props.formLoading}
                                    required
                                    value={this.props.handleDdlbDefaultValue('customer')}
                                />
                            </div>
                            <Button onClick={this.handleAddContact} color="primary">+</Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={12}>
                        <RenderCustomerDetail customer={this.state.customer} />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderContactPersonForm() {
        return (
            <React.Fragment>
                <CardTitle>{this.props.t('Contact Person')}</CardTitle>
                <Row>
                    <Col>
                        <Field
                            name="contact_person_first_name"
                            placeholder={this.props.t('First name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'contact_person_first_name')}
                            onChange={(e) => {
                                this.props.handleFieldChange('contact_person_first_name', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('contact_person_first_name')}
                        />
                        <ErrorMessage
                            name="contact_person_first_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="contact_person_last_name"
                            placeholder={this.props.t('Last name')}
                            type="text"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'contact_person_last_name')}
                            onChange={(e) => {
                                this.props.handleFieldChange('contact_person_last_name', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('contact_person_last_name')}
                        />
                        <ErrorMessage
                            name="contact_person_last_name"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="contact_person_email"
                            placeholder={this.props.t('Email')}
                            type="email"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'contact_person_email')}
                            onChange={(e) => {
                                this.props.handleFieldChange('contact_person_email', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('contact_person_email')}
                        />
                        <ErrorMessage
                            name="contact_person_email"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
                <Row className={"mt-3"}>
                    <Col>
                        <Field
                            name="contact_person_contact_number"
                            placeholder={this.props.t('Contact Number')}
                            type="tel"
                            className={'form-control' + this.props.handleFieldValidation(this.props.errors, this.props.touched, 'contact_person_contact_number')}
                            onChange={(e) => {
                                this.props.handleFieldChange('contact_person_contact_number', e.target.value)
                            }}
                            value={this.props.handleTextDefaultValue('contact_person_contact_number')}
                        />
                        <ErrorMessage
                            name="contact_person_contact_number"
                            component="div"
                            className="invalid-feedback"
                        />
                    </Col>
                </Row>
            </React.Fragment>
        )
    }

    renderContactPerson() {
        if (this.props.token == '') {
            return (
                <React.Fragment>{this.renderContactPersonDdlb()}</React.Fragment>
            )
        }

        return (
            <React.Fragment>{this.renderContactPersonForm()}</React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <ModalAddContacts
                    contactType={'customer'}
                    ddlbCountries={this.props.ddlbCodeTable.countries}
                />
                {this.renderContactPerson()}
            </React.Fragment>
        )
    }
}

ContactPerson.propTypes = {
    t: PropTypes.any,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    handleTextDefaultValue: PropTypes.any.isRequired,
    handleFieldChange: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    const bookingForm = {
        ...state.Bookings.form
    }

    const {
        formLoading,
    } = state.ComponentsForm

    const ddlbCodeTable = {
        ...state.CodeTable.data
    }

    const customers = {
        ...state.Contacts.data
    }

    const {
        token,
    } = state.Globals

    return {
        bookingForm,
        formLoading,
        ddlbCodeTable,
        customers,
        token
    }
}

export default withTranslation()(connect(mapStateToProps, {
    bookingsFormAction,
    contactsAction,
    componentModalToggleAction
})(ContactPerson))