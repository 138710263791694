import {takeEvery, put, call} from 'redux-saga/effects'
import {LOGIN_USER} from './actionTypes'
import * as API_URL from 'app.cms.react/store/actionTypes'
import {
    getSuccessResponse,
    getErrorResponse,
    setJWTToken,
} from 'app.cms.react/helpers/utils'
import {apiPost} from 'app.cms.react/helpers/apiHelpers'
import {
    loginUserSuccessAction,
    loginUserFailAction,
    componentFormOnSubmitAction,
    componentFormOnSubmitDoneAction
} from '../actions'
import i18n from 'i18n'

function* loginUser({payload: {params, history}}) {
    let componentResponse = {}

    try {
        yield put(componentFormOnSubmitAction())

        const response = yield call(apiPost, API_URL.AJAX_URL_LOGIN, params)
        componentResponse = {
            alertType: 'success',
            response: i18n.t('Login successful'),
        }
        setJWTToken(getSuccessResponse(response).access_token)
        yield put(loginUserSuccessAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
        // history.push('/dashboard')
        window.location.assign('/dashboard')
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(loginUserFailAction(componentResponse))
        yield put(componentFormOnSubmitDoneAction())
    }
}

function* LoginSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export default LoginSaga