import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link, withRouter} from 'react-router-dom'
import {Col, Container, Row} from 'reactstrap'
import logodark from '../../assets/images/logo-dark.png'
import logolight from '../../assets/images/logo-light.png'
import CarouselPage from 'components/Common/CarouselPage'
import {Helmet} from 'react-helmet'
import FormLogin from 'app.cms.react/components/Login/FormLogin'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {pluckRouteFromName} from 'app.cms.react/helpers/utils'

class Login extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Login')}</title>
                </Helmet>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <CarouselPage/>

                            <Col xl={3}>
                                <div className="auth-full-page-content p-md-5 p-4">
                                    <div className="w-100">
                                        <div className="d-flex flex-column h-100">
                                            <div className="my-auto">
                                                <div>
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p className="text-muted">
                                                        Sign in to continue.
                                                    </p>
                                                </div>

                                                <FormLogin
                                                    hasGoogleSocialite={false}
                                                    hasFacebookSocialite={false}
                                                    hasTwitterSocialite={false}
                                                    hasForgotPassword={false}
                                                    hasSignupLink={false}
                                                />
                                            </div>

                                            <div className="text-center">
                                                <p className="mb-0">
                                                    © {new Date().getFullYear()} | BookingHatch
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Login.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {})(withRouter(withTranslation()(Login)))