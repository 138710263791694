import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Link, withRouter} from 'react-router-dom'

class ListSocialite extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <ul className="list-inline">
                    {this.props.hasFacebook ? (
                        <li className="list-inline-item">
                            <Link
                                to="#"
                                className="social-list-item bg-primary text-white border-primary"
                            >
                                <i className="mdi mdi-facebook"></i>
                            </Link>
                        </li>
                    ) : null}
                    {this.props.hasGoogle ? (
                        <li className="list-inline-item">
                            <Link
                                to="#"
                                className="social-list-item bg-danger text-white border-danger"
                            >
                                <i className="mdi mdi-google"></i>
                            </Link>
                        </li>
                    ) : null}
                    {this.props.hasTwitter ? (
                        <li className="list-inline-item">
                            <Link
                                to="#"
                                className="social-list-item bg-info text-white border-info"
                            >
                                <i className="mdi mdi-twitter"></i>
                            </Link>
                        </li>
                    ) : null}
                </ul>
            </React.Fragment>
        )
    }
}

ListSocialite.propTypes = {
    t: PropTypes.any,
    hasGoogle: PropTypes.bool.isRequired,
    hasFacebook: PropTypes.bool.isRequired,
    hasTwitter: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {})(withRouter(withTranslation()(ListSocialite)))