import {
    DEFAULTS,
    DEFAULTS_DATA,
} from './actionTypes'
export const defaultsAction = (params) => ({
    type: DEFAULTS,
    payload: {params},
})
export const defaultsDataAction = (data) => ({
    type: DEFAULTS_DATA,
    payload: {data},
})
