import {
    INVOICES,
    INVOICES_INIT,
    INVOICES_DATA,
    INVOICES_UPDATE,
    INVOICES_ADD,
    INVOICES_DELETE,
    INVOICES_EXPORT,
    INVOICES_VIEW,
    INVOICES_VIEW_DATA,
    INVOICES_EXPORT_LOADING,
    INVOICES_EXPORT_LOADED,
    INVOICES_FAIL,
    INVOICES_SUCCESS,
    INVOICES_SUBMIT_EXPORT,
    INVOICES_PAYMENTS,
    INVOICES_PAYMENTS_DATA,
    INVOICES_PAYMENTS_EXPORT,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    dataView: {},
    dataInvoiceDetailPayments: {},
    isInvoiceExportLoading: false,
    submitExport: false,
    response: '',
    alertType: '',
}

const invoices = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case INVOICES_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
            }
        case INVOICES_SUBMIT_EXPORT:
            return {
                ...state,
                submitExport: payload.params,
            }
        case INVOICES_ADD:
            let items = []
            Object.entries(JSON.parse(payload.params.items)).map(([key, value]) => {
                if (typeof value.id != 'undefined') {
                    items.push(value)
                }
            })
            if (items.length > 0) {
                payload.params.items = JSON.stringify(items)
            } else {
                payload.params.items = ''
            }

            return {...state}
        case INVOICES:
        case INVOICES_UPDATE:
        case INVOICES_DELETE:
        case INVOICES_EXPORT:
        case INVOICES_VIEW:
        case INVOICES_PAYMENTS:
        case INVOICES_PAYMENTS_EXPORT:
            return {...state}
        case INVOICES_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
        case INVOICES_VIEW_DATA:
            return {
                ...state,
                dataView: getPropTraverse(payload, ['data']),
            }
        case INVOICES_PAYMENTS_DATA:
            const data = getPropTraverse(payload, ['data'])
            return {
                ...state,
                dataInvoiceDetailPayments: data,
            }
        case INVOICES_EXPORT_LOADING:
            return {
                ...state,
                isInvoiceExportLoading: true
            }
        case INVOICES_EXPORT_LOADED:
            return {
                ...state,
                isInvoiceExportLoading: false
            }
        case INVOICES_FAIL:
        case INVOICES_SUCCESS:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
    }

    return {...state}
}

export default invoices