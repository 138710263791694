import {
    COMPONENT_DATA_TABLE_LOADING,
    COMPONENT_DATA_TABLE_LOADED,
} from './actionTypes'

const INIT_STATE = {
    isDataTableLoading: true,
}

const componentDataTable = (state = INIT_STATE, action) => {
    const {type, payload} = action;

    switch (type) {
        case COMPONENT_DATA_TABLE_LOADING:
            return {
                ...state,
                isDataTableLoading: true
            }
        case COMPONENT_DATA_TABLE_LOADED:
            return {
                ...state,
                isDataTableLoading: false
            }
    }

    return {...state}
}

export default componentDataTable