import {
    // BUSINESS_INFO_INIT,
    BUSINESS_INFO,
    BUSINESS_INFO_DATA,
    BUSINESS_INFO_UPDATE,
    // BUSINESS_INFO_ADD,
    // BUSINESS_INFO_DELETE,
    BUSINESS_INFO_SUCCESS,
    BUSINESS_INFO_FAIL,
    BUSINESS_INFO_FORM,
} from './actionTypes'

// export const businessInfoInitAction = (params) => ({
//     type: BUSINESS_INFO_INIT,
//     payload: params,
// })

export const businessInfoAction = (params) => ({
    type: BUSINESS_INFO,
    payload: {params},
})

export const businessInfoDataAction = (data) => ({
    type: BUSINESS_INFO_DATA,
    payload: {data},
})

export const businessInfoUpdateAction = (params, meta) => ({
    type: BUSINESS_INFO_UPDATE,
    payload: {params, meta},
})

// export const businessInfoAddAction = (params, meta) => ({
//     type: BUSINESS_INFO_ADD,
//     payload: {params, meta},
// })

// export const businessInfoDeleteAction = (params, meta) => ({
//     type: BUSINESS_INFO_DELETE,
//     payload: {params, meta},
// })

export const businessInfoSuccessAction = (params) => ({
    type: BUSINESS_INFO_SUCCESS,
    payload: params,
})

export const businessInfoFailAction = (params) => ({
    type: BUSINESS_INFO_FAIL,
    payload: params,
})

export const businessInfoFormAction = (params) => ({
    type: BUSINESS_INFO_FORM,
    payload: params,
})