import {takeEvery, put, call} from 'redux-saga/effects'
import {
    buildUrlParams,
    formArgsExcludeEmpty,
    formatFormData,
    getErrorResponse,
    getPropTraverse,
} from 'app.cms.react/helpers/utils'
import {PRODUCTS_SERVICES, PRODUCTS_SERVICES_UPDATE, PRODUCTS_SERVICES_ADD, PRODUCTS_SERVICES_DELETE} from './actionTypes'
import {AJAX_URL_PRODUCTS_SERVICES} from '../actionTypes'
import {
    productsServicesDataAction,
    componentFormOnSubmitDoneAction,
    componentDataTableLoadedAction,
    // exceptionHandlerAction,
    componentDataTableLoadingAction,
    componentFormOnSubmitAction,
    loginUserSuccessAction,
    loginUserFailAction,
    productsServicesFailAction, productsServicesSuccessAction
} from '../actions'
import {apiGet, apiPost, apiPut, apiDel} from 'app.cms.react/helpers/apiHelpers'
import * as API_URL from '../actionTypes'

function* productsServices({payload: {params, history}}) {
    let paramObj = {}

    try {
        params = formArgsExcludeEmpty(params)

        if (Object.entries(params).length > 0) {
            paramObj = {params: params}
        }

        yield put(componentDataTableLoadingAction())
        const response = yield call(apiGet, API_URL.AJAX_URL_PRODUCTS_SERVICES, paramObj)
        const data = getPropTraverse(response, ['response', 'data'])
        yield put(productsServicesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        console.log(error, ' - error')
        // yield put(exceptionHandlerAction(error))
        yield put(componentDataTableLoadedAction())
    }
}

function* productsServicesUpdate({payload: {params, meta}}) {
    let options = {}
    let componentResponse = {}

    options = {
        id: params.id,
        product_service_name: params.product_service_name,
        product_service_desc: params.product_service_desc,
        price: params.price,
    }

    try {
        const response = yield call(apiPut, API_URL.AJAX_URL_PRODUCTS_SERVICES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(productsServicesSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_PRODUCTS_SERVICES)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(productsServicesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(productsServicesFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* productsServicesAdd({payload: {params, meta}}) {
    const options = {
        product_service_name: params.product_service_name,
        product_service_desc: params.product_service_desc,
        price: params.price,
    }
    let componentResponse = {}

    try {
        const response = yield call(apiPost, API_URL.AJAX_URL_PRODUCTS_SERVICES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(productsServicesSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_PRODUCTS_SERVICES)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(productsServicesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(productsServicesFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* productsServicesDelete({payload: {params, meta}}) {
    const options = {
        data: {
            id: params.primaryKey.id,
        }
    }
    let componentResponse = {}

    try {
        const response = yield call(apiDel, API_URL.AJAX_URL_PRODUCTS_SERVICES, options)
        componentResponse = {
            alertType: 'success',
            response: getPropTraverse(response, ['response', 'data']),
        }
        yield put(productsServicesSuccessAction(componentResponse))

        const responseData = yield call(apiGet, API_URL.AJAX_URL_PRODUCTS_SERVICES)
        const data = getPropTraverse(responseData, ['response', 'data'])
        yield put(productsServicesDataAction(data))
        yield put(componentDataTableLoadedAction())
    } catch (error) {
        componentResponse = {
            alertType: 'danger',
            response: getErrorResponse(error),
        }
        yield put(productsServicesFailAction(componentResponse))
    }

    yield put(componentFormOnSubmitDoneAction())
}

function* ProductsServicesSaga() {
    yield takeEvery(PRODUCTS_SERVICES, productsServices)
    yield takeEvery(PRODUCTS_SERVICES_UPDATE, productsServicesUpdate)
    yield takeEvery(PRODUCTS_SERVICES_ADD, productsServicesAdd)
    yield takeEvery(PRODUCTS_SERVICES_DELETE, productsServicesDelete)
}

export default ProductsServicesSaga