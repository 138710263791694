import {
    MICROSITES_INIT,
    MICROSITES,
    MICROSITES_DATA,
    MICROSITES_UPDATE,
    MICROSITES_ADD,
    MICROSITES_DELETE,
    MICROSITES_SUCCESS,
    MICROSITES_FAIL,
    MICROSITES_FORM,
} from './actionTypes'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

const INIT_STATE = {
    data: [],
    response: '',
    alertType: '',
    country: {},
}

const microsites = (state = INIT_STATE, action) => {
    const {type, payload} = action

    switch (type) {
        case MICROSITES_INIT:
            return {
                ...state,
                response: '',
                alertType: '',
            }
        case MICROSITES:
        case MICROSITES_UPDATE:
        case MICROSITES_ADD:
        case MICROSITES_DELETE:
            return {...state}
        case MICROSITES_SUCCESS:
        case MICROSITES_FAIL:
            return {
                ...state,
                alertType: action.payload.alertType,
                response: action.payload.response,
            }
        case MICROSITES_DATA:
            return {
                ...state,
                data: getPropTraverse(payload, ['data']),
            }
        case MICROSITES_FORM:
            return {
                ...state,
            }
    }

    return {...state}
}

export default microsites