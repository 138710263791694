import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle, Col, Row, Spinner} from 'reactstrap'
import Select from 'react-select'
import {ErrorMessage} from 'formik'
import {codeTableOptionsAction, globalGetterAction} from 'app.cms.react/store/actions'
import {bookingsFormAction} from '../../store/actions'
import {getPropTraverse} from 'app.cms.react/helpers/utils'

class FormBookingType extends Component {
    constructor(props) {
        super(props)

        this.props.codeTableOptionsAction({
            table: 'booking_types',
            type: 'ddlb',
            token: this.props.token,
        })

        this.handleDdlbChange = this.handleDdlbChange.bind(this)
        this.handleDdlbDefaultValue = this.handleDdlbDefaultValue.bind(this)
        this.renderBookingType = this.renderBookingType.bind(this)
        this.renderDataLoader = this.renderDataLoader.bind(this)
        this.renderForm = this.renderForm.bind(this)
    }

    handleDdlbChange = (val) => {
        this.props.bookingsFormAction({
            category: {
                category_code: val,
                category_type_code: 'booking_types'
            }
        })

        if (typeof val != 'undefined') {
            return {
                label: val.label,
                value: val.value
            }
        }
    }

    handleDdlbDefaultValue() {
        if (getPropTraverse(this.props.bookingForm, ['category', 'category_code', 'value']) != '') {
            return {
                label: this.props.bookingForm.category.category_code.label,
                value: this.props.bookingForm.category.category_code.value
            }
        }
    }

    renderBookingType() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <CardTitle>{this.props.t('Type of Booking')}</CardTitle>
                                <Row>
                                    <Col>
                                        <Select
                                            name="booking_types"
                                            options={this.props.ddlbBookingTypes.booking_types}
                                            onChange={this.handleDdlbChange}
                                            classNamePrefix="select2-selection"
                                            className={this.props.handleFieldValidation(this.props.errors, this.props.touched, 'booking_type')}
                                            isClearable={false}
                                            required
                                            value={this.handleDdlbDefaultValue()}
                                        />
                                        <ErrorMessage
                                            name="booking_type"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

    renderDataLoader() {
        return (
            <React.Fragment>
                <div className="text-center"><Spinner className="m-2" color="primary"/></div>
            </React.Fragment>
        )
    }

    renderForm() {
        if (typeof this.props.bookingId == 'undefined') {
            return this.renderBookingType()
        }

        if ((Object.keys(this.props.bookingForm).length > 0)) {
            return this.renderBookingType()
        } else {
            return this.renderDataLoader()
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.renderForm()}
            </React.Fragment>
        )
    }
}

FormBookingType.propTypes = {
    t: PropTypes.any,
    ddlbBookingTypes: PropTypes.any.isRequired,
    handleFieldValidation: PropTypes.any.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    bookingId: PropTypes.any,
    initialValue: PropTypes.any,
}

const mapStateToProps = state => {
    const ddlbBookingTypes = {
        ...state.CodeTable.data
    }

    const bookingForm = {
        ...state.Bookings.form
    }

    const {
        token,
    } = state.Globals

    return {
        ddlbBookingTypes,
        bookingForm,
        token
    }
}

export default withTranslation()(connect(mapStateToProps, {
    codeTableOptionsAction,
    bookingsFormAction,
    globalGetterAction,
})(FormBookingType))