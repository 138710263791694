import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Card, CardBody, Col, Container, Row} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {Link} from 'react-router-dom'


//Import Images
import profile1 from 'assets/images/profile-img.png'
import avatar from 'assets/images/users/avatar-1.jpg'
import avtar1 from 'assets/images/users/avatar-2.jpg'
import StarRatingsComponent from '../../components/Common/StarRatingsComponent'


class Profile extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('Profile')}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={this.props.t('Profile')}/>
                        <Row>
                            <Col xl="4">
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col xs="12">
                                                <div className="text-primary p-3">
                                                    <h5 className="text-primary pb-2">Rickson Chew</h5>
                                                    <p>A Certified Lean Six Sigma White Belt Software Engineering
                                                        Manager</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col sm="8">
                                                <div className="avatar-xl profile-user-wid mb-2">
                                                    <img src={avatar} className="img-thumbnail rounded-circle"/>
                                                </div>
                                                <div className="text-truncate">
                                                    <i className="bx bx-map text-primary"/> Makati, Philippines
                                                </div>
                                                <div className="text-success">
                                                    <i className="bx bx-check text-success"/> Email verified
                                                </div>
                                                <div className="text-danger">
                                                    <i className="bx bx-x text-danger"/> Email verified
                                                </div>
                                            </Col>
                                            <Col sm={4}>
                                                <div className="pt-4">
                                                    <Row>
                                                        <Col xs="12">
                                                            <h5 className="font-size-15">
                                                                Overall rating: 4.5
                                                            </h5>
                                                            <div className="text-muted mb-0">
                                                                <StarRatingsComponent rating={4.5}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div className="mt-4">
                                                        <Link to="" className="btn btn-primary btn-sm">
                                                            View My Porfolio{' '}<i
                                                            className="mdi mdi-arrow-right ms-1"/>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl={8}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            <h5 className="font-size-15">
                                                <i className="bx bx-message-dots text-muted align-middle me-1"></i>{' '}
                                                Reviews :
                                            </h5>

                                            <div>
                                                <div className="media py-3">
                                                    <div className="avatar-xs me-3">
                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                            <i className="bx bxs-user"></i>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 className="font-size-14 mb-1">
                                                            Delores Williams{' '}
                                                            <small className="text-muted float-end">
                                                                1 hr Ago
                                                            </small>
                                                        </h5>
                                                        <div className={"mb-2"}>
                                                            <StarRatingsComponent rating={4.5}/>
                                                        </div>
                                                        <p className="text-muted">
                                                            If several languages coalesce, the grammar
                                                            of the resulting language is more simple and
                                                            regular than that of the individual
                                                        </p>
                                                        <div>
                                                            <Link to="#" className="text-success">
                                                                <i className="mdi mdi-reply"></i> Reply
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media py-3 border-top">
                                                    <div className="avatar-xs me-3">
                                                        <img
                                                            src={avtar1}
                                                            alt=""
                                                            className="img-fluid d-block rounded-circle"
                                                        />
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 className="font-size-14 mb-1">
                                                            Clarence Smith{' '}
                                                            <small className="text-muted float-end">
                                                                2 hrs Ago
                                                            </small>
                                                        </h5>
                                                        <div className={"mb-2"}>
                                                            <StarRatingsComponent rating={2}/>
                                                        </div>
                                                        <p className="text-muted">
                                                            Neque porro quisquam est, qui dolorem ipsum
                                                            quia dolor sit amet
                                                        </p>
                                                        <div className="media pt-1">
                                                            <div className="avatar-xs me-3">
                                                                <div
                                                                    className="avatar-title rounded-circle bg-light text-primary">
                                                                    <i className="bx bxs-user"></i>
                                                                </div>
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="font-size-14 mb-1">
                                                                    You{' '}
                                                                    <small className="text-muted float-end">
                                                                        2 hrs Ago
                                                                    </small>
                                                                </h5>
                                                                <p className="text-muted">
                                                                    To take a trivial example, which of us
                                                                    ever undertakes laborious physical
                                                                    exercise
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="media py-3 border-top">
                                                    <div className="avatar-xs me-3">
                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                            <i className="bx bxs-user"></i>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 className="font-size-14 mb-1">
                                                            Keith McCoy{' '}
                                                            <small className="text-muted float-end">
                                                                12 Aug
                                                            </small>
                                                        </h5>
                                                        <div className={"mb-2"}>
                                                            <StarRatingsComponent rating={5}/>
                                                        </div>
                                                        <p className="text-muted">
                                                            Donec posuere vulputate arcu. phasellus
                                                            accumsan cursus velit
                                                        </p>
                                                        <div>
                                                            <Link to="#" className="text-success">
                                                                <i className="mdi mdi-reply"></i> Reply
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Profile.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(Profile))
