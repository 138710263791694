import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import {Button, Col, Row, Spinner} from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import {componentModalToggleAction} from 'app.cms.react/store/actions'
import './scss/datatables.scss'
import PlaceholderEmptyState from '../Placeholder/PlaceholderEmptyState'
import {Link, withRouter} from 'react-router-dom'

const {SearchBar} = Search

class DataTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            sizePerPage: 10,
        }
    }

    render() {
        const pageOptions = {
            sizePerPage: 10,
            totalSize: this.props.data.length,
            custom: true,
        }

        const defaultSorted = [{
            dataField: 'id',
            order: 'asc'
        }]

        const keyField = (this.props.keyField) ? this.props.keyField : 'id'
        const expandRow = this.props.expandRow
        const rowClasses = (this.props.rowClasses) ? this.props.rowClasses : ''

        return (
            <React.Fragment>
                {(this.props.isDataTableLoading) ? (
                    <div className="text-center"><Spinner className="m-2" color="primary"/></div>
                ) : (
                    (this.props.data.length == 0) ? (
                        <PlaceholderEmptyState/>
                    ) : (
                        <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="id"
                            columns={this.props.columns}
                            data={this.props.data}
                        >
                            {({paginationProps, paginationTableProps}) => (
                                <ToolkitProvider
                                    keyField={keyField}
                                    columns={this.props.columns}
                                    data={this.props.data}
                                    search
                                >
                                    {props => (
                                        <React.Fragment>
                                            <Row className="mb-2">
                                                <Col md="4">
                                                    <div className="search-box me-2 d-inline-block">
                                                        <div className="position-relative">
                                                            <SearchBar
                                                                {...props.searchProps}
                                                            />
                                                            <i className="bx bx-search-alt search-icon"/>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {(typeof this.props.toggleAddModal != 'undefined') ? (
                                                    <Col sm="8">
                                                        <div className="text-sm-end">
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="mb-2 me-2"
                                                                onClick={(e) => this.props.componentModalToggleAction(this.props.toggleAddModal)}
                                                            >
                                                                <i className="mdi mdi-plus me-1"/>{(typeof this.props.toggleAddModal.buttonText == 'undefined') ? 'Add New' : this.props.toggleAddModal.buttonText}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                                {(typeof this.props.toggleAddLink != 'undefined') ? (
                                                    <Col sm="8">
                                                        <div className="text-sm-end">
                                                            <Link
                                                                to={this.props.toggleAddLink.linkTo}
                                                                className="btn btn-primary"
                                                            >
                                                                <i className="mdi mdi-plus me-1"/>{(typeof this.props.toggleAddLink.linkText == 'undefined') ? 'Add New' : this.props.toggleAddLink.linkText}
                                                            </Link>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                            <Row>
                                                <Col xl="12">
                                                    <div className="table-responsive">
                                                        <BootstrapTable
                                                            keyField={keyField}
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            // defaultSorted={defaultSorted}
                                                            expandRow={expandRow}
                                                            rowClasses={rowClasses}
                                                            classes={
                                                                'table align-middle table-nowrap table-striped'
                                                            }
                                                            headerWrapperClasses={'table-light'}
                                                            {...props.baseProps}
                                                            {...paginationTableProps}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-md-center mt-30">
                                                <Col className="inner-custom-pagination d-flex">
                                                    <div className="d-inline">
                                                        <SizePerPageDropdownStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                    <div className="text-md-right ms-auto">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </ToolkitProvider>
                            )}
                        </PaginationProvider>
                    )
                )}
            </React.Fragment>
        )
    }
}

DataTable.propTypes = {
    keyField: PropTypes.any,
    data: PropTypes.any.isRequired,
    columns: PropTypes.array.isRequired,
    expandRow: PropTypes.any,
    rowClasses: PropTypes.any,
    toggleAddModal: PropTypes.any,
    toggleAddLink: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {
    componentModalToggleAction
})(withRouter(withTranslation()(DataTable)))