import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Card, CardBody, Col, Label, Row} from 'reactstrap'
import {NumericFormat} from 'react-number-format'
import {decimalPlace} from 'app.cms.react/helpers/utils'

class InvoiceItems extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-centered table-items">
                        <thead className="table-light">
                            <tr>
                                <th className="bg-dark bg-opacity-10">Item #</th>
                                <th className="bg-dark bg-opacity-10">Product / Service</th>
                                <th className="bg-dark bg-opacity-10 text-end">Quantity</th>
                                <th className="bg-dark bg-opacity-10 text-end">Price</th>
                                <th className="bg-dark bg-opacity-10 text-end">Sub Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.dataView.invoice_items.map((item, idx) => (
                                <tr id={"invoice-item-" + idx} key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>
                                        <div>{item.product_service_name}</div>
                                        {(item.product_service_desc) ? (
                                            <React.Fragment><small>{item.product_service_desc}</small></React.Fragment>
                                        ) : ''}
                                    </td>
                                    <td className="text-end">{item.quantity}</td>
                                    <td className="text-end">
                                        <NumericFormat
                                            value={decimalPlace(item.price)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                        />
                                    </td>
                                    <td className="text-end">
                                        <NumericFormat
                                            value={decimalPlace(item.sub_total_price)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            decimalSeparator="."
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                        <tr className="text-end">
                            <td colSpan="5"><strong>Total ({this.props.dataView.invoice.currency_symbol}): <NumericFormat
                                value={decimalPlace(this.props.dataView.invoice.total_amount)}
                                displayType="text"
                                thousandSeparator={true}
                                decimalSeparator="."
                            /></strong></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}

InvoiceItems.propTypes = {
    t: PropTypes.any,
    dataView: PropTypes.any.isRequired,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {
})(InvoiceItems))