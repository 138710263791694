import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Button, Card, CardBody, Col, Label, Row, Spinner} from 'reactstrap'
import PropTypes from 'prop-types'
import 'flatpickr/dist/themes/material_blue.css'
import Select from 'react-select'
import {
    productsServicesAction,
    packageBuilderInitAction,
    packageBuilderFormAction,
    packageBuilderFormItemsAction,
    packageBuilderFormAddRowAction,
    packageBuilderFormDeleteRowAction,
    packageBuilderViewAction,
    packageBuilderUpdateAction,
    componentModalToggleAction,
    componentFormOnSubmitAction,
    componentFormInitAction,
} from 'store/actions'
import {ddlbParser, decimalPlace} from 'app.cms.react/helpers/utils'
import ButtonLoading from 'app.cms.react/components/Common/Buttons/ButtonLoading'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import ModalAddProductsServices from '../ProductsServices/ModalAddProductsServices'
import FormAlert from '../Common/Alerts/FormAlert'
import { NumericFormat } from 'react-number-format'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const formatOptionLabelProductsServices = ({ value, label, price, description }, {context}) => (
    <React.Fragment>
        {(context == 'menu') ? (
            <React.Fragment>
                <div className="clearfix">
                    <div className="text-truncate">{label}{typeof description == 'string' ? <React.Fragment> - <em>{description}</em></React.Fragment> : ''}</div>
                    <small>
                        <NumericFormat value={decimalPlace(price)} displayType="text" thousandSeparator={true} decimalSeparator="." />
                    </small>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment>{label}</React.Fragment>
        )}
    </React.Fragment>
)

const initStateItems = () => {
    return {
        id: '',
        product_service_id: '',
        product_item: {
            label: '',
            value: '',
        },
        description: '',
        quantity: 1,
        price: '0.00',
        discount_price: '0.00',
        amount: '0.00',
    }
}

class FormUpdatePackageBuilder extends Component {
    constructor(props) {
        super(props)

        this.state = {
            package_name: '',
            description: '',
            items: [initStateItems()],
            package_price: '0.00',
            suggested_package_price: '0.00',
            toggleAddProductsServicesModalProps: {
                target: 'addProductsServices',
                isOpen: true,
            },
        }

        this.props.packageBuilderInitAction()
        this.props.componentFormInitAction()
        this.props.productsServicesAction()
        this.props.packageBuilderViewAction({package_id: this.props.packageId})

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleDdlbDefaultValue = this.handleDdlbDefaultValue.bind(this)
        this.handleSetProductServiceItem = this.handleSetProductServiceItem.bind(this)
        this.handleSetProductServiceItemDescription = this.handleSetProductServiceItemDescription.bind(this)
    }

    handleAddRow = () => {
        const stateList = this.props.dataView.items
        stateList.push(initStateItems())

        this.setState({ items: stateList })
        // this.props.packageBuilderFormAddRowAction(stateList)
    }

    handleAddProductService = () => {
        this.props.componentModalToggleAction(this.state.toggleAddProductsServicesModalProps)
    }

    handleRemoveRow = (e, idx) => {
        const stateList = this.props.dataView.items.filter((item, index) => {
            return idx !== index
        })

        this.computeTotal(stateList)
        this.setState({items: stateList})

        this.props.packageBuilderFormDeleteRowAction(stateList, idx)
    }

    handleOnChange = (value, idx, type) => {
        let label, code = ''

        switch (type) {
            case 'category':
                label = type + '_name'
                code = type + '_code'
                this.props.packageBuilderFormAction({
                    [type]: {
                        [label]: value.label,
                        [code]: value.value
                    }
                })
                break
            case 'product_service':
                this.handleSetProductServiceItemOnSelect(value, idx)
                break
            default:
                this.setState({[type]: value})
        }
    }

    handleDdlbDefaultValue(idx, type) {
        switch (type) {
            case 'booking_type':
                return {
                    label: this.props.dataView.category.category_name,
                    value: this.props.dataView.category.category_code,
                }
            default:
                if (typeof this.props.dataView.items[idx] != 'undefined') {
                    return {
                        label: this.props.dataView.items[idx].name,
                        value: this.props.dataView.items[idx].id
                    }
                }
        }
    }

    handleSetProductServiceItemOnSelect(value, idx) {
        let stateList = [initStateItems()]
        let quantity = 1

        if (value != null) {
            stateList = Object.entries(this.props.dataView.items).map(([key, val]) => {
                if (key == idx) {
                    quantity = (typeof value.quantity != 'undefined') ? value.quantity : quantity
                    return {
                        id: this.props.dataView.items[key].id,
                        product_service_id: value.value,
                        product_item: {
                            label: value.label,
                            value: value.value
                        },
                        description: value.description,
                        quantity: quantity,
                        price: value.price,
                        discount_price: value.discount_price,
                        amount: quantity * value.price,
                    }
                } else {
                    return val
                }
            })

            this.computeTotal(stateList)
        }

        // this.setState({items: stateList})
        this.props.packageBuilderFormItemsAction(stateList, idx)
    }

    handleSetProductServiceItem(e, idx, type) {
        this.computeSubTotal(e, idx, type)
    }

    handleSetProductServiceItemDescription(e, idx) {
        const stateList = Object.entries(this.props.dataView.items).map(([key, val]) => {
            if (key == idx) {
                return {
                    ...val,
                    description: e.target.value
                }
            } else {
                return val
            }
        })

        this.props.packageBuilderFormItemsAction(stateList, idx)
    }

    computeSubTotal(e, idx, type) {
        let discount = '0.00'

        const stateList = Object.entries(this.props.dataView.items).map(([key, val]) => {
            if (key == idx) {

                switch (type) {
                    case 'quantity':
                        // discount = (val.discount_price < (e.target.value * val.price)) ? e.target.value * val.price - val.discount_price : discount
                        return {
                            ...val,
                            quantity: e.target.value,
                            amount: e.target.value * val.price,
                        }
                    case 'discount_price':
                        discount = (e.target.value < (val.price * val.quantity)) ? val.price * val.quantity - e.target.value : discount
                        return {
                            ...val,
                            discount_price: e.target.value,
                            amount: discount,
                        }
                    case 'suggested_package_price':
                        return {
                            ...val,
                        }
                }
            } else {
                return val
            }
        })

        this.computeTotal(stateList)
        this.props.packageBuilderFormItemsAction(stateList, idx)
    }

    computeTotal(stateList) {
        let total = 0.00

        Object.entries(stateList).map(([key, value]) => {
            total += (value.amount == null) ? 0.00 : Number(value.amount)
        })

        this.props.packageBuilderFormAction({
            suggested_package_price: total,
            package_price: this.props.dataView.package_price,
        })
    }

    handleOverrideTotalAmount(e) {
        this.props.packageBuilderFormAction({
            suggested_package_price: this.props.dataView.suggested_package_price,
            package_price: e.target.value
        })
    }

    handleValidationSchema() {
        return Yup.object().shape({
            // product_service_desc: Yup.string().required(
            //     this.props.t('Product / Service name is required')
            // ),
        })
    }

    handleFieldValidation(errors, touched, property) {
        return (errors[property] && touched[property]) ? ' is-invalid' : ''
    }

    handleFormSubmit(values) {
        this.props.packageBuilderUpdateAction({
            id: this.props.packageId,
            package_name: values.package_name,
            description: this.state.description,
            package_price: values.package_price,
            items: JSON.stringify(this.props.dataView.items),
            category: this.props.dataView.category,
            category_type: this.props.categoryType,
        })
        this.props.componentFormOnSubmitAction()
    }

    render() {
        return (
            <React.Fragment>
                {(Object.keys(this.props.dataView).length > 0) ? (
                    <React.Fragment>
                        <ModalAddProductsServices />
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.props.dataView || this.state}
                            validationSchema={this.handleValidationSchema()}
                            onSubmit={(values) => this.handleFormSubmit(values)}
                        >
                            {({errors, status, touched}) => (
                                <Form className="form-horizontal mb-4">
                                    <Row>
                                        <Col md={12}>
                                            <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Label className={'col-md-1 col-form-label'} htmlFor="package_name">{this.props.t('Package Name')}</Label>
                                                        <Col>
                                                            <Field
                                                                name="package_name"
                                                                type="text"
                                                                className={'form-control' + this.handleFieldValidation(errors, touched, 'package_name')}
                                                                disabled={this.props.disableFields}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className={"mt-3"}>
                                                        <Label className={'col-md-1 col-form-label'} htmlFor="package_desc">{this.props.t('Description')}</Label>
                                                        <Col>
                                                            <CKEditor
                                                                name="description"
                                                                editor={ClassicEditor}
                                                                data={this.props.dataView.description}
                                                                onReady={editor => {
                                                                    this.props.dataView.description = editor.getData()
                                                                }}
                                                                config={
                                                                    {
                                                                        toolbar: ['bold', 'italic', 'numberedList', 'bulletedList']
                                                                    }
                                                                }
                                                                onChange={(event, editor) => {
                                                                    const data = editor.getData()
                                                                    this.handleOnChange(data, 0, 'description')
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    {(this.props.ddlbCategories) ? (
                                                        <Row className={"mt-3"}>
                                                            <Label className={'col-md-1 col-form-label'} htmlFor="category">{this.props.t('Category')}</Label>
                                                            <Col md={11}>
                                                                <Select
                                                                    name="category"
                                                                    options={this.props.ddlbCategories.booking_types}
                                                                    onChange={(e) => (this.handleOnChange(e, 0, 'category'))}
                                                                    classNamePrefix="select2-selection"
                                                                    className={this.handleFieldValidation(errors, touched, 'category')}
                                                                    isClearable={true}
                                                                    required
                                                                    value={this.handleDdlbDefaultValue(0, 'booking_type')}
                                                                />
                                                                <ErrorMessage
                                                                    name="category"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ) : null}
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <Label className={'col-md-2 col-form-label pt-0'}>{this.props.t('Inclusions')}</Label>
                                                    <table className="table vertical-align-middle table-striped pt-0">
                                                        <thead>
                                                        <tr>
                                                            <th className="col-md-4">{this.props.t('Product / Service')}</th>
                                                            <th className="col-md-4">&nbsp;</th>
                                                            <th className="col-md-1 text-end">{this.props.t('Quantity')}</th>
                                                            <th className="text-end">{this.props.t('Price')}</th>
                                                            <th className="text-end">{this.props.t('Amount')}</th>
                                                            <th>&nbsp;</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.props.dataView.items.map((item1, idx) => (
                                                                <tr id={"nested" + idx} key={idx}>
                                                                    <td>
                                                                        <div className="input-group">
                                                                            <div className="form-control border-0 p-0">
                                                                                <Select
                                                                                    name="product_service"
                                                                                    options={ddlbParser(this.props.productsServices, {
                                                                                        label: 'name',
                                                                                        value: 'id',
                                                                                    }, true)}
                                                                                    onChange={(e) => (this.handleOnChange(e, idx, 'product_service'))}
                                                                                    classNamePrefix="select2-selection "
                                                                                    isClearable={false}
                                                                                    formatOptionLabel={formatOptionLabelProductsServices}
                                                                                    isDisabled={this.props.formLoading}
                                                                                    required
                                                                                    value={this.handleDdlbDefaultValue(idx, 'product_service')}
                                                                                />
                                                                            </div>
                                                                            <Button onClick={this.handleAddProductService} color="primary">+</Button>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <Field
                                                                            name="product_service_desc"
                                                                            type="text"
                                                                            className={'form-control' + this.handleFieldValidation(errors, touched, 'product_service_desc')}
                                                                            disabled={this.props.disableFields}
                                                                            value={(this.props.dataView.items[idx].description != null) ? this.props.dataView.items[idx].description : ''}
                                                                            onChange={(e) => (this.handleSetProductServiceItemDescription(e, idx))}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="product_service_desc"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <NumericFormat
                                                                            allowNegative={false}
                                                                            name="quantity"
                                                                            type="text"
                                                                            className={'form-control text-end ' + this.handleFieldValidation(errors, touched, 'quantity')}
                                                                            disabled={this.props.disableFields}
                                                                            value={this.props.dataView.items[idx].quantity}
                                                                            onChange={(e) => (this.handleSetProductServiceItem(e, idx, 'quantity'))}
                                                                        />
                                                                        <ErrorMessage
                                                                            name="quantity"
                                                                            component="div"
                                                                            className="invalid-feedback"
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <Label className="form-control-plaintext text-end">
                                                                            <NumericFormat
                                                                                value={decimalPlace(this.props.dataView.items[idx].price)}
                                                                                displayType="text"
                                                                                thousandSeparator={true}
                                                                                decimalSeparator="."
                                                                            />
                                                                        </Label>
                                                                    </td>
                                                                    <td>
                                                                        <Label className="form-control-plaintext text-end">
                                                                            <NumericFormat
                                                                                value={decimalPlace(this.props.dataView.items[idx].amount)}
                                                                                displayType="text"
                                                                                thousandSeparator={true}
                                                                                decimalSeparator="."
                                                                            />
                                                                        </Label>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        {(idx > 0) ? (
                                                                            <Button
                                                                                onClick={e =>
                                                                                    this.handleRemoveRow(e, idx)
                                                                                }
                                                                                color="danger"
                                                                                className="btn-block inner"
                                                                                value={idx}
                                                                            >
                                                                                <i className="bx bx-trash" />
                                                                            </Button>
                                                                        ) : null}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td>
                                                                    <Button onClick={this.handleAddRow} color="primary">{this.props.t('Add Item')}</Button>
                                                                </td>
                                                                <td colSpan={4}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="form-control-plaintext text-end font-size-16">
                                                                                {this.props.t('Suggested Package Price')}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <Label className="form-control-plaintext text-end font-size-16">
                                                                                <NumericFormat
                                                                                    value={decimalPlace(this.props.dataView.suggested_package_price)}
                                                                                    displayType="text"
                                                                                    thousandSeparator={true}
                                                                                    decimalSeparator="."
                                                                                />
                                                                            </Label>
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={5}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="form-control-plaintext text-end font-size-16">
                                                                                {this.props.t('Package Price')}
                                                                            </Label>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <NumericFormat
                                                                                className="form-control text-end font-size-18"
                                                                                value={this.props.dataView.package_price}
                                                                                type="text"
                                                                                decimalSeparator="."
                                                                                onChange={(e) => (this.handleOverrideTotalAmount(e))}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </td>
                                                                <td>
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col md={12}>
                                                    <FormAlert response={this.props.response} alertType={this.props.alertType} validationType="notification" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="text-end">
                                                    <ButtonLoading
                                                        formLoading={this.props.formLoading}
                                                        buttonClass={'btn btn-primary w-md me-3'}
                                                        // onClick={() => {this.props.invoicesSubmitExportAction(false)}}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </React.Fragment>
                ) : (
                    <div className="text-center"><Spinner className="m-2" color="primary"/></div>
                )}
            </React.Fragment>
        )
    }
}

FormUpdatePackageBuilder.propTypes = {
    t: PropTypes.any,
    packageId: PropTypes.any.isRequired,
    ddlbCategories: PropTypes.any,
    categoryType: PropTypes.any,
}

const mapStateToProps = state => {
    const {
        disableFields,
        formSubmit,
        formLoading,
    } = state.ComponentsForm

    const productsServices = {
        ...state.ProductsServices.data
    }

    const {
        isDataTableLoading
    } = state.ComponentsDataTable

    const {
        dataView,
        response,
        alertType,
    } = state.PackageBuilder

    return {
        isDataTableLoading,
        productsServices,
        disableFields,
        formSubmit,
        formLoading,
        response,
        alertType,
        dataView,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    productsServicesAction,
    packageBuilderInitAction,
    packageBuilderFormAction,
    packageBuilderFormItemsAction,
    packageBuilderFormAddRowAction,
    packageBuilderFormDeleteRowAction,
    packageBuilderViewAction,
    packageBuilderUpdateAction,
    componentModalToggleAction,
    componentFormOnSubmitAction,
    componentFormInitAction
})(FormUpdatePackageBuilder))