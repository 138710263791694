import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {componentModalToggleAction, bookingsFormAction} from 'store/actions'
import {getPropTraverse, numberFormatComma} from 'app.cms.react/helpers/utils'
import parse from 'html-react-parser'

class ModalViewPackageDetails extends Component {
    constructor(props) {
        super(props)

        this.toggleModal = this.toggleModal.bind(this)
        this.renderSelectedPackageButton = this.renderSelectedPackageButton.bind(this)
    }

    toggleModal(value) {
        this.props.bookingsFormAction({package: value.packageItems})
        this.props.componentModalToggleAction({isOpen: value.isOpen})
    }

    renderSelectedPackageButton(details, isSelected) {
        let text = (isSelected) ? this.props.t('Selected') + ': ' : this.props.t('Book now for')
        text += ' ' + numberFormatComma(details.package_price)

        return (
            <React.Fragment>{text}</React.Fragment>
        )
    }

    render() {
        const {
            details,
            bookingFormPackage
        } = this.props

        const isSelected = (getPropTraverse(bookingFormPackage, ['id']) != null && (details.id == getPropTraverse(bookingFormPackage, ['id']))) ? true : false

        return (
            <React.Fragment>
                <Modal isOpen={(this.props.target == 'bookingPackageDetails') ? this.props.isOpen : false} toggle={this.toggleModal}>
                    <ModalHeader>{details.package_name}</ModalHeader>
                    <ModalBody>
                        <div>{(details.description != null) ? parse(details.description) : ''}</div>
                        {(Object.entries(details.items).length > 0) ? (
                            <div className="mt-3">
                                <strong>{this.props.t('Inclusions')}:</strong>
                                <ul className="list-group">
                                    {Object.entries(details.items).map(([key, item]) => (
                                        <li className="list-group-item border-0 me-0 px-0 py-1" key={key}><i className="bx bx-check text-success"></i> {item.name}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" onClick={(e) => this.toggleModal({isOpen: false, packageItems: details})} className={'btn ' + ((isSelected) ? 'btn-success' : 'btn-primary')}>
                            {this.renderSelectedPackageButton(details, isSelected)}
                        </button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

ModalViewPackageDetails.propTypes = {
    t: PropTypes.any,
    details: PropTypes.any.isRequired,
    bookingFormPackage: PropTypes.any
}

const mapStateToProps = state => {
    const {
        isOpen,
        target,
    } = state.ComponentsModal

    return {
        isOpen,
        target,
    }
}

export default withTranslation()(connect(mapStateToProps, {
    componentModalToggleAction,
    bookingsFormAction,
})(ModalViewPackageDetails))