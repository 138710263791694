import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import PropTypes from 'prop-types'
import Wizard from 'components/Bookings/Wizard'
import {Col, Container, Row} from 'reactstrap'

class Create extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <section className="section py-4 bg-white" id="booking-form-wizard">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <Wizard bookingType={this.props.bookingType} />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}

Create.propTypes = {
    t: PropTypes.any,
    bookingType: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {

})(Create))