import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withTranslation} from 'react-i18next'
import {Container} from 'reactstrap'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import FormInvoices from 'app.cms.react/components/Invoices/FormInvoices'
import {Helmet} from 'react-helmet'
import ModalLoader from '../../app.cms.react/components/Invoices/ModalLoader'

class Create extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.props.t('New Invoice')}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Invoices')} breadcrumbItem={this.props.t('New Invoice')}/>
                        <FormInvoices/>
                        <ModalLoader />
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Create.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {})(Create))