import {all, fork} from 'redux-saga/effects'

import BookingsSaga from './bookings/saga'
import LayoutSaga from './layout/saga'

// app.cms.react
import {
    BusinessInfoSaga,
    CodeTableSaga,
    ContactsSaga,
    DefaultsSaga,
    ForgotPasswordSaga,
    InvoicesSaga,
    LoginSaga,
    LogoutSaga,
    MicrositesSaga,
    PackageBuilderSaga,
    PaymentsSaga,
    ProductsServicesSaga,
    ResetPasswordSaga,
    SignUpSaga,
} from 'app.cms.react/store/sagas'

export default function* rootSaga() {
    yield all([
        fork(LayoutSaga),
        fork(BookingsSaga),

        // app.cms.react
        fork(BusinessInfoSaga),
        fork(CodeTableSaga),
        fork(ContactsSaga),
        fork(DefaultsSaga),
        fork(ForgotPasswordSaga),
        fork(InvoicesSaga),
        fork(LoginSaga),
        fork(LogoutSaga),
        fork(MicrositesSaga),
        fork(PackageBuilderSaga),
        fork(PaymentsSaga),
        fork(ProductsServicesSaga),
        fork(ResetPasswordSaga),
        fork(SignUpSaga),
    ])
}
