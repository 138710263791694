import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux'
import {Button, Spinner} from 'reactstrap'

class ButtonLoading extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            (this.props.formLoading) ? (
                <React.Fragment>
                    <Button className={(this.props.buttonClass) ?? 'btn btn-primary w-md waves-effect waves-light'} disabled={this.props.formLoading}>
                        <Spinner as="span" animation="grow" size="sm" role="status"/>&nbsp;{(this.props.buttonLoadingText) ?? 'Loading...'}
                    </Button>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <button
                        type={(this.props.buttonType) ?? 'submit'}
                        className={(this.props.buttonClass) ?? 'btn btn-primary w-md waves-effect waves-light'}
                        onClick={(this.props.onClick) ? this.props.onClick : null}
                    >
                        {(this.props.buttonName) ?? 'Submit'}
                    </button>
                </React.Fragment>
            )
        )
    }
}

ButtonLoading.propTypes = {
    formLoading: PropTypes.bool.isRequired,
    buttonClass: PropTypes.any,
    buttonName: PropTypes.any,
    buttonLoadingText: PropTypes.any,
    buttonType: PropTypes.any,
    onClick: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default withTranslation()(connect(mapStateToProps, {

})(ButtonLoading))