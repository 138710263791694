import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {logoutUserAction} from 'app.cms.react/store/actions'
import {withTranslation} from 'react-i18next'

class Logout extends Component {
    componentDidMount = () => {
        this.props.logoutUserAction(this.props.history)
    }

    render() {
        return <React.Fragment></React.Fragment>
    }
}


Logout.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {
    logoutUserAction
})(withRouter(withTranslation()(Logout)))